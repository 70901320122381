import React, { useEffect, useState } from "react";
import { images } from "../../../actions/customFn";
import { NavLink, useLocation, useParams } from "react-router-dom";
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css'
import LoginModal from "../Modals/LoginModal";
// import LoginModal from "../Modals/LoginModal";

function Header() {

    const [isOpen, setIsOpen] = React.useState(false)
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }

//     const location = useLocation();
// const [anotherheader, setAnotherHeader] = useState(false);
//     console.log("params=",anotherheader, "==",location);

//     useEffect(()=>{
//         if(location.pathname != '/'){
//             setAnotherHeader(true)
//                 }
//     }, [anotherheader]);
   

        // Login modal
const [loginmodal, setLoginModal] = useState(false);

const closeLoginModal = ()=>{
    setLoginModal(false);
}

const openLoginModal = ()=>{
    setLoginModal(true); 
}

        return (
        <>
      
            <div className="header">
                <div className="container">
                    <nav className="navbar navbar-expand-lg">
                        <NavLink className="navbar-brand" to="/">
                            <img src={images["logo-dark.png"]} alt="Logo" className="logo-dark logo"/>
                            <img src={images["logo.png"]} alt="Logo" className="logo-white logo"/>
                        </NavLink>
                        <Drawer
                            open={isOpen}
                            onClose={toggleDrawer}
                            direction='right'
                            className='mobile_menu'
                        >
                               {/* <img src={images["logo.png"]} alt="Logo" className="drawer_logo"/> */}
                               <div className=" main_menu" >

                                    <div className="navbar-nav drawer_menulinks">
                                        <NavLink className="nav-item nav-link " to="/">Home</NavLink>
                                        <NavLink className="nav-item nav-link" to="/market">Market</NavLink>
                                        <NavLink className="nav-item nav-link" to="/services">Services</NavLink>
                                        <NavLink className="nav-item nav-link" to="/user-supplier">Users</NavLink>
                                        <NavLink className="nav-item nav-link" to="/blog">Blog</NavLink>
                                        <NavLink className="nav-item nav-link" to="/about-us">About us</NavLink>
                                        <NavLink className="nav-item nav-link" to="/contact-us">Contact us</NavLink>
                                    </div>
                                    <div className="join_btn">
                                        <button onClick={openLoginModal} >Join Now</button>
                                    </div>
                                </div>
                        </Drawer>
                        <button className="navbar-toggler" type="button"  onClick={toggleDrawer}>
                             <img className="black_menu" src={images['bar-menu.png']} alt=""/>
                             <img className="white_leftmenu" src={images['white_menu.png']} alt="" />
                        </button>
                        
                        <div className="main_menu desktop_menu">
                            <div className="navbar-nav">
                                <NavLink className="nav-item nav-link " to="/">Home</NavLink>
                                <NavLink className="nav-item nav-link" to="/market">Market</NavLink>
                                <NavLink className="nav-item nav-link" to="/services">Services</NavLink>
                                <NavLink className="nav-item nav-link" to="/user-supplier">Users</NavLink>
                                <NavLink className="nav-item nav-link" to="/blog">Blog</NavLink>
                                <NavLink className="nav-item nav-link" to="/about-us">About us</NavLink>
                                <NavLink className="nav-item nav-link" to="/contact-us">Contact us</NavLink>
                            </div>
                            <div className="join_btn">
                                <button onClick={openLoginModal} >Join Now</button>
                            </div>
                        </div>
              

                    </nav>
                </div>
            </div>

           
            <LoginModal loginmodal={loginmodal} closeLoginModal={closeLoginModal} />
        </>
    );
}

export default Header;