import React from "react";
import {
    AvailabilityHeader,
  AvailabilitySidebar,
  
} from "../../components/Front";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { images } from "../../actions/customFn";

const SupplierMyOrder = () => {

    const firsttab_tabledata = [
        { status: "Completed" },
        { status: "Inprogress" },
        { status: "Completed" },
        { status: "Completed" },
        { status: "Open" },
        { status: "Inprogress" },
        { status: "Inprogress" },
        { status: "Open" },
        { status: "Completed" },
        { status: "Completed" },
        { status: "Inprogress" },
      ];

  return (
    <motion.div
    initial={{ opacity: 0.2 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 1 }}
    transition={{ duration: 2 }}
  >
    <>
      <div className="dashboard-layout">
        <div className="layout_left_side">
          <AvailabilitySidebar />
        </div>

        <div className="layout-right-sider">
          <div className="">
            <AvailabilityHeader headertopic="My Orders" />

            <div className="dashborader_datacontentmain">
              <div className="requirements_listpagemain">
                <div className="requirements_table">
                  <div className="table-responsive ">
                    <table class="table table-striped table-borderless">
                      <thead>
                        <tr>
                          <th scope="col">Contract No</th>
                          <th scope="col">Date listed</th>
                          <th scope="col">Product</th>
                          <th scope="col">Origin</th>
                          <th scope="col">Grade</th>
                          <th scope="col">Specification</th>
                          <th scope="col">Price</th>
                          <th scope="col">Payment terms</th>
                          <th scope="col">Quantity</th>
                          <th scope="col">Delivery port</th>
                          <th scope="col">Incoterms</th>
                          <th scope="col">No. of shipments </th>
                          <th scope="col">Delivery period</th>
                          <th scope="col"> Additional information</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {firsttab_tabledata.map((v, i) => {
                          return (
                            <tr>
                              
                              <td scope="row" >73749</td>
                              <td>12.04.24</td>
                              <td>Oaten Hay</td>
                              <td>Any</td>
                              <td>Premium</td>
                              <td>Specific Detail</td>
                              <td>$400/mt</td>
                              <td>CAD</td>
                              <td>200 mts</td>
                              <td>Jebel Ali</td>
                              <td>CIF</td>
                              <td>1</td>
                              <td>12.04.24</td>
                              <td>Lorem Ipsum is simply...</td>
                              {/* <td> 
                                <div className="show_status">
                                  <p
                                    className={
                                      v.status == "Completed"
                                        ? "completed_tag"
                                        : v.status == "Inprogress"
                                        ? "inprogess_tag"
                                        : "open_tag"
                                    }
                                  >
                                    {v.status}
                                  </p>
                                </div>{" "}
                              </td> */}
                              <td className="actions_list">
                                <NavLink to={"/seller/myorder-detail"}>
                                  <img
                                    src={images["view_btn.png"]}
                                    alt=""
                                  />{" "}
                                </NavLink>
                                {/* <NavLink to={""}>
                                  <img
                                    src={images["edit_btn.png"]}
                                    alt=""
                                  />{" "}
                                </NavLink> */}
                                <button>
                                  <img
                                    src={images["download_btn.png"]}
                                    alt=""
                                  />
                                </button>
                                {/* <button>
                                  <img
                                    src={images["delete_btn.png"]}
                                    alt=""
                                  />
                                </button> */}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  </motion.div>
  )
}

export default SupplierMyOrder