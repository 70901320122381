import React from "react";
import {
    BidListDetail,
    DashboardHeader,
    DashboardSidebar,
    RequirementDescription,
    RequirementsClientList,
  } from "../../components/Front";
  import { motion } from "framer-motion";

const RequirementDetails = () => {
  return (
    <motion.div
    initial={{ opacity: 0.2 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 1 }}
    transition={{ duration: 2 }}
  >
    <>
      <div className="dashboard-layout">
        <div className="layout_left_side">
          <DashboardSidebar />
        </div>

        <div className="layout-right-sider">
          <div className="">
            <DashboardHeader headertopic="Requirement Detail" />

            <div className="dashborader_datacontentmain">
              <BidListDetail />
              <div className="bids_outerinfopart mb-3">  
<div className=" bidsreceive_dashbinfo">
        <p>Additional Information</p>
        <h6>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text
          ever since the 1500s, when an unknown printer took a galley of type
          and scrambled it to make a type specimen book.
        </h6>
      </div>
      </div>
              {/* <RequirementDescription /> */}
              <RequirementsClientList />
            </div>
          </div>
        </div>
      </div>
    </>
  </motion.div>
  )
}

export default RequirementDetails;