import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { images } from "../../../actions/customFn";
import { NavLink, useNavigate } from "react-router-dom";
import SignUpModal from "./SignUpModal";

const LoginModal = (props) => {
  const { loginmodal, closeLoginModal } = props;

  const [signuppage,setSignupPart] = useState();
  const navigation = useNavigate();

  const doLogin = ()=>{
    navigation('/buyer/dashboard');
  }

  return (
    <div>
      <Modal
        className="login_modalmain"
        show={loginmodal}
        onHide={closeLoginModal}
        centered
      >
        <Modal.Body>
          <div className="closemodal_btn">
            <button onClick={closeLoginModal}>
              <img src={images["cross_btn.png"]} alt="" />
            </button>
          </div>
          <div className="modal_loginsectionmain">
            <div
              className="modal_leftimage"
              style={{ backgroundImage: `url(${images["loginbg_img.png"]})` }}
            ></div>
{
    signuppage == 1 ? <SignUpModal setSignupPart={setSignupPart} /> : 

            <div className="login_rightcontentpart">
              <h2>Login</h2>

              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>

              <div className="input_allmain">
                <label>Email</label>
                <input type="text" placeholder="Enter your email address" />
              </div>

              <div className="input_allmain">
                <label>Password</label>
                <input type="text" placeholder="Enter your password" />
              </div>

              <div className="forgotpassword">
                <button>Forgot Password?</button>
              </div>

              <div className="login_button">
                <button className="button_darkmain" onClick={()=>doLogin()} >Login</button>
              </div>

              <div class="or_section">
                <p>
                  <span>Or</span>
                </p>

                <p className="signup_link">
                  Don't have an account?{" "}
                  <button class="" onClick={()=> setSignupPart(1)}  >
                    Signup
                  </button>
                </p>
              </div>
            </div>
}

          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LoginModal;
