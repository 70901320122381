import React from "react";
// import   logo  from "../../src/assets/images/logo.png";
import { images } from "../../../actions/customFn";
import { NavLink } from "react-router-dom";



function Footer(){
    return(
        <>
            <div className="footer">
                <div className="foot_top">
                    <div className="container">
                        <nav className="navbar">
                            <NavLink className="navbar-brand" to="#">
                                <img src={images["logo.png"]} alt="Logo"/>
                            </NavLink>
                            
                            <div className="foot_menu">
                                <div className="navbar-nav">
                                    <NavLink className="nav-item nav-link " to="/">Home</NavLink>
                                    <NavLink className="nav-item nav-link" to="/market">Market</NavLink>
                                    <NavLink className="nav-item nav-link" to="/services">Services</NavLink>
                                    <NavLink className="nav-item nav-link" to="/user-buyers">Users</NavLink>
                                    <NavLink className="nav-item nav-link" to="/blog">Blog</NavLink>
                                    <NavLink className="nav-item nav-link" to="/about-us">About us</NavLink>
                                    <NavLink className="nav-item nav-link" to="/contact-us">Contact us</NavLink>
                                </div>                         
                            </div>
                        </nav>
                    </div>
                </div>

                <div className="copyright_sec">
                    <div className="container">
                        <div className="copy_text">
                            <div className="left_text">
                                <p>COPYRIGHT © 2024 SUPPLYARABIA - ALL RIGHTS RESERVED</p>
                            </div>
                            <div className="right_text">
                                <p>This site is protected by reCAPTCHA and the Google  
                                    <NavLink to="#"> Privacy Policy</NavLink> and <NavLink to="/term-condition">Terms of Service</NavLink> apply.</p>
                            </div>
                        </div>
                    </div>
                </div>
                
                
            </div>
        </>
    );
}

export default Footer;