import React from 'react';
import { NavLink } from "react-router-dom";
import { images } from "../../../actions/customFn";

const SupplyInspectionList = () => {

    const firsttb_tabledata = [
        { id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Completed" },
        {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay',status: 'Scheduled'},
        {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Completed" },
        {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Completed" },
        {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Canceled" },
        {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Scheduled" },
        {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Scheduled" },
        {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Canceled" },
        {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Completed" },
        {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Completed" },
        {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Scheduled" },
       
      ];

  return (
    <div className="requirements_listpagemain">
    <div className="requirements_table">
      <div className="table-responsive ">
        <table class="table table-striped table-borderless">
          <thead>
            <tr>  
              <th scope="col">#Inspection Id</th>
              <th scope="col">Inspection Date</th> 
              <th scope="col">Time</th> 
              <th scope="col">inspector</th> 
              <th scope="col">Status</th> 
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {firsttb_tabledata && firsttb_tabledata.map((v, i) => {
              return (
                <tr>
                <td scope="row">73749</td>
                <td>12.04.24</td> 
                <td>03:15 PM</td>
                <td>inspector name</td>
                 
                  <td>
                    <div className="show_status">
                      <p className={v.status == 'Completed' ? 'completed_tag': v.status == 'Scheduled' ? 'inprogess_tag' : 'open_tag'}>{v.status}</p>
                    </div>
                  </td>
                  <td className="actions_list">
                    <NavLink to={"/seller/inspections-detail"}>
                      <img src={images["view_btn.png"]} alt="" />
                    </NavLink>
                     
                    {/* <button>
                      <img src={images["edit_btn.png"]} alt="" />
                    </button> */}
                    {/* <button>
                      <img src={images["download_btn.png"]} alt="" />
                    </button> */}
                    <button>
                      <img src={images["delete_btn.png"]} alt="" />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  </div>
  )
}

export default SupplyInspectionList