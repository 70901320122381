import React, { useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { images } from "../../../actions/customFn";
// import AliceCarousel from 'react-alice-carousel';
// import 'react-alice-carousel/lib/alice-carousel.css';
// import ImageGallery from "react-image-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const RequirementsSlider = () => {
  const experience = {
    loop: true,
    margin: 0,
    nav: true,
    dots: false,
    autoplay: false,
    autoplaySpeed: 3000,
    items: 1,
    navText: [
      '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="116" viewBox="0 0 20 116" fill="none"><rect opacity="0.5" width="20" height="116" fill="white"/><path d="M8.28936 58.6947C8.10189 58.5072 7.99657 58.2529 7.99657 57.9877C7.99657 57.7225 8.10189 57.4682 8.28936 57.2807L12.8744 52.6947C13.0565 52.5061 13.1573 52.2535 13.155 51.9913C13.1528 51.7291 13.0476 51.4783 12.8622 51.2929C12.6768 51.1075 12.426 51.0023 12.1638 51C11.9016 50.9978 11.649 51.0986 11.4604 51.2807L6.87836 55.8667C6.31595 56.4293 6 57.1922 6 57.9877C6 58.7832 6.31595 59.5461 6.87836 60.1087L11.4644 64.6947C11.653 64.8769 11.9056 64.9777 12.1678 64.9754C12.43 64.9731 12.6808 64.8679 12.8662 64.6825C13.0516 64.4971 13.1568 64.2463 13.159 63.9841C13.1613 63.7219 13.0605 63.4693 12.8784 63.2807L8.28936 58.6947Z" fill="black"/></svg>',
      '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="116" viewBox="0 0 20 116" fill="none"><rect opacity="0.5" width="20" height="116" transform="matrix(-1 0 0 1 20 0)" fill="white"/><path d="M11.7106 58.6947C11.8981 58.5072 12.0034 58.2529 12.0034 57.9877C12.0034 57.7225 11.8981 57.4682 11.7106 57.2807L7.12564 52.6947C6.94348 52.5061 6.84269 52.2535 6.84497 51.9913C6.84724 51.7291 6.95241 51.4783 7.13782 51.2929C7.32323 51.1075 7.57404 51.0023 7.83624 51C8.09844 50.9978 8.35104 51.0986 8.53964 51.2807L13.1216 55.8667C13.6841 56.4293 14 57.1922 14 57.9877C14 58.7832 13.6841 59.5461 13.1216 60.1087L8.53564 64.6947C8.34704 64.8769 8.09444 64.9777 7.83224 64.9754C7.57004 64.9731 7.31923 64.8679 7.13382 64.6825C6.94841 64.4971 6.84324 64.2463 6.84097 63.9841C6.83869 63.7219 6.93948 63.4693 7.12164 63.2807L11.7106 58.6947Z" fill="black"/></svg>',
    ],
    // responsive: {
    //   0: {
    //     items: 2,
    //   },
    //   375: {
    //     items: 3,
    //   },
    //   600: {
    //     items: 1,
    //   },

    // },
  };

  const requirementdat = [
      images["latest_blog1.png"] ,
      images["requirement_1.png"] ,
     images["requirement_3.png"]  , 
     images["latest_blog1.png"]  , 
      images["requirement_4.png"] ,
     images["home_service3.png"]  , 
  ];

  const [toggler, setToggler] = useState(false);
  const [index, setIndex] = useState(-1);
  const [currentImage, setCurrentImage] = useState(requirementdat[index]);

  const nextIndex = (index + 1) % requirementdat.length;
  const nextImage = requirementdat[nextIndex] || currentImage;
  const prevIndex = (index + requirementdat.length - 1) % requirementdat.length;
  const prevImage = requirementdat[prevIndex] || currentImage;

  const handleClick = (index) => {
    setIndex(index);
    setToggler(!toggler);
    setCurrentImage(requirementdat[index]);
  };
  const handleClose = (index) => {
    setToggler(!toggler);
  };

  const handleMovePrev = () => {
    setIndex(prevIndex);
    setCurrentImage(requirementdat[prevIndex]);
  };
  const handleMoveNext = () => {
    setIndex(nextIndex);
    setCurrentImage(requirementdat[nextIndex]);
  };

  return (
    <div className="requirements_slidermain">
      <div className="trending_carosol_maindiv">
        <div className="homeizon_carosol">
          <OwlCarousel
            {...experience}
            className="require_slider"
            //   nav
            margin={10}
          >
            {requirementdat.map((value, index) => {
              return (
                <div className="item" key={index}>
                  <div className="requirement_item1">
                    <img src={value} alt="" />
                  </div>
                </div>
              );
            })}
          </OwlCarousel>
        </div>
      </div>

      <div className="suggested_sliderimages">
        <img src={images["latest_blog1.png"]} alt="" onClick={() => handleClick(0)} />
        <img src={images["requirement_1.png"]} alt="" onClick={() => handleClick(1)} />
        <img src={images["requirement_3.png"]} alt="" onClick={() => handleClick(2)} />
        <img src={images["latest_blog1.png"]} alt="" onClick={() => handleClick(3)} />
        <div className="slider_lastimg" onClick={() => handleClick(4)} >
          <img src={images["requirement_4.png"]} alt=""  />
          <div className="img_overlay">
            <h5>10+</h5>
          </div>
        </div>
      </div>

{/* <ImageGallery items={requirementdat} thumbnailClass="slider_thumbnails" originalHeight={'300px'}  /> */}

<div className="Light-box-react">
          {toggler && (
            <Lightbox
              className="New-modal-light"
              mainSrc={currentImage}
              // imageTitle={`dfgfdg`}
              //   mainSrcThumbnail={currentImage.src}
              nextSrc={nextImage}
              nextSrcThumbnail={nextImage}
              prevSrc={prevImage}
              prevSrcThumbnail={prevImage}
              onCloseRequest={handleClose}
              onMovePrevRequest={handleMovePrev}
              onMoveNextRequest={handleMoveNext}
            />
          )}
        </div>
    </div>
  );
};

export default RequirementsSlider;
