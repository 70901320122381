import React, { useState } from 'react'
import { images } from '../../../actions/customFn'
import AddQuotationModal from '../Modals/AddQuotationModal'

const AvailabilityPrice = () => {

  const [addquatemodal,setAddquatemodal] = useState(false);

  const openQuatationmodal = ()=>{
    setAddquatemodal(true);
  }
  const closeQuatationmodal=()=>{
    setAddquatemodal(false);
  }

  return (
    <> 
    <div className="pricedetail_mainsection availability_pricemain">
   
    <h2>
      $ 435 <span>/mt</span>{" "}
    </h2>
    
    {/* <div className="locaton">
   <img src={images['location1.png']} alt="" /> 2864 Scenicview Drive Longview, TX 75601
    </div> */}

    
    <h5>Target Price</h5>

    <div className="datalist_flex">
      <p>Unique #</p>
      <h6>73749</h6>
    </div>
    <div className="datalist_flex">
      <p>Unique #</p>
      <h6>73749</h6>
    </div>
    <div className="datalist_flex">
      <p>Unique #</p>
      <h6>73749</h6>
    </div>
    <div className="datalist_flex">
      <p>Unique #</p>
      <h6>73749</h6>
    </div>
    <div className="datalist_flex">
      <p>Unique #</p>
      <h6>73749</h6>
    </div>
    <div className="datalist_flex">
      <p>Unique #</p>
      <h6>73749</h6>
    </div>
    <div className="datalist_flex">
      <p>Unique #</p>
      <h6>73749</h6>
    </div>
    <div className="datalist_flex">
      <p>Specification</p>
      <h6>Lorem Ipsum</h6>
    </div>

    <div className="chatbid_btnsflex">
      <button className="transparent_btnmain">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
        >
          <g clipPath="url(#clip0_211_604)">
            <path
              d="M16.25 15.125V18.5C16.168 18.4531 16.0508 18.3828 15.8984 18.2891C15.7461 18.1953 15.4297 17.9375 14.9492 17.5156C14.4688 17.0938 13.9883 16.6016 13.5078 16.0391C12.9336 16.1797 12.3477 16.25 11.75 16.25C10.8945 16.25 10.1064 16.1621 9.38574 15.9863C8.66504 15.8105 8.07617 15.5938 7.61914 15.3359C7.16211 15.0781 6.77246 14.791 6.4502 14.4746C6.12793 14.1582 5.89941 13.8652 5.76465 13.5957C5.62988 13.3262 5.5625 13.0859 5.5625 12.875H7.25C8.76172 12.875 10.1064 12.6113 11.2842 12.084C12.4619 11.5566 13.3965 10.7773 14.0879 9.74609C14.7793 8.71484 15.125 7.50781 15.125 6.125C17.375 7.26172 18.5 8.85547 18.5 10.9062C18.5 12.582 17.75 13.9883 16.25 15.125ZM7.25 11.75C6.65234 11.75 6.06641 11.6797 5.49219 11.5391C5.01172 12.1016 4.54297 12.5879 4.08594 12.998C3.62891 13.4082 3.28906 13.6777 3.06641 13.8066L2.75 14V10.625C1.25 9.48828 0.5 8.08203 0.5 6.40625C0.5 5.33984 0.801758 4.35254 1.40527 3.44434C2.00879 2.53613 2.8291 1.81836 3.86621 1.29102C4.90332 0.763672 6.03418 0.5 7.25879 0.5C8.4834 0.5 9.61426 0.763672 10.6514 1.29102C11.6885 1.81836 12.5059 2.53613 13.1035 3.44434C13.7012 4.35254 14 5.33984 14 6.40625C14 7.47266 13.6953 8.41602 13.0859 9.23633C12.4766 10.0566 11.6621 10.6807 10.6426 11.1084C9.62305 11.5361 8.49219 11.75 7.25 11.75ZM7.25 1.625C6.23047 1.625 5.29004 1.82422 4.42871 2.22266C3.56738 2.62109 2.88477 3.16602 2.38086 3.85742C1.87695 4.54883 1.625 5.30176 1.625 6.11621C1.625 6.93066 1.87695 7.68359 2.38086 8.375C2.88477 9.06641 3.56738 9.61426 4.42871 10.0186C5.29004 10.4229 6.23047 10.625 7.25 10.625C8.26953 10.625 9.20996 10.4229 10.0713 10.0186C10.9326 9.61426 11.6152 9.06641 12.1191 8.375C12.623 7.68359 12.875 6.93066 12.875 6.11621C12.875 5.30176 12.623 4.54883 12.1191 3.85742C11.6152 3.16602 10.9326 2.62109 10.0713 2.22266C9.20996 1.82422 8.26953 1.625 7.25 1.625Z"
              fill="#191919"
            />
          </g>
          <defs>
            <clipPath id="clip0_211_604">
              <rect
                width="18"
                height="18"
                fill="white"
                transform="translate(0.5 0.5)"
              />
            </clipPath>
          </defs>
        </svg>{" "}
        Chat
      </button>

      <button className="button_darkmain" onClick={openQuatationmodal} >
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
<g clipPath="url(#clip0_309_7225)">
<path d="M13.2401 9.49551C13.2401 9.29664 13.1611 9.10592 13.0205 8.9653C12.8798 8.82467 12.6891 8.74566 12.4903 8.74563H9.74739L9.74739 6.00276C9.74397 5.80614 9.66346 5.61872 9.5232 5.48088C9.38295 5.34303 9.19416 5.26579 8.9975 5.26579C8.80085 5.26579 8.61206 5.34303 8.4718 5.48088C8.33154 5.61872 8.25103 5.80614 8.24762 6.00276L8.24762 8.74563L5.50475 8.74563C5.30812 8.74905 5.12071 8.82955 4.98286 8.96981C4.84502 9.11007 4.76778 9.29886 4.76778 9.49551C4.76778 9.69217 4.84502 9.88096 4.98286 10.0212C5.12071 10.1615 5.30812 10.242 5.50475 10.2454L8.24762 10.2454L8.24762 12.9883C8.25103 13.1849 8.33154 13.3723 8.4718 13.5102C8.61206 13.648 8.80085 13.7252 8.9975 13.7252C9.19416 13.7252 9.38295 13.648 9.5232 13.5102C9.66346 13.3723 9.74397 13.1849 9.74739 12.9883L9.74739 10.2454L12.4903 10.2454C12.6891 10.2454 12.8798 10.1664 13.0205 10.0257C13.1611 9.88511 13.2401 9.69439 13.2401 9.49551Z" fill="white"/>
<path d="M9 0.5C7.21997 0.5 5.47991 1.02784 3.99987 2.01677C2.51983 3.00571 1.36628 4.41131 0.685088 6.05585C0.00389957 7.70038 -0.17433 9.50998 0.172937 11.2558C0.520204 13.0016 1.37737 14.6053 2.63604 15.864C3.89472 17.1226 5.49836 17.9798 7.24419 18.3271C8.99002 18.6743 10.7996 18.4961 12.4442 17.8149C14.0887 17.1337 15.4943 15.9802 16.4832 14.5001C17.4722 13.0201 18 11.28 18 9.5C17.9974 7.11384 17.0484 4.82616 15.3611 3.13889C13.6738 1.45162 11.3862 0.502581 9 0.5ZM9 17C7.51664 17 6.0666 16.5601 4.83323 15.736C3.59986 14.9119 2.63856 13.7406 2.07091 12.3701C1.50325 10.9997 1.35473 9.49168 1.64411 8.03682C1.9335 6.58197 2.64781 5.24559 3.6967 4.1967C4.7456 3.14781 6.08197 2.4335 7.53683 2.14411C8.99168 1.85472 10.4997 2.00325 11.8701 2.5709C13.2406 3.13856 14.4119 4.09985 15.236 5.33322C16.0601 6.56659 16.5 8.01664 16.5 9.5C16.4978 11.4885 15.7069 13.3948 14.3009 14.8009C12.8948 16.2069 10.9885 16.9978 9 17Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_309_7225">
<rect width="18" height="18" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>
        Quote Now
      </button>
    </div>
  </div>

<AddQuotationModal addquatemodal={addquatemodal} closeQuatationmodal={closeQuatationmodal} />

</>
  )
}

export default AvailabilityPrice