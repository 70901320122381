import React from 'react';
import {
    AvailabilityHeader,
    AvailabilitySidebar,
    OrderTracking,
   
  } from "../../components/Front";
  import { motion } from "framer-motion";
import { images } from "../../actions/customFn";

const SupplyTrackingDetails = () => {

    const trade_detaillist = [
        { title: "Company", point: "Agrimark" },
        { title: "Product", point: "Oaten Hay" },
        { title: "Price", point: "$300 pmt" },
        { title: "Payment Terms", point: "CAD" }, 
    ]

  return (
    <motion.div
    initial={{ opacity: 0.2 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 1 }}
    transition={{ duration: 2 }}
  >
    <>
      <div className="dashboard-layout">
        <div className="layout_left_side">
          <AvailabilitySidebar />
        </div>

        <div className="layout-right-sider">
          <div className="">
            <AvailabilityHeader headertopic="Tracking Details" />

            <div className="dashborader_datacontentmain">
              

            <div className="orderdetail_mainsection tracking_detailpage">
      <div className="orderdetail_toppart">
        <div className="left_orderdetail">
          <img className="p_img" src={images["latest_blog1.png"]} alt="" />

          <div className="datalist_flex">
            <h6>Order #12542</h6>
            <div className="horizont_divide"></div>
            <div className="date_flex">
              <img src={images["gray_date.png"]} alt="" />
              <h6>26/07/2024</h6>
            </div>
            <div className="horizont_divide"></div>
            <div className="processing_tag">Processing</div>
          </div>
        </div>

       
      </div>

      <div className="bidlist_detailmainsection quatation_detaillistmain">
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
            <div className="bidlist_uniquelists">
              {trade_detaillist.map((v, i) => {
                return (
                  <div className="build_list1">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        {" "}
                        <p>{v.title}</p>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <h6>{v.point}</h6>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
            <div className="bidlist_uniquelists">
              {trade_detaillist.map((v, i) => {
                return (
                  <div className="build_list1">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        {" "}
                        <p>{v.title}</p>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <h6>{v.point}</h6>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
            <div className="bidlist_uniquelists">
             
                  <div className="build_list1">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <p>Payment Terms</p>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <h6>CAD</h6>
                      </div>
                    </div>
                  </div>
                
            </div>
          </div>

           
        </div>

        <div className="estimated_datediv">
<h6>Estimated Delivery date: <span>Monday 26 July , 2024</span> </h6>
      </div>

      </div>

 
    </div>
               
    <OrderTracking />  

              

            </div>
          </div>
        </div>
      </div>
    </>
  </motion.div>
  )
}

export default SupplyTrackingDetails;