import React from "react";
import { images } from "../../../actions/customFn";
import { NavLink } from "react-router-dom";

const RequirementsClientList = () => {

  const requireclient = [{}, {}, {}, {}, {}, {}];

  return (
    <div className="requirementsclient_listmain">
      {requireclient.map((v, i) => {
        return (
          <div className="requirement_clientcard1">
            <div className="left_clientinform">
              <div className="person_flex">
                <img src={images["person1.png"]} alt="" />
                <h6>Nicholas J. Peters</h6>
              </div>

              <div className="product_flexlist">
                <div className="">
                  <p>Product</p>
                  <h6>Alfalfa</h6>
                </div>
                <div className="">
                  <p>Quantity</p>
                  <h6>200 mts</h6>
                </div>
                <div className="">
                  <p>Grade</p>
                  <h6>Premium</h6>
                </div>
              </div>
            </div>

            <div className="right_pricedetail">
              <p>Price</p>
              <h4>
                $ 400 <span>/mt</span>{" "}
              </h4>
              <NavLink to={"#"}>
                {" "}
                <div className="view_detailbtn"> View </div>{" "}
              </NavLink>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RequirementsClientList;
