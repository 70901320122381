import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import LoginModal from "../Modals/LoginModal";


function HomeBanner(){

    const [loginmodal, setLoginModal] = useState(false);

const closeLoginModal = ()=>{
    setLoginModal(false);
}

const openLoginModal = ()=>{
    setLoginModal(true); 
}

    return (
        <>
            <div className="home_banner">
                <div className="container">
                    <h1 className="banner_title">The Hay Exchange</h1>
                    <p>Connecting Top-Tier Global Suppliers with Reliable Middle Eastern Buyers.</p>
                    <button className="join_nowbtn" onClick={()=>openLoginModal()} >Join Now</button>
                </div>
            </div>

            <LoginModal loginmodal={loginmodal} closeLoginModal={closeLoginModal} />
        </>
    );
}

export default HomeBanner;