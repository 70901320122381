import React from 'react';
import { NavLink } from "react-router-dom";


function ChatNow() {
  return (
   <>
    <div className='chat_now_sec'>
        <div className='container'>
        <div className='chat_row'>
                    <h3>Chat with SupplyArabia.</h3>
                    <NavLink to="#" className="chat_now_btn">
                    chat now
                    </NavLink>
                </div>
            
        </div>
    </div>
   </>
  )
}

export default ChatNow;
