import React from "react";
import { images } from "../../../actions/customFn";
import { NavLink } from "react-router-dom";
import ReactStars from "react-rating-stars-component";

const MyReviewsRating = () => {
  const secondtab_tabledata = [{}, {}, {}, {}, {}, {}];

  return (
    <div className="myreviews_ratingmain">

<div className="rating_cardsflexpart">
  <div className="review_showcard1">
    <p>Communication</p>
    <h3>3.0 <span>out of</span> 5</h3>
    <ReactStars count={5} size={24} color="#FFC107" />
  </div>
  <div className="review_showcard1">
    <p>Product Quality</p>
    <h3>4.5 <span>out of</span> 5</h3>
    <ReactStars count={5} size={24} color="#FFC107" />
  </div>
  <div className="review_showcard1">
    <p>Overall Service</p>
    <h3>2.0 <span>out of</span> 5</h3>
    <ReactStars count={5} size={24} color="#FFC107" />
  </div>
</div>

<div className="review_tablebelow"> 
<h5>My Reviews/Rating </h5>

      <div className="requirements_listpagemain availability_lsitmain">
        <div className="requirements_table">
          <div className="table-responsive ">
            <table class="table table-striped table-borderless">
              <thead>
                <tr>
                  <th scope="col">Supplier</th>
                  <th scope="col">ID number</th>
                  <th scope="col">Date listed</th>
                  {/* <th scope="col">Review</th>  */}
                  <th scope="col">Rating</th> 
                </tr>
              </thead>
              <tbody className="reviewlist_table">
                {secondtab_tabledata.map((v, i) => {
                  return (
                    <tr>
                      {/* <td scope="row"> 
                        <img src={images["availabel_img1.png"]} alt="" />
                      </td> */}
                      <td>Premium Alfalfa</td>
                      <td>73749</td>
                      <td>12.04.24</td>
                      {/* <td> 
                        <div className="review_para"> 
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and{" "}
                            <br /> typesetting industry. Lorem Ipsum has been
                            the industry's <br /> standard{" "}
                            <NavLink to={""}> read more </NavLink>{" "}
                          </p>     
                        </div>
                      </td> */}
                      <td> 
                        <div className="rating_count">
                           <ReactStars count={5} size={24} color="#FFC107" />{" "}
                        </div> 
                      </td>
                       
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="pagination">
            <button className="arrow_button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M7.94993 9.53025C7.80933 9.3896 7.73034 9.19887 7.73034 9C7.73034 8.80113 7.80933 8.6104 7.94993 8.46975L11.3887 5.03025C11.5253 4.8888 11.6009 4.69935 11.5992 4.5027C11.5975 4.30605 11.5186 4.11794 11.3795 3.97889C11.2405 3.83983 11.0524 3.76095 10.8557 3.75924C10.6591 3.75754 10.4696 3.83313 10.3282 3.96975L6.89168 7.40925C6.46987 7.83119 6.23291 8.40338 6.23291 9C6.23291 9.59662 6.46987 10.1688 6.89168 10.5908L10.3312 14.0303C10.4726 14.1669 10.6621 14.2425 10.8587 14.2408C11.0554 14.239 11.2435 14.1602 11.3825 14.0211C11.5216 13.8821 11.6005 13.6939 11.6022 13.4973C11.6039 13.3007 11.5283 13.1112 11.3917 12.9697L7.94993 9.53025Z"
                  fill="#999999"
                />
              </svg>
            </button>
            <button>01</button>
            <button>02</button>
            <button>03</button>
            <button className="arrow_button">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M10.0501 9.53025C10.1907 9.3896 10.2697 9.19887 10.2697 9C10.2697 8.80113 10.1907 8.6104 10.0501 8.46975L6.61132 5.03025C6.4747 4.8888 6.39911 4.69935 6.40081 4.5027C6.40252 4.30605 6.4814 4.11794 6.62046 3.97889C6.75951 3.83983 6.94762 3.76095 7.14427 3.75924C7.34092 3.75754 7.53037 3.83313 7.67182 3.96975L11.1083 7.40925C11.5301 7.83119 11.7671 8.40338 11.7671 9C11.7671 9.59662 11.5301 10.1688 11.1083 10.5908L7.66882 14.0303C7.52737 14.1669 7.33792 14.2425 7.14127 14.2408C6.94462 14.239 6.75651 14.1602 6.61746 14.0211C6.4784 13.8821 6.39952 13.6939 6.39781 13.4973C6.39611 13.3007 6.4717 13.1112 6.60832 12.9697L10.0501 9.53025Z"
                  fill="#999999"
                />
              </svg>{" "}
            </button>
          </div>

      </div>

    </div>
  );
};

export default MyReviewsRating;
