import React, { useRef, useState } from 'react'
import { images } from '../../../actions/customFn';

const PostAvailability = () => {

    const inputFile = useRef(null);

    const [formData, setFormData] = useState({
      image: [],
    });
  
    const handleChange = (e, key) => {
      if (e.target != undefined) {
        let { name, value } = e.target;
        if (name == "image") {
          if (e.target.files.length > 0) {
            for (let i = 0; i < e.target.files.length; i++) {
              setFormData((formData) => ({
                ...formData,
                [name]: [...formData.image, e.target.files[i]],
              }));
            }
          }
        } else {
          setFormData((formData) => ({ ...formData, [name]: value }));
        }
      }
    };
  
    const handleRemoveImage = (key) => {
      let images_arr = formData.image;
  
      images_arr.splice(key, 1);
  
      setFormData((prev) => ({
        ...prev,
        image: images_arr,
      }));
    };

  return (
    <div className="addrequirement_mainsection">

    <div className="images_uploadermain">
      <div className="require_flexinput">
        <div className="input_allmain">
          <label>Product</label>

          <div className="upload_propertyimage ">
            <div className="upload_imagepart">
              <button
                className="choosepic_btn"
                onClick={(e) => inputFile.current.click(e)}
              >
                <img src={images["upload_imgicon.png"]} alt="" />
              </button>

              <input
                type="file"
                name="image"
                // id="fileInput"
                // accept="image/*"
                value={''}
                inputProps={{ accept: "image/*" }}
                className="d-none"
                multiple={true}
                onChange={(e) => {
                  handleChange(e);
                }}
                ref={inputFile}
              />
              <h6>Upload Image</h6>
              <p>Format : jpg, png </p>
            </div>
          </div>
        </div>

        <div className="uploaded_imagessection">
          {formData.image.map((v, i) => {
            return (
              <div className="selected_images">
                <img
                  className="main_image"
                  key={i}
                  src={URL.createObjectURL(v)}
                  alt=""
                />

                <button
                  className="imgcross_btn"
                  onClick={() => {
                    handleRemoveImage(i);
                  }}
                >
                  <img src={images["cross_circle.png"]} alt="" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </div>

    {/* <div className="product_field">
      <div className="input_allmain">
        <label>Product</label>
        <input type="text" placeholder="Enter product name" />
      </div>
    </div> */}

    <div className="require_flexinput">
      <div className="input_allmain">
        <label>Lot number</label>
        <input type="text" placeholder="Enter Lot number" />
      </div>
      <div className="input_allmain">
        <label>Date listed</label>
        <input type="text" placeholder="Enter Date listed" />
      </div>
    </div>
    <div className="require_flexinput">
      <div className="input_allmain">
        <label>Product</label>
        <input type="text" placeholder="Enter product name" />
      </div>
      <div className="input_allmain">
        <label>Origin</label>
        <input type="text" placeholder="Enter Origin" />
      </div>
    </div>
    <div className="require_flexinput">
      <div className="input_allmain">
        <label>Grade</label>
        <input type="text" placeholder="Enter Grade" />
      </div>
      <div className="input_allmain">
        <label>Specification</label>
        <input type="text" placeholder="Enter Specification" />
      </div>
    </div>
    <div className="require_flexinput">
      <div className="input_allmain">
        <label>Quantity</label>
        <input type="text" placeholder="Enter Quantity" />
      </div>
      <div className="input_allmain">
        <label>Shipping period</label>
        <input type="text" placeholder="Enter Shipping period" />
      </div>
    </div>

    <div className="input_allmain">
      <label>Additional information</label>
      <textarea type="text" rows={5} placeholder="Enter text" />
    </div>

<div className="addpublish_btn">
<button className="button_darkmain">Publish</button>
</div>


  </div>
  )
}

export default PostAvailability