import React, { useRef, useState } from "react";
import { images } from "../../../actions/customFn";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const ProfileEdit = () => {
  const inputFile = useRef(null);
  // const inputimage = useRef(null);
  const [file, setFile] = useState();

  const [formData, setFormData] = useState({
    profile_image: "",
  });

  function showImage(e) {
    if (e.target.files.length > 0) {
      setFile(URL.createObjectURL(e.target.files[0]));
    }
  }

  const handleChange = (e) => {
    if (e.target != undefined) {
      const { name, value } = e.target;
      if (name == "profile_image") {
        setFormData((formData) => ({ ...formData, [name]: e.target.files[0] }));
      } else {
        setFormData((formData) => ({ ...formData, [name]: value }));
      }
    } else {
    }
  };

  const [bsuiness_category, setBusinesscategory] = useState();

  const handleSelectCAtegory = (event) => {
    setBusinesscategory(event.target.value);
  };

  console.log("resp=", formData.profile_image);

  return (
    <div className="profileedit_mainsection">
      <div className="profile_user_image ">
        <div className="user_image">
          {formData.profile_image != undefined &&
          formData.profile_image != "" ? (
            <img
              src={
                file == "" || file == undefined ? formData.profile_image : file
              }
              alt=""
            />
          ) : (
            <img src={images["profile_1img.png"]} alt="" />
          )}
        </div>

        <div className="profile_btn_change">
          <button
            className="chnange_profile_btn"
            onClick={(e) => inputFile.current.click(e)}
          >
            {" "}
            <img src={images["camera.png"]} alt="" />{" "}
          </button>

          <input
            type="file"
            name="profile_image"
            id="fileInput"
            inputProps={{ accept: "image/*" }}
            className="d-none"
            onChange={(e) => {
              handleChange(e);
              showImage(e);
            }}
            ref={inputFile}
          />
        </div>
      </div>
      <p className="upload_imaglabel">Upload image/Company Logo</p>

      <div className="profile_inputflex">
        <div className="input_allmain">
          <label>Name</label>
          <input type="text" placeholder="Enter your Name" />
        </div>
        <div className="input_allmain">
          <label>Email</label>
          <input type="text" placeholder="Enter your email address" />
        </div>
      </div>
      <div className="profile_inputflex">
        <div className="input_allmain">
          <label>Telephone Number</label>
          <input type="text" placeholder="Enter your Telephone Number" />
        </div>
        <div className="input_allmain">
          <label>Company Name</label>
          <input type="text" placeholder="Enter your Company Name" />
        </div>
      </div>

      <div className="profile_inputflex">
        <div className="input_allmain">
          <label>Address</label>
          <input type="text" placeholder="Enter your Address" />
        </div>
        <div className="input_allmain">
          <label>Role</label>
          <input type="text" placeholder="Enter your Role" />
        </div>
      </div>

      <div className="profile_inputflex">
        <div className="input_allmain">
          <label>Activities</label>
          <input type="text" placeholder="Enter your Activities" />
        </div>
        <div className="input_allmain">
          <label>Services/products offered</label>
          <input type="text" placeholder="Enter services/products offered " />
        </div>
      </div>

      <div className="profile_inputflex">
        <div className="input_allmain">
          <label>Volumes</label>
          <input type="text" placeholder="Enter your Volumes" />
        </div>

        <div className="input_allmain">
          <label htmlFor="">Business categories</label>
          <div className="material_selector">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={bsuiness_category}
                  defaultValue={10}
                  onChange={handleSelectCAtegory}
                >
                  <MenuItem value={10}>Select business categories </MenuItem>
                  <MenuItem value={20}>Select business categories </MenuItem>
                  <MenuItem value={30}>Select business categories </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
        </div>
        {/* <div className="update_btns">
                <button className='button_darkmain' >Update</button>
              </div> */}
      </div>

      <div className="input_allmain">
        <label>Website link</label>
        <input type="text" placeholder="Paste your website link here" />
      </div>

      <div className="input_allmain">
        <label>Brief description</label>
        <textarea type="text" placeholder="Enter Brief description" />
      </div>

      <div className="update_btns">
        <button className="button_darkmain">Update</button>
      </div>
    </div>
  );
};

export default ProfileEdit;
