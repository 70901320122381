import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';

const OrderTracking = () => {
  return (
<div className="ordertracking_sectionmain">
    <h4>Order Tracking</h4>

<div className="trackingshow_section">

<ProgressBar now={28} />

<div className="tracking_contentlimits">   
<div className="row">
<div className="col-3">
    <h6>Ordered</h6>
    <p>20/07/2024</p>
    <p>17:32</p>
</div>
<div className="col-3">
    <h6>Ready</h6>
    <p>20/07/2024</p>
    <p>17:32</p>
</div>
<div className="col-3">
    <h6>Shipped</h6>
    <p>20/07/2024</p>
    <p>17:32</p>
</div>
<div className="col-3">
    <h6>Estimated Delivery </h6>
    <p>20/07/2024</p>
    <p>17:32</p>
</div>
</div>

</div>

</div>

</div>
  )
}

export default OrderTracking;