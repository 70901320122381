import React, { useEffect, useRef, useState } from 'react'
import $ from 'jquery';
// import HowWorkImg from '../../assets/images/home/how-works.png';
import { images } from "../../../actions/customFn";
import { useParams } from 'react-router-dom';

function HowWorks() {

    const containerRef = useRef(null);
    const [leftSpace, setLeftSpace] = useState(0);
  
    // Function to get and set the left padding and margin of the container
    const updateLeftSpace = () => {
      if (containerRef.current) {
        const computedStyle = getComputedStyle(containerRef.current);
        const leftPadding = parseFloat(computedStyle.paddingLeft);
        const leftMargin = parseFloat(computedStyle.marginLeft);
        setLeftSpace(leftPadding + leftMargin);
      }
    };
   
    useEffect(() => {
      updateLeftSpace();
  
      window.addEventListener('resize', updateLeftSpace);
      return () => {
        window.removeEventListener('resize', updateLeftSpace);
      };
    }, []);
 


  return (
    <>
    {/* This div is required  */}
    <div className="container"  ref={containerRef}  >

    </div>

        <div className='how_work_sec p-left'  style={{
          marginLeft: `${leftSpace}px`, 
        }}>
              
                    <div className='left_col'>
                        <div className='left_content_sec'>
                            <h3>How it works?</h3>  
                            <div className='number_list'>
                                <div className='number_box'>
                                    <div className='number'>
                                        <span>01</span>
                                    </div>
                                    <div className='number_content'>
                                        <h5>Post Requirements</h5>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                                    </div>
                                </div>
                                <div className='number_box'>
                                    <div className='number'>
                                        <span>02</span>
                                    </div>
                                    <div className='number_content'>
                                        <h5>Get Bids</h5>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                                    </div>
                                </div>
                                <div className='number_box'>
                                    <div className='number'>
                                        <span>03</span>
                                    </div>
                                    <div className='number_content'>
                                        <h5>Go to Chat and Buy</h5>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                                    </div>
                                </div>
                            </div>                          
                        </div>
                    </div>
                    <div className='right_col'>
                        <img src={images["how-works1.png"]} alt="How Works" />
                    </div>
                
            
        </div>
    </>
  )
}

export default HowWorks;