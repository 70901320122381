import React from 'react'

const ChangePassword = () => {
  return (
    <div className="changepassword_dashboradmain">
        
        <div className="change_topinputs">  
        <div className="oldpassword_input">
        <div className="input_allmain">
                <label>Old Password</label>
                <input type="text" placeholder="Enter your Old Password" />
              </div>
        </div>

        <div className="inputs_flexdiv">
        <div className="input_allmain">
                <label>New Password</label>
                <input type="text" placeholder="Enter your New Password" />
              </div>
              <div className="input_allmain">
                <label>Confirm Password</label>
                <input type="text" placeholder="Enter your Confirm Password" />
              </div>
        </div>
        </div>

<div className="last_updatebtn">
    <button className='button_darkmain'>Update</button>
</div>

    </div>
  )
}

export default ChangePassword;