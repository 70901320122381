import React from 'react'

const ContactUsForm = () => {
  return (
    <div className="contactus_formmain">

<h5>Contact info</h5>

<div className='flex_inputfields'>
<div className="input_allmain">
                <label>First Name</label>
                <input type="text" placeholder="Enter your First Name" />
              </div>
              <div className="input_allmain">
                <label>Last Name</label>
                <input type="text" placeholder="Enter your Last Name" />
              </div>
</div>

<div className='flex_inputfields'>
<div className="input_allmain">
                <label>Phone Number</label>
                <input type="text" placeholder="Enter your Phone Number" />
              </div>
              <div className="input_allmain">
                <label>Email Address</label>
                <input type="text" placeholder="Enter your email address" />
              </div>
</div>

<div className="input_allmain">
                <label>Message</label>
                <textarea type="text" rows={5} placeholder="Enter your message" />
              </div>

              <div className="form_submitbtn">
                <button className='button_darkmain' >Send</button>
              </div>

    </div>
  )
}

export default ContactUsForm