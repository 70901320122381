import React from 'react';
import { images } from "../../../actions/customFn";
import { NavLink } from "react-router-dom";

const CreditInsuranceList = () => {
    const firsttb_tabledata = [
        { id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Accepted" },
        {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay',status: 'Pending'},
        {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Accepted" },
        {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Accepted" },
        {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Rejected" },
        {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Pending" },
        {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Pending" },
        {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Rejected" },
        {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Accepted" },
        {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Accepted" },
        {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Pending" },
       
      ];

  return (
  <div className="requirements_listpagemain ">
      <div className="requirements_table">
        <div className="table-responsive ">
          <table class="table table-striped table-borderless">
            <thead>
              <tr>
                <th scope="col">Credit ID</th>
                <th scope="col">Date</th>
                <th scope="col">Buyer</th>
                <th scope="col">Product</th> 
                <th scope="col">Origin</th>
                <th scope="col">Price</th>
                <th scope="col">Payment terms</th>
                <th scope="col">Quantity</th>
                <th scope="col">Delivery port</th>
                <th scope="col">Incoterms</th>
                <th scope="col">No. of shipments </th>
                <th scope="col">Total value</th>
                <th scope="col"> Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody className="">
              {firsttb_tabledata.map((v, i) => {
                return (
                    <tr>
                    <td scope="row">73749</td>
                    <td>12.04.24</td>
                    <td>Jebel Ali</td>
                    <td>Oaten Hay</td>
                    <td>Any</td>
                    <td>$400/mt</td>
                    <td>Any</td>
                    <td>200 mts</td>
                    <td>Jebel Ali</td>
                    <td>CIF</td>
                    <td>1</td>
                    <td>$400/mt</td>
                     
                      <td>
                        <div className="show_status">
                          <p className={v.status == 'Accepted' ? 'completed_tag': v.status == 'Pending' ? 'inprogess_tag' : 'open_tag'}>{v.status}</p>
                        </div>
                      </td>
                      <td className="actions_list">
                        <NavLink to={"/seller/contract-details"}>
                          <img src={images["view_btn.png"]} alt="" />
                        </NavLink>
                         
                        {/* <button>
                          <img src={images["edit_btn.png"]} alt="" />
                        </button> */}
                        {/* <button>
                          <img src={images["download_btn.png"]} alt="" />
                        </button> */}
                        <button>
                          <img src={images["delete_btn.png"]} alt="" />
                        </button>
                      </td>
                    </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default CreditInsuranceList