import React from 'react';

function ServicesOperations() {
  return (
    <>
      <div className='service_operations'>
        <div className='container'>
          <h3 className='sec_title'>Explore SupplyArabia's Comprehensive Services for Seamless Trade Operations</h3>
          <div className='services'>
              <div className='service'>
                  <h5 className='service_title'>Inspections</h5>
                  <p className='service_para'>Quality assurance is paramount to ensure the integrity of products and uphold customer satisfaction. SupplyArabia offers comprehensive inspection services to assess the quality, condition, and compliance of goods at various stages of the supply chain. From pre-shipment inspections to on-site quality checks, our experienced inspectors provide thorough evaluations to mitigate risks and maintain high standards of product quality.</p>
              </div>
              <div className='service'>
                  <h5 className='service_title'>Verification</h5>
                  <p className='service_para'>Ensuring trust and reliability is crucial in any business transaction. At SupplyArabia, we offer robust verification services to authenticate the identity and credibility of buyers and sellers on our platform. Through thorough vetting processes and verification checks, we provide peace of mind to our users, fostering a secure and trustworthy trading environment.</p>
              </div>
              <div className='service'>
                  <h5 className='service_title'>Tracking</h5>
                  <p className='service_para'>Visibility and transparency are key elements in supply chain management. With our advanced tracking services, businesses can monitor the movement of goods and shipments in real-time, enabling efficient logistics management and proactive decision-making. Whether it's tracking inventory, shipments, or delivery statuses, SupplyArabia empowers businesses with the tools they need to stay informed and in control.</p>
              </div>
              <div className='service'>
                  <h5 className='service_title'>Credit Insurance</h5>
                  <p className='service_para'>Managing credit risk is a critical aspect of trade finance. SupplyArabia offers credit insurance services to protect businesses against the risk of non-payment and default. With our credit insurance solutions,businesses can safeguard their receivables, mitigate financial losses, and unlock opportunities for growth with confidence.</p>
              </div>
              <div className='service'>
                  <h5 className='service_title'>Sales Support</h5>
                  <p className='service_para'>Navigating the complexities of sales and marketing can be challenging, especially in competitive markets. At SupplyArabia, we provide comprehensive sales support services to help businesses drive revenue and accelerate growth. From market analysis and lead generation to customer engagement and after-sales support, our dedicated sales team is committed to helping businesses succeed in today's dynamic marketplace.</p>
              </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ServicesOperations;