// import { Dashboard } from "@mui/icons-material";
import {
    
    AvailabilityDashboard,
    AvailabilityDetailDashboard,
    PostAvailabilityForm,
    FirmQuoteDashboard,
   
    AvailableSuplierDashboard,
    SupplierBidReceived,
    BidsReceivedDetail,
    MyQuotationDashboard,
    MyQuotationDetail,
    SupplierMyOrder,
    SupplierOrderDetail,
    SupplierTracking,
    AddNewShipmentTrack,
    SupplyTrackingDetails,
    SupplierInspections,
    ArrangeInspection,
    SupplyInspectionDetail,
    SupplierTermCondition,
    SupplyCreditInsurance,
    ApplyCreditInsurance,
    SupplierInvoices,
    SupplyInvoiceDetail,
    SupplierProfileDashboard,
    ContractDetails,
  } from "../pages/Front";
//   import QuatationDetailDashboard from "../pages/Front/QuatationDetailDashboard";
  
  const AvailabilityDashboardRouteArr = [
    {
      name: "AvailableSuplierDashboard",
      path: "/seller/dashboard",
      key: "seller-dashboard",
      route: "/seller-dashboard",
      element: <AvailableSuplierDashboard />,
    },
     

    {
      name: "AvailabilityDashboard",
      path: "/seller/availability",
      key: "availability",
      route: "/availability",
      element: <AvailabilityDashboard />,
    },
    {
      name: "AvailabilityDashboard",
      path: "/seller/availability-details",
      key: "availability-details",
      route: "/availability-details",
      element: <AvailabilityDetailDashboard />,
    },
    {
      name: "PostAvailabilityForm",
      path: "/seller/post-availability",
      key: "post-availability",
      route: "/post-availability",
      element: <PostAvailabilityForm />,
    },
    {
      name: "FirmQuoteDashboard",
      path: "/seller/firm-quote",
      key: "firm-quote",
      route: "/firm-quote",
      element: <FirmQuoteDashboard />,
    },

    {
      name: "SupplierBidReceived",
      path: "/seller/bids-received",
      key: "bids-received",
      route: "/bids-received",
      element: <SupplierBidReceived />,
    },
    {
      name: "BidsReceivedDetail",
      path: "/seller/bidsreceived-detail",
      key: "bidsreceived-detail",
      route: "/bidsreceived-detail",
      element: <BidsReceivedDetail />,
    },
    {
      name: "MyQuotationDashboard",
      path: "/seller/my-quotation",
      key: "my-quotation",
      route: "/my-quotation",
      element: <MyQuotationDashboard/>,
    },
    {
      name: "MyQuotationDetail",
      path: "/seller/myquotation-detail",
      key: "myquotation-detail",
      route: "/myquotation-detail",
      element: <MyQuotationDetail/>,
    },
    
    {
      name: "SupplierMyOrder",
      path: "/seller/my-order",
      key: "my-order",
      route: "/my-order",
      element: <SupplierMyOrder/>,
    },
    {
      name: "SupplierOrderDetail",
      path: "/seller/myorder-detail",
      key: "myorder-detail",
      route: "/myorder-detail",
      element: <SupplierOrderDetail/>,
    },
    {
      name: "SupplierTracking",
      path: "/seller/tracking",
      key: "tracking",
      route: "/tracking",
      element: <SupplierTracking/>,
    },
    {
      name: "AddNewShipmentTrack",
      path: "/seller/addshipment-tracking",
      key: "addshipment-tracking",
      route: "/addshipment-tracking",
      element: <AddNewShipmentTrack/>,
    },
    {
      name: "SupplyTrackingDetails",
      path: "/seller/tracking-details",
      key: "tracking-details",
      route: "/tracking-details",
      element: <SupplyTrackingDetails/>,
    },
    {
      name: "SupplierInspections",
      path: "/seller/inspections",
      key: "inspections",
      route: "/inspections",
      element: <SupplierInspections/>,
    },
    {
      name: "ArrangeInspection",
      path: "/seller/arrange-inspections",
      key: "arrange-inspections",
      route: "/arrange-inspections",
      element: <ArrangeInspection/>,
    },
    {
      name: "AupplyInspectionDetail",
      path: "/seller/inspections-detail",
      key: "inspections-detail",
      route: "/inspections-detail",
      element: <SupplyInspectionDetail/>,
    },
    {
      name: "SupplyCreditInsurance",
      path: "/seller/credit-insurance",
      key: "credit-insurance",
      route: "/credit-insurance",
      element: <SupplyCreditInsurance/>,
    },
    {
      name: "ApplyCreditInsurance",
      path: "/seller/applycredit-insurance",
      key: "applycredit-insurance",
      route: "/applycredit-insurance",
      element: <ApplyCreditInsurance/>,
    },
    {
      name: "SupplierInvoices",
      path: "/seller/invoices",
      key: "invoices",
      route: "/invoices",
      element: <SupplierInvoices/>,
    },
    {
      name: "SupplyInvoiceDetail",
      path: "/seller/invoices-details",
      key: "invoices-details",
      route: "/invoices-details",
      element: <SupplyInvoiceDetail/>,
    },



    {
      name: "SupplierTermCondition",
      path: "/seller/terms-conditions",
      key: "terms-conditions",
      route: "/terms-conditions",
      element: <SupplierTermCondition/>,
    },
    {
      name: "ContractDetails",
      path: "/seller/contract-details",
      key: "contract-details",
      route: "/contract-details",
      element: <ContractDetails/>,
    },

    
    {
      name: "ProfileDashboard",
      path: "/seller/my-profile",
      key: "my-profile",
      route: "/my-profile",
      element: <SupplierProfileDashboard />,
    },
  
    //   {
    //     path: "",
    //     private: true,
    //     element: <></>,
    //     children: [
    //       {
    //         path: "",
    //         element: "Private Route",
    //       },
    //     ],
    //   },
  ];
  
  export default AvailabilityDashboardRouteArr;
  