import React, { useState } from "react";
import {
  AvailabilityHeader,
  AvailabilitySidebar,
 
  InvoiceQuantityRates,
 
  SupplyInvoiceList,

} from "../../components/Front";
import { motion } from "framer-motion";
import { images } from "../../actions/customFn";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select"; 

const SupplyInvoiceDetail = () => {

  

    const trade_detaillist = [
        { title: "Name", point: "Johan deo" },
        { title: "Email", point: "Glob@gmail.com" },
        { title: "Contact no.", point: "+1 231 256 3233" },
        { title: "Company name", point: "Globex Corporation" }, 
    ]

  return (
    <motion.div
    initial={{ opacity: 0.2 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 1 }}
    transition={{ duration: 2 }}
  >
    <>
      <div className="dashboard-layout">
        <div className="layout_left_side">
          <AvailabilitySidebar />
        </div>

        <div className="layout-right-sider">
          <div className="">
            <AvailabilityHeader headertopic="Invoice Detail" />

            <div className="dashborader_datacontentmain">
               
            <div className="inspectdetail_top invoicedetail_toppart">
      <div className="orderdetail_toppart">
        <div className="left_orderdetail">
          {/* <img className="p_img" src={images["latest_blog1.png"]} alt="" /> */}

          <div className="datalist_flex">
            <h6>INV-7374923654</h6>
            <div className="horizont_divide"></div>
            <div className="date_flex">
                <h6>Inspection Date:</h6>
              <img src={images["gray_date.png"]} alt="" />
              <h6><span>26/07/2024</span></h6>
            </div>
            <div className="horizont_divide"></div>
            <div className="date_flex">
                <h6>Inspection Time:</h6>
              <img src={images["black_clock.png"]} alt="" />
              <h6> <span>03:15 PM</span></h6>
            </div>
            <div className="horizont_divide"></div>
            <div className="unpaid_tag">Unpaid</div>
          </div>
        </div>

<button className="pdf_btn">
    <img src={images['download_btn.png']} alt="" />
</button>
       
      </div>

<div className="orderdetail_toppart bill_totopic">
    <h6>Bill to </h6>
</div>

      <div className="bidlist_detailmainsection quatation_detaillistmain">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="bidlist_uniquelists">
              {trade_detaillist.map((v, i) => {
                return (
                  <div className="build_list1">
                    <div className="row">
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
                        {" "}
                        <p>{v.title}</p>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
                        <h6>{v.point}</h6>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="bidlist_uniquelists">
              {trade_detaillist.map((v, i) => {
                return (
                  <div className="build_list1">
                    <div className="row">
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
                        {" "}
                        <p>{v.title}</p>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
                        <h6>{v.point}</h6>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

           
        </div>

  
      {/* <div className="lets_discussspart">
<p>In you need help with inspection  you can discuss with inspectors and other relevant parties</p>
<button className="button_darkmain">Let’s Discuss</button>
      </div> */}

      </div>

 
    </div>
               
    <InvoiceQuantityRates />
           
 

            </div>
          </div>
        </div>
      </div>
    </>
  </motion.div>
  )
}

export default SupplyInvoiceDetail;