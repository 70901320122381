import React from "react";
import { TabTable } from "../../components/Front";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { NavLink } from "react-router-dom";
import { images } from "../../actions/customFn";
import { motion } from "framer-motion";

function Market() {
  const firsttab_tabledata = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];

  const secondtab_tabledata = [{}, {}, {}, {}, {}, {}];

  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <div className="market_mainpage">
        <div className="pagenavlink_main">
          <div className="container">
            <ul>
              <li>
                {" "}
                <NavLink to={""}>Home</NavLink> /
              </li>
              <li>
                {" "}
                <NavLink to={""}> Listing</NavLink>{" "}
              </li>
            </ul>
          </div>
        </div>

        <div className="market_sectionmain">
          <div className="container">
            <h3 className="heading"> Listings</h3>
          </div>

          <div className="tab_table_sec">
            <div className="">
              <Tabs
                defaultActiveKey="open_rq"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="open_rq" title="Open Requirements">
                  <div className="table-responsive ">
                    <table class="table table-striped table-borderless">
                      <thead>
                        <tr>
                        <th scope="col">ID number</th>
                      <th scope="col">Delivery Port</th>
                      <th scope="col">Date Listed</th>
                      <th scope="col">Product</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Grade</th>
                      <th scope="col">Origin</th>
                      <th scope="col">Total quotes</th>
                      <th scope="col">Incoterns</th>
                      <th scope="col">More </th> 
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {firsttab_tabledata.map((v, i) => {
                          return (
                            <tr>
                            <td scope="row">73749</td>
                          <td>Jebel Ali</td>
                          <td>12.04.24</td>
                          <td>Oaten Hay</td>
                          <td>200 mts</td>
                          <td>Premium</td>
                          <td>Any</td>
                          <td>20 Bid</td>
                          <td>CIF</td>
                          <td>Lorem Ipsum is simply...</td>
                              <td>
                                <NavLink to={"/requirement-detail"}>
                                  View
                                </NavLink>
                                <NavLink to={"#"}>Quote</NavLink>
                                <NavLink to="#">Chat</NavLink>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </Tab>
                <Tab eventKey="current_av" title="Current Availability">
                  <div className="table-responsive ">
                    <table class="table table-striped table-borderless">
                      <thead>
                        <tr>
                        <th scope="col">Pictures</th> 
                      <th scope="col">Product</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Quality</th>
                      <th scope="col">Origin</th>
                      <th scope="col">Location</th>  
                      <th scope="col">Additional information</th>
                      <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody className="current_available_table">
                        {secondtab_tabledata.map((v, i) => {
                          return (
                            <tr>
                                <td scope="row">
                      <img src={images["availabel_img1.png"]} alt="" />
                    </td>
                          <td >
                          Premium Oaten Hay
                          </td>
                          <td>100 metric tons</td>
                          <td>Premium</td>
                          <td>200 mts</td>
                          <td>2864 Scenicview Drive...</td>
                          <td>Lorem Ipsum is simply...</td> 
                              <td>
                                <NavLink to={"/availability-detail"}>
                                  View
                                </NavLink>
                                <NavLink to={"#"}>Bid</NavLink>
                                <NavLink to="#">Chat</NavLink>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>

          <div className="pagination">
            <button className="arrow_button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M7.94993 9.53025C7.80933 9.3896 7.73034 9.19887 7.73034 9C7.73034 8.80113 7.80933 8.6104 7.94993 8.46975L11.3887 5.03025C11.5253 4.8888 11.6009 4.69935 11.5992 4.5027C11.5975 4.30605 11.5186 4.11794 11.3795 3.97889C11.2405 3.83983 11.0524 3.76095 10.8557 3.75924C10.6591 3.75754 10.4696 3.83313 10.3282 3.96975L6.89168 7.40925C6.46987 7.83119 6.23291 8.40338 6.23291 9C6.23291 9.59662 6.46987 10.1688 6.89168 10.5908L10.3312 14.0303C10.4726 14.1669 10.6621 14.2425 10.8587 14.2408C11.0554 14.239 11.2435 14.1602 11.3825 14.0211C11.5216 13.8821 11.6005 13.6939 11.6022 13.4973C11.6039 13.3007 11.5283 13.1112 11.3917 12.9697L7.94993 9.53025Z"
                  fill="#999999"
                />
              </svg>
            </button>
            <button>01</button>
            <button>02</button>
            <button>03</button>
            <button className="arrow_button">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M10.0501 9.53025C10.1907 9.3896 10.2697 9.19887 10.2697 9C10.2697 8.80113 10.1907 8.6104 10.0501 8.46975L6.61132 5.03025C6.4747 4.8888 6.39911 4.69935 6.40081 4.5027C6.40252 4.30605 6.4814 4.11794 6.62046 3.97889C6.75951 3.83983 6.94762 3.76095 7.14427 3.75924C7.34092 3.75754 7.53037 3.83313 7.67182 3.96975L11.1083 7.40925C11.5301 7.83119 11.7671 8.40338 11.7671 9C11.7671 9.59662 11.5301 10.1688 11.1083 10.5908L7.66882 14.0303C7.52737 14.1669 7.33792 14.2425 7.14127 14.2408C6.94462 14.239 6.75651 14.1602 6.61746 14.0211C6.4784 13.8821 6.39952 13.6939 6.39781 13.4973C6.39611 13.3007 6.4717 13.1112 6.60832 12.9697L10.0501 9.53025Z"
                  fill="#999999"
                />
              </svg>{" "}
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Market;
