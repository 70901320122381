import React from 'react';
import { NavLink } from "react-router-dom";
import { images } from "../../../actions/customFn";

const BidsReceivedList = () => {

    const tracking_maintitlles = [
        {title: '#Inspection Id'},
        {title: 'Inspection  Date'},
        {title: 'Inspection Type'},
        {title: 'Location'},
        {title: 'Product'},
        {title: 'Status'},
        {title: 'Action'},
       ];
   
       const firsttb_tabledata = [
         { id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Accepted" },
         {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay',status: 'Pending'},
         {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Accepted" },
         {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Accepted" },
         {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Rejected" },
         {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Pending" },
         {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Pending" },
         {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Rejected" },
         {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Accepted" },
         {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Accepted" },
         {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Pending" },
        
       ];

  return (
    <div className="requirements_listpagemain">
    <div className="requirements_table">
      <div className="table-responsive ">
        <table class="table table-striped table-borderless">
          <thead>
            <tr>
             
              <th scope="col">ID number</th>
              <th scope="col">Date listed</th>
              <th scope="col">Supplier</th>
              <th scope="col">Product</th>
              <th scope="col">Origin</th>
              <th scope="col"> Grade</th>
              <th scope="col">Specification</th>
              <th scope="col">Price</th>
              <th scope="col">Payment terms</th>
              <th scope="col">Quantity</th>
              <th scope="col">Delivery port</th>
              <th scope="col">Incoterms</th>
              <th scope="col">No. of shipments </th>
              <th scope="col">Status</th> 
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {firsttb_tabledata && firsttb_tabledata.map((v, i) => {
              return (
                <tr>
                <td scope="row">73749</td>
                <td>12.04.24</td>
                <td>Jebel Ali</td>
                <td>Oaten Hay</td>
                <td>Any</td>
                <td>Premium</td>
                <td>Specific Detail</td>
                <td>$400/mt</td>
                <td>Any</td>
                <td>200 mts</td>
                <td>Jebel Ali</td>
                <td>CIF</td>
                <td>1</td>
                 
                  <td>
                    <div className="show_status">
                      <p className={v.status == 'Accepted' ? 'completed_tag': v.status == 'Pending' ? 'inprogess_tag' : 'open_tag'}>{v.status}</p>
                    </div>
                  </td>
                  <td className="actions_list">
                    <NavLink to={"/seller/bidsreceived-detail"}>
                      <img src={images["view_btn.png"]} alt="" />
                    </NavLink>
                     
                    {/* <button>
                      <img src={images["edit_btn.png"]} alt="" />
                    </button>
                    <button>
                      <img src={images["download_btn.png"]} alt="" />
                    </button> */}
                    {/* <button>
                      <img src={images["delete_btn.png"]} alt="" />
                    </button> */}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  </div>
  )
}

export default BidsReceivedList;