import React from 'react';
import { images } from "../../../actions/customFn";


function Welcome() {
  return (
    <>
      <div className='welcome_sec'>
          <div className='container'>
            <div className='wel_top'>
              <h3 className='sec_title'>Welcome to SupplyArabia!</h3>
              <p className='wel_para'>At SupplyArabia, we're leading the charge in revolutionizing the online marketplace exclusively dedicated to the supply of raw materials to the Middle East and Arabic-speaking countries. Our mission is clear: to ensure that your buying and selling experience is not only seamless but also secure, effortless, and transparent. Join our vibrant community today and transform the way you do business!
              </p>
            </div>
            <div className='welcome_img_box buyer_supplierpart'>
              <div className='row'>
                  <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                      <img src={images["buyer-supplier.png"]} alt="" />
                  </div>
                  <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pt-sm-4'>
                      <h5>For Buyers and Suppliers Alike</h5>
                      <p>Whether you're a buyer from the Middle East seeking to diversify your supply sources or a global supplier looking to tap into Arabic-speaking markets, SupplyArabia is your ultimate destination. Build your network of reliable partners and kickstart your trading journey on our platform.</p>
                  </div>
              </div>
            </div>
            <div className='welcome_img_box buyer_supplierpart'>
              <div className='row'>
                  <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1 order-sm-2 pt-sm-4'>
                      <h5>Products We Trade</h5>
                      <p>At SupplyArabia, we are embarking on our journey with a focus on Forage & Fodder within the agricultural space, particularly Hay, Grass & Straw. As we establish ourselves as experts in this exchange, we will expand into more agricultural products and eventually venture into other raw material sectors across the Middle East marketplace.</p>
                  </div>
                  <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 order-lg-2 order-sm-1'>
                      <img src={images["trade.png"]} alt="" />
                  </div>
              </div>
            </div>
                        
          </div>
      </div>
    </>
  )
}

export default Welcome;