import React from 'react'; 
import { NavLink } from "react-router-dom";
import { images } from "../../../actions/customFn";

const InspectionList = () => {

    const tracking_maintitlles = [
        {title: '#Inspection Id'},
        {title: 'Inspection  Date'},
        {title: 'Inspection Type'},
        {title: 'Location'},
        {title: 'Product'},
        {title: 'Status'},
        {title: 'Action'},
       ];
   
       const firsttb_tabledata = [
         { id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Completed" },
         {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay',status: 'Inprogress'},
         {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Completed" },
         {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Completed" },
         {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Open" },
         {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Inprogress" },
         {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Inprogress" },
         {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Open" },
         {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Completed" },
         {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Completed" },
         {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Inprogress" },
        
       ];

  return (
    <div className="requirements_listpagemain">
    <div className="requirements_table">
      <div className="table-responsive ">
        <table class="table table-striped table-borderless">
          <thead>
            <tr>
              {
                tracking_maintitlles.map((v,i)=>{
                  return(
                    <th scope="col">{v.title}</th>
                  )
                })
              }
              {/* <th scope="col">#Shipment Id</th>
              <th scope="col">Order Date</th>
              <th scope="col">Quantity</th>
              <th scope="col">Origin</th>
              <th scope="col">Status</th> 
              <th scope="col">Action</th> */}
            </tr>
          </thead>
          <tbody>
            {firsttb_tabledata && firsttb_tabledata.map((v, i) => {
              return (
                <tr>
                 {v.id && <td scope="row">{v.id}</td> }
                 {v.date && <td>{v.date}</td>  }
                 {v.quantity && <td>{v.quantity}</td>  }
                {v.type && <td>{v.type}</td> }
                {v.location && <td>{v.location}</td> }
                {v.product &&  <td>{v.product}</td> }
                  <td>
                    <div className="show_status">
                      <p className={v.status == 'Completed' ? 'completed_tag': v.status == 'Inprogress' ? 'inprogess_tag' : 'open_tag'}>{v.status}</p>
                    </div>
                  </td>
                  <td className="actions_list">
                    <NavLink to={"/buyer/inspection-detail"}>
                      <img src={images["view_btn.png"]} alt="" />
                    </NavLink>
                     
                    <button>
                      <img src={images["edit_btn.png"]} alt="" />
                    </button>
                    <button>
                      <img src={images["download_btn.png"]} alt="" />
                    </button>
                    <button>
                      <img src={images["delete_btn.png"]} alt="" />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  </div>
  )
}

export default InspectionList;