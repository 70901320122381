import React from "react";
import { images } from "../../../actions/customFn";

const BidContactBuyer = () => {
  return (
    <div className="bidcontact_buyersection">
      <h6>Additional Information</h6>
      <h6>
        <span>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book.
        </span>
      </h6>

      <div className="supplier_details">
        <h4>Suppliers Details</h4>

        <div className="flex_profile">
          <img src={images["profile_1img.png"]} alt="" />
          <h2>Trent B. Brooks</h2>
        </div>

        <div className="firmbid_informationmain">
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="product_typelists">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-5">
                    <p>Name</p>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-7">
                    <h6>Clifford M. Pereira</h6>
                  </div>
                </div>
              </div>
              <div className="product_typelists">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-5">
                    <p>Email</p>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-7">
                    <h6>Glob@gmail.com</h6>
                  </div>
                </div>
              </div>
              <div className="product_typelists">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-5">
                    <p>Telephone Number</p>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-7">
                    <h6>+1 231 256 3233</h6>
                  </div>
                </div>
              </div>
              <div className="product_typelists">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-5">
                    <p>Company Name</p>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-7">
                    <h6>Globex Corporation</h6>
                  </div>
                </div>
              </div>
              <div className="product_typelists">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-5">
                    <p>Address</p>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-7">
                    <h6>Lorem Ipsum is simply dummy text of the printing.</h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="product_typelists">
                <div className="row">
                  <div className="col-xl-6 col-lg-7 col-md-7 col-sm-7 col-5">
                    <p>Role</p>
                  </div>
                  <div className="col-xl-6 col-lg-5 col-md-5 col-sm-5 col-7">
                    <h6>Lorem Ipsum</h6>
                  </div>
                </div>
              </div>
              <div className="product_typelists">
                <div className="row">
                  <div className="col-xl-6 col-lg-7 col-md-7 col-sm-7 col-5">
                    <p>Activities</p>
                  </div>
                  <div className="col-xl-6 col-lg-5 col-md-5 col-sm-5 col-7">
                    <h6>Lorem Ipsum</h6>
                  </div>
                </div>
              </div>

              <div className="product_typelists">
                <div className="row">
                  <div className="col-xl-6 col-lg-7 col-md-7 col-sm-7 col-5">
                    <p>Services/products offered</p>
                  </div>
                  <div className="col-xl-6 col-lg-5 col-md-5 col-sm-5 col-7">
                    <h6>Oaten Hay</h6>
                  </div>
                </div>
              </div>

              <div className="product_typelists">
                <div className="row">
                  <div className="col-xl-6 col-lg-7 col-md-7 col-sm-7 col-5">
                    <p>Volumes</p>
                  </div>
                  <div className="col-xl-6 col-lg-5 col-md-5 col-sm-5 col-7">
                    <h6>200mts</h6>
                  </div>
                </div>
              </div>

              <div className="product_typelists">
                <div className="row">
                  <div className="col-xl-6 col-lg-7 col-md-7 col-sm-7 col-5">
                    <p>Business categories</p>
                  </div>
                  <div className="col-xl-6 col-lg-5 col-md-5 col-sm-5 col-7">
                    <h6>Finance </h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="product_typelists">
                <div className="row">
                  <div className="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-5">
                    <p>Website link</p>
                  </div>
                  <div className="col-xl-7 col-lg-6 col-md-6 col-sm-6 col-7">
                    <h6>www.abc.com</h6>
                  </div>
                </div>
              </div>

              <div className="product_typelists">
                <div className="row">
                  <div className="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-5">
                    <p>Brief description</p>
                  </div>
                  <div className="col-xl-7 col-lg-6 col-md-6 col-sm-6 col-7">
                    <h6>
                      Lorem Ipsum is simply dummy text of the printing.Lorem
                      Ipsum is simply dummy text of the printing.
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contact_buyerbtn">
          <button className="button_darkmain">Contact Supplier</button>
        </div>
      </div>
    </div>
  );
};

export default BidContactBuyer;
