import React from "react";
import {
  AddRequirement,
  DashboardHeader,
  DashboardSidebar,
} from "../../components/Front";
import { motion } from "framer-motion";

const AddRequirementForm = () => {
  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <>
        <div className="dashboard-layout">
          <div className="layout_left_side">
            <DashboardSidebar />
          </div>

          <div className="layout-right-sider">
            <div className="">
              <DashboardHeader headertopic="Add Requirements" />

              <div className="dashborader_datacontentmain">
                <AddRequirement />
              </div>
            </div>
          </div>
        </div>
      </>
    </motion.div>
  );
};

export default AddRequirementForm;
