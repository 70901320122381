import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { motion } from "framer-motion";

const Master = () => {
  return (
    <>
      <div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 2 }}
      >
        {/* <Header /> */}
        <Outlet />
        {/* <Footer /> */}
      </div>
    </>
  );
};

export default Master;