import React, { useState } from "react";
import {
  AvailabilityHeader,
  AvailabilitySidebar,
  BidListDetail,
  BidReceiveBuyDetail,
  MyQuotationList,
} from "../../components/Front";
import { motion } from "framer-motion";
import { images } from "../../actions/customFn";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { NavLink } from "react-router-dom";

const MyQuotationDashboard = () => {
  const [openfilter, setOpenfilter] = useState(false);
  const [select_status, setSelectStatus] = useState();

  const handleSelectStatus = (event) => {
    setSelectStatus(event.target.value);
  };

  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <>
        <div className="dashboard-layout">
          <div className="layout_left_side">
            <AvailabilitySidebar />
          </div>

          <div className="layout-right-sider">
            <div className="">
              <AvailabilityHeader headertopic="My Quotations" />

              <div className="dashborader_datacontentmain">
                <div className="addrequirements_topmain tracking_topsection">
                  <div className="left_search">
                    <div className="search_tracking">
                      <input
                        type="text"
                        placeholder="Enter Inspection details to track your  inspection"
                      />
                      <button className="button_darkmain">
                        <img src={images["search_img.png"]} alt="" />
                      </button>
                    </div>

                    <button
                      className="filter_button"
                      onClick={() => setOpenfilter(!openfilter)}
                    >
                      <span>Filter</span>
                      <img src={images["filter_icon.png"]} alt="" />
                    </button>
                  </div>
                </div>

                {openfilter == true && (
                  <div className="addrequirements_topmain tracking_topsection">
                    <div className="left_search">
                      <div className="arabia_datepcker ">
                        <Flatpickr
                          name="booking_date"
                          placeholder="Select Date"
                          className="flat_datepicinput"
                        />
                        <span className="date_timeicon">
                          <img src={images["gray_date.png"]} alt="" />
                        </span>
                      </div>

                      <div className="material_selector">
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl fullWidth>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={select_status}
                              defaultValue={10}
                              onChange={handleSelectStatus}
                            >
                              <MenuItem value={10}>Status </MenuItem>
                              <MenuItem value={20}>Status </MenuItem>
                              <MenuItem value={30}>Status </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </div>

                      <button className="filter_button">
                        <img src={images["search_img.png"]} alt="" />
                      </button>
                    </div>
                  </div>
                )}

                <MyQuotationList />

              </div>
            </div>
          </div>
        </div>
      </>
    </motion.div>
  );
};

export default MyQuotationDashboard;
