import React from 'react'; 
import {
    AvailabilityHeader,
  AvailabilitySidebar,
  CreditInsuranceList,
  
  
} from "../../components/Front";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { images } from "../../actions/customFn";

const SupplyCreditInsurance = () => {
  return (
    <motion.div
    initial={{ opacity: 0.2 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 1 }}
    transition={{ duration: 2 }}
  >
    <>
      <div className="dashboard-layout">
        <div className="layout_left_side">
          <AvailabilitySidebar />
        </div>

        <div className="layout-right-sider">
          <div className="">
            <AvailabilityHeader headertopic="Credit Insurance" />

            <div className="dashborader_datacontentmain">
           
            <div className="addrequirements_topmain">
                <div>
                  <h5>Apply for Credit Insurance </h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>

                <div className="requirements_addbtns">
                  <NavLink to={"/seller/applycredit-insurance"}>
                    <div className="addrequire_btn">
                       
                      <span>Apply</span>
                    </div>
                  </NavLink>
                </div>
              </div>

              {/* <AvailabilityList /> */}
              <CreditInsuranceList />
           

            </div>

          </div>
        </div>
      </div>
    </>
  </motion.div>
  )
}

export default SupplyCreditInsurance;