import React from "react";
import {
  DashboardHeader,
  DashboardRequirements,
  DashboardSidebar,
} from "../../components/Front";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";

const BuyerDashboard = () => {
  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <>
        <div className="dashboard-layout">
          <div className="layout_left_side">
            <DashboardSidebar />
          </div>

          <div className="layout-right-sider">
            <div className="">
              <DashboardHeader headertopic="Dashboard" />

              <div className="dashborader_datacontentmain">
                {/* <DashboardBoxes /> */}
                <DashboardRequirements />
              </div>
              {/* <div className="col-12">
                <div className="dashboard_termfooter">
                  <p>
                    This site is protected by reCAPTCHA and the Google 
                    <NavLink to={""}> Privacy Policy</NavLink> and 
                    <NavLink to={""}> Terms of Service </NavLink> apply.
                  </p>
                </div>
              </div> */}

            </div>
          </div>
        </div>
      </>
    </motion.div>
  );
};

export default BuyerDashboard;
