import React from 'react'
import { images } from '../../../actions/customFn'

const QuotationBuyerDetail = () => {

  const buyerdetail_list2 = [
    { title: "Role", points: "Lorem Ipsum" },
    { title: "Activities", points: "Lorem Ipsum" },
    { title: "Services/products offered", points: "Oaten Hay" },
    { title: "Volumes", points: "200mts" },
    { title: "Business categories", points: "Finance" },
  ];

  return (
    <div className="bidreceived_buydetails">
    <div className="firmbid_informationmain ">
      <div className="top_titleflex">
        <h4>Buyers Details</h4>
        {/* <button className="backto_bid">
          <img src={images["red_back.png"]} alt="" />
          Reply to Bid
        </button> */}
      </div>

      <div className="row">
        <div className="col-xl-1 col-lg-2 col-md-2 col-sm-12 col-12">
          <div className="topprofile">
            <img src={images["person1.png"]} alt="" />
            {/* <h4>Trent B. Brooks</h4> */}
          </div>
        </div>
        <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12">
            {buyerdetail_list2.map((v, i) => {
              return (
                <div className="product_typelists">
                  <div className="row">
                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                      <p>Name</p>
                    </div>
                    <div className="col-xl-7 col-lg-7 col-md-7 col-sm-6 col-6">
                      <h6>Clifford M. Pereira</h6>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

        <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
            {buyerdetail_list2.map((v, i) => {
              return (
                <div className="product_typelists">
                  <div className="row">
                    <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-6">
                      <p>{v.title}</p>
                    </div>
                    <div className="col-xl-6 col-lg-7 col-md-7 col-sm-6 col-6">
                      <h6>{v.points}</h6>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="product_typelists">
              <div className="row">
                <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-6">
                  <p>Website link</p>
                </div>
                <div className="col-xl-6 col-lg-7 col-md-7 col-sm-6 col-6">
                  <h6>www.abc.com</h6>
                </div>
              </div>
            </div>

            <div className="product_typelists">
              <div className="row">
                <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-6">
                  <p>Brief description</p>
                </div>
                <div className="col-xl-6 col-lg-7 col-md-7 col-sm-6 col-6">
                  <h6>
                    Lorem Ipsum is simply dummy text of the printing.Lorem
                    Ipsum is simply dummy text of the printing.Lorem Ipsum is
                    simply dummy text of the printing.
                  </h6>
                </div>
              </div>
            </div>
          </div>
          
      </div>

      <div className=" quptaion_supliercontact">
          <button className="button_darkmain">Contact Supplier</button>
        </div>

    </div>

   

    {/* <div className="firmbid_buttonssection">
      <button>Reject</button>
      <button>Accept</button>
    </div> */}
  </div>
  )
}

export default QuotationBuyerDetail