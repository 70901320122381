import React from "react";
import { Banner, ContactUsForm } from "../../components/Front";
import { images } from "../../actions/customFn";
import { motion } from "framer-motion";

const ContactUs = () => {
  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <div className="contactus_mainpage">
        <Banner title="Contact us" bg={images["contactus_bgimg.png"]} />

        <div className="contactus_contentsection">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="contact_leftinfo">
                  <h4>Contact info</h4>

                  <div className="info_flex">
                    <img src={images["email.png"]} alt="" />
                    <div>
                      <p>Email</p>
                      <h6>support@supplyarabia.com</h6>
                    </div>
                  </div>
                  <div className="info_flex">
                    <img src={images["phone.png"]} alt="" />
                    <div>
                      <p>Phone</p>
                      <h6>+4536991363</h6>
                    </div>
                  </div>

                  <div className="below_lastimg">
                    <img src={images["contact_infobg.png"]} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-6 col-sm-12 col-12">
                <ContactUsForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ContactUs;
