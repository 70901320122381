import React from "react";
import { 
  DashboardHeader, 
  DashboardSidebar,
  RequirementsLists,
} from "../../components/Front";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";

const RequirementsDashboard = () => {
  return (
    <motion.div
    initial={{ opacity: 0.2 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 1 }}
    transition={{ duration: 2 }}
  >
    <>
      <div className="dashboard-layout">
        <div className="layout_left_side">
          <DashboardSidebar />
        </div>

        <div className="layout-right-sider">
          <div className="">
            <DashboardHeader headertopic="My Requirements" />

            <div className="dashborader_datacontentmain">
              <div className="addrequirements_topmain">
                <div>
                  <h5>Make a Requirement</h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>

                <div className="requirements_addbtns">
                  <NavLink to={"/buyer/add-requirement"}>
                    <div className="addrequire_btn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="18"
                        viewBox="0 0 19 18"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_342_904)">
                          <path
                            d="M13.7401 8.99545C13.7401 8.79658 13.6611 8.60586 13.5205 8.46524C13.3798 8.32461 13.1891 8.2456 12.9903 8.24557H10.2474L10.2474 5.5027C10.244 5.30607 10.1635 5.11866 10.0232 4.98082C9.88295 4.84297 9.69416 4.76573 9.4975 4.76573C9.30085 4.76573 9.11206 4.84297 8.9718 4.98082C8.83154 5.11866 8.75103 5.30607 8.74762 5.5027L8.74762 8.24557L6.00475 8.24557C5.80812 8.24898 5.62071 8.32949 5.48286 8.46975C5.34502 8.61001 5.26778 8.7988 5.26778 8.99545C5.26778 9.19211 5.34502 9.3809 5.48286 9.52116C5.62071 9.66141 5.80812 9.74192 6.00475 9.74534L8.74762 9.74534L8.74762 12.4882C8.75103 12.6848 8.83154 12.8722 8.9718 13.0101C9.11206 13.1479 9.30085 13.2252 9.4975 13.2252C9.69416 13.2252 9.88295 13.1479 10.0232 13.0101C10.1635 12.8722 10.244 12.6848 10.2474 12.4882L10.2474 9.74534L12.9903 9.74534C13.1891 9.74531 13.3798 9.66629 13.5205 9.52567C13.6611 9.38505 13.7401 9.19433 13.7401 8.99545Z"
                            fill="black"
                          />
                          <path
                            d="M9.5 0C7.71997 0 5.97991 0.527841 4.49987 1.51677C3.01983 2.50571 1.86628 3.91131 1.18509 5.55585C0.5039 7.20038 0.32567 9.00998 0.672937 10.7558C1.0202 12.5016 1.87737 14.1053 3.13604 15.364C4.39472 16.6226 5.99836 17.4798 7.74419 17.8271C9.49002 18.1743 11.2996 17.9961 12.9442 17.3149C14.5887 16.6337 15.9943 15.4802 16.9832 14.0001C17.9722 12.5201 18.5 10.78 18.5 9C18.4974 6.61384 17.5484 4.32616 15.8611 2.63889C14.1738 0.951621 11.8862 0.00258081 9.5 0ZM9.5 16.5C8.01664 16.5 6.5666 16.0601 5.33323 15.236C4.09986 14.4119 3.13856 13.2406 2.57091 11.8701C2.00325 10.4997 1.85473 8.99168 2.14411 7.53682C2.4335 6.08197 3.14781 4.74559 4.1967 3.6967C5.2456 2.64781 6.58197 1.9335 8.03683 1.64411C9.49168 1.35472 10.9997 1.50325 12.3701 2.0709C13.7406 2.63856 14.9119 3.59985 15.736 4.83322C16.5601 6.06659 17 7.51664 17 9C16.9978 10.9885 16.2069 12.8948 14.8009 14.3009C13.3948 15.7069 11.4885 16.4978 9.5 16.5Z"
                            fill="black"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_342_904">
                            <rect
                              width="18"
                              height="18"
                              fill="white"
                              transform="translate(0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>{" "}
                      <span>Add</span>
                    </div>
                  </NavLink>
                </div>
              </div>

              <RequirementsLists />


            </div>
          </div>
        </div>
      </div>
    </>
    </motion.div>
  );
};

export default RequirementsDashboard;
