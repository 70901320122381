import React from 'react'
import { NavLink } from "react-router-dom";
function Join() {
  return (
    <>
        <div className='about_join_sec'>
            <div className='container'>
                <div className='join_row'>
                    <h3>Join Today</h3>
                    <NavLink to="#" className="join_btn">
                    Join Now
                    </NavLink>
                </div>                
            </div>            
        </div>
    </>
  )
}

export default Join;