import React from 'react'
import { images } from '../../../actions/customFn';

const HomeServices = () => {

    const servicedata = [
        {dataimg: images['home_service1.png'], title:'Inspections' },
        {dataimg: images['home_service2.png'], title:'Verifications' },
        {dataimg: images['home_service3.png'], title:'Tracking' },
        {dataimg: images['home_service4.png'], title:'Credit Insurance' },
        {dataimg: images['home_service5.png'], title:'Sales Support' },
    ]

  return (
    <div className="homeservices_sectionmain">
        <div className="container"> 
        <div className="services_listflex">
            <div className="service_titlecard servicecard_samesize">
                <h2>Services</h2>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
            </div>
{
    servicedata.map((v,i)=>{
        return(
<div className="service_inspectioncards1 servicecard_samesize" key={i}>
<img src={v.dataimg} alt="" />
<h6>{v.title}</h6>

</div>
        )
    })
}



            </div>
        </div>
    </div>
  )
}

export default HomeServices;