import React, { useEffect } from "react";
import "../../assets/vendor/css/bootstrap.css";
import "../../assets/vendor/css/style.css";
import "../../assets/vendor/css/responsive.css";
import "../../assets/vendor/dist/assets/owl.carousel.css";
import "../../assets/vendor/dist/assets/owl.theme.default.min.css";
// import RouteArr from "../../hooks/GetRoutes";
import { useLocation, useNavigate } from "react-router-dom";
import { Master } from "../../components/Front";

function FrontApp() {
  // Top To Scroll
  const routePath = useLocation();
  const navigate = useNavigate();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  return <Master />;
}

export default FrontApp;
