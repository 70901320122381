import React from 'react';
// import SupplierImage from '../../assets/images/home/supplier.png';
// import BuyerImage from '../../assets/images/home/buyer.png';
// import BusinessImage from '../../assets/images/home/business.png';
import { images } from "../../../actions/customFn";

function Community() {
  return (
    <>
        <div className='community_sec'>
            <div className='container'>
                <h3>The Community</h3>
                <div className='community_col'>
                    <div className='box'>
                        <img src={images["supplier.png"]} alt="supplier" />
                        <h5>Suppliers</h5>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                    </div>
                    <div className='box'>
                        <img src={images["buyer.png"]} alt="supplier" />
                        <h5>Buyers</h5>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                    </div>
                    <div className='box'>
                        <img src={images["business.png"]} alt="supplier" />
                        <h5>Business Services</h5>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Community;