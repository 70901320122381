import React from 'react'
import { images } from '../../../actions/customFn';

const QuatationDetailList = () => {

    const bidlist = [
        { title: "Company", point: "Agrimark" },
        { title: "Grade", point: "Premium" },
        { title: "Delivery Port", point: "Jebel Ali" },
        { title: "Origin", point: "USA" },
        { title: "Delivery Port", point: "Jebel Ali" },
       
      ];

  return (
    <div className="bidlist_detailmainsection quatation_detaillistmain">
      <div className="row">
       
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">

          <div className="bidlist_uniquelists">
   
              {bidlist.map((v, i) => {
                return (
        
                    <div className="build_list1">
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                          {" "}
                          <p>{v.title}</p>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                          <h6>{v.point}</h6>
                        </div>
                      </div>
                    </div>
           
                );
              })}
     
          </div>

        </div>

        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">

          <div className="bidlist_uniquelists">
   
              {bidlist.map((v, i) => {
                return (
        
                    <div className="build_list1">
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                          {" "}
                          <p>{v.title}</p>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                          <h6>{v.point}</h6>
                        </div>
                      </div>
                    </div>
           
                );
              })}
     
          </div>

        </div>

        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">

          <div className="bidlist_uniquelists">
   
              
                    <div className="build_list1">
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                          {" "}
                          <p>Payment Terms</p>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                          <h6>CAD</h6>
                        </div>
                      </div>
                    </div>
            
     
          </div>

        </div>

      

      </div>

      <div className="download_pdfsection">
            <button><img src={images['download_btn.png']} alt="" /></button>
        </div>

    </div>
  )
}

export default QuatationDetailList