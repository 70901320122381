import React from 'react'
import { AvailabilityClientList, AvailabilityHeader, AvailabilitySidebar, BidListDetail, DashboardHeader, DashboardSidebar, RequirementDescription, RequirementsClientList } from '../../components/Front'
import { motion } from "framer-motion";

const AvailabilityDetailDashboard = () => {
  return (
    <motion.div
    initial={{ opacity: 0.2 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 1 }}
    transition={{ duration: 2 }}
  >
    <>
    <div className="dashboard-layout">
      <div className="layout_left_side">
        <AvailabilitySidebar />
      </div>

      <div className="layout-right-sider">
        <div className="">
          <AvailabilityHeader headertopic="Availability  Details" />

          <div className="dashborader_datacontentmain">
<BidListDetail />
<div className="bids_outerinfopart mb-3">  
<div className=" bidsreceive_dashbinfo">
        <p>Additional Information</p>
        <h6>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text
          ever since the 1500s, when an unknown printer took a galley of type
          and scrambled it to make a type specimen book.
        </h6>
      </div>
      </div>

           <AvailabilityClientList topic={"Intrested Supplier List"} />

          </div>
        </div>
      </div>
    </div>
  </>
  </motion.div>
  )
}

export default AvailabilityDetailDashboard;