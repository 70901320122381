import React from "react";
import { images } from "../../../actions/customFn";
import { NavLink } from "react-router-dom";

const AvailabilityList = () => {
  const secondtab_tabledata = [
    { id:'INV-7374923654', date: '12.04.24', duedate: 'name', type:'Oaten Hay', location: 'Any', product: '$100/mt', status: "Completed" },
    {id:'73749', date: '12.04.24',name: 'name',  type:'Oaten Hay', location: 'Any', product: '$100/mt',status: 'InProgress'},
    {id:'73749', date: '12.04.24',name: 'name',  type:'Oaten Hay', location: 'Any', product: '$100/mt ', status: "Completed" },
    {id:'73749', date: '12.04.24',name: 'name',  type:'Oaten Hay', location: 'Any', product: '$100/mt ', status: "Completed" },
    {id:'73749', date: '12.04.24',name: 'name',  type:'Oaten Hay', location: 'Any', product: '$100/mt ', status: "Open" },
    {id:'73749', date: '12.04.24', name: 'name', type:'Oaten Hay', location: 'Any', product: '$100/mt ', status: "InProgress" },  
    {id:'73749', date: '12.04.24',name: 'name',  type:'Oaten Hay', location: 'Any', product: '$100/mt ', status: "Open" },
  ];

  return (
    <div className="requirements_listpagemain ">
      <div className="requirements_table">
        <div className="table-responsive ">
          <table class="table table-striped table-borderless">
            <thead>
              <tr>
                <th scope="col">Lot number</th>
                <th scope="col">Date Listed</th>
                <th scope="col">Product</th> 
                <th scope="col">Origin</th>
                <th scope="col">Grade</th>
                <th scope="col">Specification</th>
                <th scope="col">Quantity</th>
                <th scope="col">Shipping Period</th>
                {/* <th scope="col"> Additional information</th>
                <th scope="col">Total quotes</th>
                <th scope="col">Status</th> */}
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody className="">
              {secondtab_tabledata.map((v, i) => {
                return (
                  <tr>
                    <td scope="row">
                    73749
                      {/* <img src={images["availabel_img1.png"]} alt="" /> */}
                    </td>
                    <td>12.04.24</td>
                    <td>Oaten Hay</td>
                    <td>Any</td>
                    <td>Premium</td> 
                    <td>Specific Detail</td> 
                    <td>200mts</td> 
                    <td>Mar-Dec</td> 
                    {/* <td>Lorem Ipsum is simply...</td>
                    <td>20 quote</td> */}
                    {/* <td>12.04.24</td> */}
                    {/* <td>
                    <div className="show_status">
                      <p className={v.status == 'Completed' ? 'completed_tag': v.status == 'InProgress' ? 'inprogess_tag' : 'open_tag'}>{v.status}</p>
                    </div>
                  </td> */}
                    <td className="actions_list">
                      <NavLink to={"/seller/availability-details"}>
                        {" "}
                        <img src={images["view_btn.png"]} alt="" />{" "}
                      </NavLink>
                      <NavLink to={""}>
                        {" "}
                        <img src={images["edit_btn.png"]} alt="" />{" "}
                      </NavLink>
                      <button>
                        <img src={images["delete_btn.png"]} alt="" />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AvailabilityList;
