import React from "react";
import { images } from "../../../actions/customFn";

const BidListDetail = () => {
  const bidlist = [
    { title: "ID number", point: "73749" },
    { title: "Quantity", point: "200 mts" },
    { title: "Date listed", point: "12.04.24" },
    { title: "Delivery Port", point: "Jebel Ali" },
    { title: "Product", point: "Oaten Hay" },
    { title: "Incoterms", point: "CIF" },
    { title: "Origin", point: "Any" },
    { title: "No. of shipments", point: "6" },
    { title: "Grade", point: "Premium" },
    { title: "Delivery period", point: "March-December" },
    { title: "Specification", point: "Specific Detail" },
  ];

  return (
    <div className="bidlist_detailmainsection">
      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <div className="bidlist_imagedetailflex">
            <img
              className="main_img"
              src={images["requirement_1.png"]}
              alt=""
            />

            <div className="biddetail_leftcontent">
              <h6>Premium Alfalfa</h6>
              <h2>
                $ 435 <span>/mt</span>{" "}
              </h2>

              {/* <div className="total_bidspart">
                <div className="">
                  <p>Total Bids</p>
                  <h4>20</h4>
                </div>
                <div className="bid_count">
                  <p>
                    {" "}
                    <img src={images["small_time.png"]} alt="" /> Add Product
                    Time
                  </p>
                  <h6>2 : 12 : 05</h6>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <div className="bidlist_uniquelists">
            <div className="row">
              {bidlist.map((v, i) => {
                return (
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="build_list1">
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                          {" "}
                          <p>{v.title}</p>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                          <h6>{v.point}</h6>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BidListDetail;
