import React from 'react'
import { images } from '../../../actions/customFn';
import { NavLink } from 'react-router-dom';

const QuatationList = () => {

    const firsttab_tabledata = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];

  return (
    <div className="requirements_listpagemain">   

    <div className="requirements_table">
          <div className="table-responsive ">
            <table class="table table-striped table-borderless">
              <thead>
                <tr>
                  <th scope="col">ID number</th>
                  <th scope="col">Date listed</th>
               
                  <th scope="col">Product</th>
                  <th scope="col">Origin</th>
                  <th scope="col">Grade</th>
                 
                  <th scope="col">Specification</th>
                  <th scope="col">Price</th>
                  <th scope="col">Payment terms</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Delivery port</th>
                  <th scope="col">Incoterms</th>
                  <th scope="col">No. of Shipments</th>
                  <th scope="col">Delivery Period</th>
                  <th scope="col">Additional Information</th>
                  <th scope="col">Actions</th>
                  
                </tr>
              </thead>
              <tbody>
                {firsttab_tabledata.map((v, i) => {
                  return (
                    <tr>
                      <td scope="row">73749</td>
                      <td>12.04.24</td>
                      <td>Oaten Hay</td>
                      <td>Any</td>
                      <td>Premium</td>
                      <td>Premium</td>
                      <td>$400/mt</td>
                      <td>CAD</td>
                      <td>200 mts</td>
                      <td>Jebel Ali</td>
                      <td>CIF</td>
                      <td>1</td>
                      <td>12.04.24</td>
                      <td>Lorem Ipsum is simply...</td>
                  
                      <td className='actions_list'>
                         <NavLink to={'/buyer/quatation-detail'} > <div className="square">View </div> </NavLink> 
                         <NavLink to={'#'} > <div className="square">Chat </div> </NavLink> 
                         <NavLink to={'/buyer/firm-quote'} > <div className="square">Firm quotation </div> </NavLink> 
                       
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        </div>
  )
}

export default QuatationList