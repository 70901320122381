import React from 'react'; 
import {
    AvailabilityHeader,
  AvailabilitySidebar,
  
  
} from "../../components/Front";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { images } from "../../actions/customFn";

const SupplierTermCondition = () => {
  return (
    <motion.div
    initial={{ opacity: 0.2 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 1 }}
    transition={{ duration: 2 }}
  >
    <>
      <div className="dashboard-layout">
        <div className="layout_left_side">
          <AvailabilitySidebar />
        </div>

        <div className="layout-right-sider">
          <div className="">
            <AvailabilityHeader headertopic="Terms and conditions" />

            <div className="dashborader_datacontentmain">
           
            <div className="term_condition_pagemain">
            <h5>Introduction</h5>
            <p>
              Welcome to SupplyArabia. These Terms and Conditions ("Terms")
              govern your use of our website and services. By accessing or using
              SupplyArabia, you agree to comply with and be bound by these
              Terms.
            </p>

            <h5>Platform Overview</h5>
            <p>
              SupplyArabia is a marketplace platform designed to connect buyers
              and suppliers. We facilitate the introduction of parties but do
              not participate in the actual transactions or agreements made
              between users.
            </p>

            <h5>No Liability for Transactions</h5>
            <p>
              SupplyArabia is not a party to any transaction or contract between
              buyers and suppliers. We do not endorse, guarantee or assume
              responsibility for any product, service, transaction, or
              information provided by users. Any disputes or claims arising out
              of a transaction conducted on SupplyArabia are solely between the
              buyer and the supplier.
            </p>

            <h5>User Responsibilities</h5>
            <div className="para_listing">
              <p>
                {" "}
                <span>Accuracy of Information : </span> Users are responsible
                for providing accurate, current, and complete information when
                registering and conducting transactions on SupplyArabia.
              </p>
              <p>
                <span>Compliance : </span> Users must comply with all applicable
                laws and regulations while using our platform
              </p>
              <p>
                <span>Dispute Resolution :</span>Users must comply with all
                applicable laws and regulations while using our platform
                involved in the transaction
              </p>
            </div>

            <h5>Settlement Process</h5>
            <div className="para_listing">
              <p>
                {" "}
                <span>Accuracy of Information : </span> Users are responsible
                for providing accurate, current, and complete information when
                registering and conducting transactions on SupplyArabia.
              </p>
              <p>
                <span>Compliance : </span> Users must comply with all applicable
                laws and regulations while using our platform
              </p>
              <p>
                <span>Dispute Resolution :</span>Users must comply with all
                applicable laws and regulations while using our platform
                involved in the transaction
              </p>
            </div>

            <h5>Disclaimer of Warranties</h5>
            <p>
              SupplyArabia provides the platform on an "as is" and "as
              available" basis. We make no representations or warranties of any
              kind, express or implied, regarding the operation of the platform
              or the information, content, materials, or products included on
              the platform.
            </p>

            <h5>Limitation of Liability</h5>
            <p>
              To the maximum extent permitted by law, SupplyArabia shall not be
              liable for any indirect, incidental, special, consequential, or
              punitive damages, or any loss of profits or revenues, whether
              incurred directly or indirectly, or any loss of data, use,
              goodwill, or other intangible losses, resulting from
            </p>

            <ul>
              <li>Your use of or inability to use the platform.</li>
              <li>
                Any conduct or content of any third party on the platform.
              </li>
              <li>Your use of or inability to use the platform.</li>
              <li>
                Any conduct or content of any third party on the platform.
              </li>
            </ul>

            <h5>Indemnification</h5>
            <p>
              You agree to indemnify, defend, and hold harmless SupplyArabia and
              its affiliates, officers, directors, employees, and agents from
              and against any claims, liabilities, damages, losses, and
              expenses, including without limitation, reasonable legal and
              accounting fees, arising out of or in any way connected with your
              access to or use of the platform or your violation of these Terms.
            </p>

            <h5>Termination</h5>
            <p>
              SupplyArabia reserves the right to terminate or suspend your
              account and access to the platform at our sole discretion, without
              notice, for conduct that we believe violates these Terms or is
              harmful to other users of the platform, us, or third parties, or
              for any other reason.
            </p>
            <h5>Governing Law</h5>
            <p>
              These Terms shall be governed and construed in accordance with the
              laws of [Your Country/Region], without regard to its conflict of
              law provisions. Any legal action or proceeding arising under these
              Terms will be brought exclusively in the courts located in [Your
              Jurisdiction], and the parties hereby consent to such jurisdiction
              and venue.
            </p>

            <h5>Changes to Terms</h5>
            <p>
              We reserve the right to modify or replace these Terms at any time.
              If a revision is material, we will provide at least [30 days]
              notice prior to any new terms taking effect. What constitutes a
              material change will be determined at our sole discretion.
            </p>

            <h5>Contact Us</h5>
            <p>
              If you have any questions about these Terms, please contact us at
              [Your Contact Information]
            </p>

            <h5>Important Points to Include later</h5>
            <ul>
              <li>Your use of or inability to use the platform.</li>
              <li>
                Any conduct or content of any third party on the platform.
              </li>
              <li>Your use of or inability to use the platform.</li>
              <li>
                Any conduct or content of any third party on the platform.
              </li>
            </ul>
          </div>

            </div>

          </div>
        </div>
      </div>
    </>
  </motion.div>
  )
}

export default SupplierTermCondition