import React from 'react';
import { NavLink } from "react-router-dom";
import { images } from "../../../actions/customFn";

const SupplyInvoiceList = () => {

    const firsttb_tabledata = [
        { id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Paid" },
        {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay',status: 'Overdue'},
        {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Paid" },
        {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Paid" },
        {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Unpaid" },
        {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Overdue" },
        {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Overdue" },
        {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Unpaid" },
        {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Paid" },
        {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Paid" },
        {id:'73749', date: '12.04.24', type:'Oaten Hay', location: 'Any', product: 'Oaten Hay', status: "Overdue" },
       
      ];

  return (
    <div className="requirements_listpagemain">
    <div className="requirements_table">
      <div className="table-responsive ">
        <table class="table table-striped table-borderless">
          <thead>
            <tr>  
              <th scope="col">#Invoice Number</th>
              <th scope="col">Invoice Date</th> 
              <th scope="col">Bill To</th> 
              <th scope="col">Bill From</th> 
              <th scope="col">Amount</th> 
              <th scope="col">Status</th> 
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {firsttb_tabledata && firsttb_tabledata.map((v, i) => {
              return (
                <tr>
                <td scope="row">INV-7374923654</td>
                <td>12.04.24</td> 
                <td>Globex Corporation</td>
                <td>Agrimark</td>
                <td>$1280</td>
                 
                  <td>
                    <div className="show_status">
                      <p className={v.status == 'Paid' ? 'completed_tag': v.status == 'Overdue' ? 'inprogess_tag' : 'open_tag'}>{v.status}</p>
                    </div>
                  </td>
                  <td className="actions_list">
                    <NavLink to={"/seller/invoices-details"}>
                      <img src={images["view_btn.png"]} alt="" />
                    </NavLink>
                     
                    {/* <button>
                      <img src={images["edit_btn.png"]} alt="" />
                    </button> */}
                    <button>
                      <img src={images["download_btn.png"]} alt="" />
                    </button>
                    {/* <button>
                      <img src={images["delete_btn.png"]} alt="" />
                    </button> */}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  </div>
  )
}

export default SupplyInvoiceList