import React from 'react'
import { images } from '../../../actions/customFn';

const BenifitesBusiness = () => {

    const benefitsdata = [
        { icons : images['benefit1.png'], title: 'Enhanced Visibility' },
        { icons : images['benefit2.png'], title: 'Access to a Targeted Audience' },
        { icons : images['globe-icon.png'], title: 'Networking Opportunities' },
        { icons : images['mesg-icon.png'], title: 'Streamlined Communication' },
    ]

  return (
    <>
        <div className='about_card_sec business_benifitsmain'>

<h4>Benefits of listing your business on SupplyArabia</h4>

            <div className='container'>
                <div className='row'>
                    {
                        benefitsdata.map((v,i)=>{
                            return(
                                <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12'>
                                <div className='card_box'>
                                    <img src={v.icons} alt="" />
                                    <h5 className='box_title'>{v.title}</h5>
                                    <p className='box_content'>Increase your company's visibility within the SupplyArabia community, reaching potential clients actively seeking your services.</p>
                                </div>
                            </div>
                            )
                        })
                    }
                   
                  
                </div>
            </div>
        </div>
    </>
  )
}

export default BenifitesBusiness;