import React from 'react';
import {Component} from 'react';
function Banner(props) {
    const bg = props.bg;
  return (
    <>
        <div className='banner_section' style={{backgroundImage:"url(" + bg + ")"}}>
            <div className='container'>
                <h2 className='page_title'>{props.title}</h2>
              {props.paragraph && <p>{props.paragraph}</p> }
            </div>
        </div>
    </>
  )
}

export default Banner;