import React from 'react';
import { AvailabilityHeader, DashboardHeader, DashboardSidebar, FirmBidInformation } from '../../components/Front';
import { motion } from "framer-motion";
import { NavLink } from 'react-router-dom';
import { images } from '../../actions/customFn';

const BuyerMyBids = () => {

    const firsttab_tabledata = [
        { status: "Completed" },
        {status: 'Inprogress'},
        { status: "Completed" },
        { status: "Completed" },
        { status: "Open" },
        { status: "Inprogress" },
        { status: "Inprogress" },
        { status: "Open" },
        { status: "Completed" },
        { status: "Completed" },
        { status: "Inprogress" },
       
      ];

  return (
    <motion.div
    initial={{ opacity: 0.2 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 1 }}
    transition={{ duration: 2 }}
  >
    <>
    <div className="dashboard-layout">
      <div className="layout_left_side">
        <DashboardSidebar />
      </div>

      <div className="layout-right-sider">
        <div className="">
          <DashboardHeader headertopic="My Bids" />

          <div className="dashborader_datacontentmain">

          <div className="requirements_listpagemain">
      <div className="requirements_table">
        <div className="table-responsive ">
          <table class="table table-striped table-borderless">
            <thead>
              <tr>
                <th scope="col">ID number</th>
                <th scope="col">Date Listed</th>
                <th scope="col">Supplier</th>
                <th scope="col">Product</th>
                <th scope="col">Origin</th>
                <th scope="col">Grade</th>
                <th scope="col">Specification</th>
                <th scope="col">Quantity</th>
                <th scope="col">No. of shipments</th>
                <th scope="col">Shipment period</th>
                <th scope="col">Price</th>
                <th scope="col">Payment terms</th>
                <th scope="col">Status</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {firsttab_tabledata.map((v, i) => {
                return (
                  
                  <tr className="linking_row"  >
                    <td scope="row">73749</td>
                    <td>12.04.24</td>
                    <td>Trent B. Brooks </td>
                    <td>Oaten Hay</td>
                    <td>Any</td>
                    <td>Premium</td>
                    <td>Specific Detail</td>
                    <td>200 mts</td>
                    <td>1</td>
                    <td>12.04.24</td>
                    <td>$400/mt</td>
                    <td>Any</td>
                     
                    <td> 
                      <div className="show_status">
                        <p className={v.status == 'Completed' ? 'completed_tag': v.status == 'Inprogress' ? 'inprogess_tag' : 'open_tag'}>{v.status}</p>
                      </div>{" "}
                    </td>
                    <td className="actions_list">
                      <NavLink to={"/buyer/bid-details"}>
                        {" "}
                        <img src={images["view_btn.png"]} alt="" />{" "}
                      </NavLink>
                      <NavLink to={""}>
                        {" "}
                        <img src={images["edit_btn.png"]} alt="" />{" "}
                      </NavLink>
                      <button>
                        <img src={images["download_btn.png"]} alt="" />
                      </button>
                      <button>
                        <img src={images["delete_btn.png"]} alt="" />
                      </button>
                    </td>
                  </tr>
                  
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
            
          </div>
        </div>
      </div>
    </div>
  </>
  </motion.div>
  )
}

export default BuyerMyBids