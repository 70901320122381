import React, { useState } from "react";
import {
  ChangePassword,
  DashboardHeader,
  DashboardSidebar,
  MyReviewsRating,
  ProfileDetailInfo,
  ProfileEdit,
} from "../../components/Front";
import { images } from "../../actions/customFn";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { motion } from "framer-motion";

const ProfileDashboard = () => {
  const [tabvalue, setTabValue] = useState(10);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <>
        <div className="dashboard-layout">
          <div className="layout_left_side">
            <DashboardSidebar />
          </div>

          <div className="layout-right-sider">
            <div className="">
              <DashboardHeader headertopic="My profile" />

              <div className="dashborader_datacontentmain">
                <div className="myprofile_dashboardmain">
                  <div className="row">
                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-12">
                      <div className="tabmyprofile_leftsection">
                        <div>
                          <div className="person_section">
                            <img src={images["profile_1img.png"]} alt="" />
                            <h5>Clifford M. Pereira</h5>
                            <p>cliffordm.pereira@gmail.com</p>
                          </div>

                          <div className="profiledashboard_tab">
                            <Box
                              sx={{
                                maxWidth: {},
                                // sm: 780
                                bgcolor: "background.paper",
                              }}
                            >
                              <Tabs
                                value={tabvalue}
                                onChange={handleChangeTab}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                              >
                                <Tab
                                  icon={
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <g clipPath="url(#clip0_628_745)">
                                        <path
                                          d="M18.7424 21H5.25562C4.63513 21 4.10537 20.7801 3.66635 20.3402C3.22732 19.9003 3.00781 19.3695 3.00781 18.7478V5.2346C3.00781 4.6129 3.22732 4.08504 3.66635 3.65103C4.10537 3.21701 4.63513 3 5.25562 3H15.3707L13.1229 5.2346H6.37952C6.06342 5.2346 5.79708 5.34604 5.5805 5.56891C5.36391 5.79179 5.25562 6.05572 5.25562 6.3607V17.6217C5.25562 17.9384 5.36684 18.2053 5.58928 18.4223C5.81171 18.6393 6.07513 18.7478 6.37952 18.7478H17.6185C17.9346 18.7478 18.201 18.6393 18.4176 18.4223C18.6342 18.2053 18.7424 17.9384 18.7424 17.6217V10.8651L20.9903 8.6129V18.7478C20.9903 19.3695 20.7707 19.9003 20.3317 20.3402C19.8927 20.7801 19.3629 21 18.7424 21ZM8.62732 12.8358L11.1561 15.3695L7.50342 16.4956L8.62732 12.8358ZM12.28 14.5249L9.47025 11.7273L17.882 3.28152C18.081 3.09384 18.3181 3 18.5932 3C18.8683 3 19.1054 3.09384 19.3044 3.28152L20.6917 4.68915C20.879 4.87683 20.9756 5.1085 20.9815 5.38416C20.9873 5.65982 20.8907 5.89736 20.6917 6.09677L12.28 14.5249Z"
                                          fill="#EF8B45"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_628_745">
                                          <rect
                                            width="18"
                                            height="18"
                                            fill="white"
                                            transform="translate(3 3)"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  }
                                  label="Edit profile"
                                />
                                <Tab
                                  icon={
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        d="M4.05 22.5H19.95C20.5266 22.5 21 22.0359 21 21.4641V11.5312C21 10.9594 20.5312 10.5 19.95 10.5H18.9375V9.05156C18.9375 7.10625 18.1781 4.94531 16.9406 3.64219C15.7078 2.33906 13.9406 1.5 12.0047 1.5H12H11.9953C10.0594 1.5 8.29219 2.33906 7.05938 3.6375C5.82188 4.94062 5.0625 7.10156 5.0625 9.04688V10.5H4.05C3.46875 10.5 3 10.9641 3 11.5312V21.4641C3 22.0359 3.46875 22.5 4.05 22.5ZM7.54688 9.05156C7.54688 7.77188 8.01094 6.1875 8.86406 5.2875V5.27344C9.68906 4.40156 10.8281 3.89062 11.9953 3.89062H12.0047C13.1719 3.89062 14.3109 4.40156 15.1359 5.27344V5.28281L15.1312 5.2875C15.9891 6.1875 16.4484 7.77188 16.4484 9.05156V10.5H7.54688V9.05156Z"
                                        fill="black"
                                      />
                                    </svg>
                                  }
                                  label="Change Password"
                                />
                                <Tab
                                  icon={
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <g clip-path="url(#clip0_3012_1510)">
                                        <path
                                          d="M7.21646 19.0854C6.73632 19.0854 6.28387 19.0948 5.83142 19.0854C5.31434 19.0667 4.78803 19.1041 4.28941 18.992C2.92284 18.6931 2.01794 17.5161 2.00871 16.0775C1.99948 13.3124 1.99024 10.5567 2.00871 7.79163C2.02718 6.05413 3.32912 4.78369 5.04657 4.78369C7.92745 4.78369 10.8083 4.78369 13.6892 4.78369C13.7631 4.78369 13.8462 4.78369 13.9385 4.78369C13.9478 5.2601 14.1971 5.60574 14.4833 5.92335C14.668 6.12886 14.825 6.36239 15.0189 6.55856C15.2035 6.73605 15.222 6.93222 15.1943 7.1751C15.1574 7.56744 15.1204 7.95978 15.1204 8.35212C15.102 9.43572 16.1084 10.1457 17.1149 9.76267C17.5212 9.61321 17.909 9.43572 18.3153 9.27692C18.4076 9.23955 18.5369 9.23955 18.6384 9.27692C18.9986 9.4077 19.3494 9.55716 19.7003 9.70662C19.7649 9.73465 19.8388 9.83741 19.8388 9.91214C19.848 12.014 19.8573 14.1251 19.8388 16.2269C19.8203 17.7309 18.5738 19.0294 17.0964 19.0761C15.7853 19.1228 14.4741 19.0854 13.1629 19.1041C13.0244 19.1041 12.8674 19.1602 12.7566 19.2442C11.6671 20.0663 10.5867 20.8977 9.48793 21.7104C8.61997 22.3549 7.40114 21.8878 7.21646 20.8229C7.1426 20.3839 7.20723 19.9261 7.20723 19.4684C7.198 19.3563 7.20723 19.2349 7.21646 19.0854ZM11.0576 9.71597C9.93115 9.71597 8.81388 9.71597 7.68738 9.71597C7.29957 9.71597 7.04103 9.95884 7.01333 10.3138C6.99486 10.6968 7.27187 10.9677 7.70585 10.9771C8.90622 10.9771 10.1066 10.9771 11.3162 10.9771C12.3319 10.9771 13.3476 10.9771 14.3633 10.9771C14.6311 10.9771 14.8619 10.9117 15.0096 10.6595C15.2589 10.2204 14.9542 9.72531 14.4279 9.72531C13.3106 9.71597 12.1841 9.71597 11.0576 9.71597ZM9.43253 14.1438C10.0235 14.1438 10.6144 14.1438 11.2054 14.1438C11.6024 14.1438 11.8887 13.8729 11.8887 13.5179C11.8794 13.163 11.6024 12.9107 11.2146 12.9107C10.0419 12.9107 8.86928 12.9107 7.69661 12.9107C7.29033 12.9107 7.01333 13.1723 7.02256 13.5366C7.02256 13.8916 7.29033 14.1438 7.68738 14.1438C8.2691 14.1438 8.85081 14.1438 9.43253 14.1438Z"
                                          fill="#F9D1B5"
                                        />
                                        <path
                                          d="M16.1363 8.51109C16.1825 7.76377 16.2287 7.1846 16.2564 6.60543C16.2564 6.51202 16.2194 6.40926 16.164 6.33453C15.8224 5.90483 15.4623 5.48446 15.1114 5.05476C14.8344 4.70912 14.9359 4.38217 15.3699 4.27008C15.8962 4.1393 16.4318 4.00852 16.9581 3.8684C17.0504 3.84037 17.152 3.76564 17.1982 3.69091C17.4844 3.24252 17.7614 2.79413 18.0384 2.34574C18.3155 1.88801 18.6386 1.89735 18.9249 2.35508C19.2019 2.80347 19.4697 3.24252 19.7559 3.68157C19.8113 3.76564 19.9221 3.84037 20.0144 3.8684C20.5315 4.00852 21.0578 4.1393 21.5749 4.27008C22.0089 4.38217 22.1197 4.71847 21.8335 5.0641C21.4918 5.48446 21.1317 5.89549 20.7993 6.32519C20.7347 6.39992 20.6977 6.52136 20.6977 6.61478C20.7254 7.14724 20.7624 7.6797 20.8085 8.20282C20.8547 8.70726 20.5777 8.93145 20.116 8.74462C19.6359 8.54845 19.1649 8.34294 18.6756 8.15611C18.5648 8.1094 18.4078 8.1094 18.2877 8.15611C17.7799 8.35228 17.2813 8.56713 16.7734 8.77265C16.4226 8.91277 16.1271 8.69791 16.1363 8.51109Z"
                                          fill="#F9D1B5"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_3012_1510">
                                          <rect
                                            width="20"
                                            height="20"
                                            fill="white"
                                            transform="translate(2 2)"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  }
                                  label="My Reviews/Rating "
                                />
                              </Tabs>
                            </Box>
                          </div>
                        </div>
                        <div className="logout_section">
                          <button>
                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M2 12L7 16V13H16V11H7V8L2 12Z"
                                fill="black"
                              />
                              <path
                                d="M13.0007 2.99906C11.8182 2.99578 10.6469 3.22717 9.55447 3.67982C8.46206 4.13247 7.47032 4.79739 6.63672 5.63606L8.05072 7.05006C9.37272 5.72806 11.1307 4.99906 13.0007 4.99906C14.8707 4.99906 16.6287 5.72806 17.9507 7.05006C19.2727 8.37206 20.0017 10.1301 20.0017 12.0001C20.0017 13.8701 19.2727 15.6281 17.9507 16.9501C16.6287 18.2721 14.8707 19.0011 13.0007 19.0011C11.1307 19.0011 9.37272 18.2721 8.05072 16.9501L6.63672 18.3641C8.33572 20.0641 10.5957 21.0011 13.0007 21.0011C15.4057 21.0011 17.6657 20.0641 19.3647 18.3641C21.0647 16.6651 22.0017 14.4051 22.0017 12.0001C22.0017 9.59506 21.0647 7.33506 19.3647 5.63606C18.5311 4.79739 17.5394 4.13247 16.447 3.67982C15.3546 3.22717 14.1832 2.99578 13.0007 2.99906Z"
                                fill="black"
                              />
                            </svg>{" "}
                            Logout
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12 col-12">
                      <div className="">
                        <div className="tab_showscroll" id="scrollableDiv">
                         
                          {tabvalue === 0 && <ProfileEdit />}

                          {tabvalue === 1 && <ChangePassword />}
                          {tabvalue === 2 && <MyReviewsRating />}

                          {tabvalue === 10 && <ProfileDetailInfo />}

                        </div>
                         
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </>
    </motion.div>
  );
};

export default ProfileDashboard;
