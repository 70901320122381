import React from "react";
import {
  AvailabilityHeader,
  AvailabilitySidebar,
  PostAvailability,
} from "../../components/Front";
import { motion } from "framer-motion";

const PostAvailabilityForm = () => {
  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <>
        <div className="dashboard-layout">
          <div className="layout_left_side">
            <AvailabilitySidebar />
          </div>

          <div className="layout-right-sider">
            <div className="">
              <AvailabilityHeader headertopic="Add Availability" />

              <div className="dashborader_datacontentmain">
                <PostAvailability />
              </div>
            </div>
          </div>
        </div>
      </>
    </motion.div>
  );
};

export default PostAvailabilityForm;
