import React from 'react';
import { images } from "../../actions/customFn";

import {
    Banner,
    ServicesOperations,
    ChatNow,
  } from "../../components/Front";
  import { motion } from "framer-motion";

function Services() {
  return (
    <motion.div
    initial={{ opacity: 0.2 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 1 }}
    transition={{ duration: 2 }}
  >
    <>
        <Banner title="Services" bg={images["service-banner.png"]}/>
        <ServicesOperations/>
        <ChatNow/>
    </>
    </motion.div>
  )
}

export default Services; 