import React from 'react'; 
import { NavLink } from "react-router-dom";
import { images } from "../../../actions/customFn";

const InvoicesList = () => {

    const tracking_maintitlles = [
        {title: 'Invoice Number'},
        {title: 'Invoice Date'},
        {title: 'Due Date'},
        {title: 'Qty'},
        {title: 'Bill To'},
        {title: 'Bill From'},
        {title: 'Subtotal'},
        {title: 'Tax'},
        // {title: 'Amount'},
        {title: 'Discount'},
        {title: 'Total Amount Due'},
        
        {title: 'Status'},
        {title: 'Action'},
       ];
   
       const firsttb_tabledata = [
         { id:'INV-7374923654', date: '12.04.24', duedate: 'name', type:'Oaten Hay', location: 'Any', product: '$100/mt', status: "Paid" },
         {id:'73749', date: '12.04.24',name: 'name',  type:'Oaten Hay', location: 'Any', product: '$100/mt',status: 'Unpaid'},
         {id:'73749', date: '12.04.24',name: 'name',  type:'Oaten Hay', location: 'Any', product: '$100/mt ', status: "Paid" },
         {id:'73749', date: '12.04.24',name: 'name',  type:'Oaten Hay', location: 'Any', product: '$100/mt ', status: "Paid" },
         {id:'73749', date: '12.04.24',name: 'name',  type:'Oaten Hay', location: 'Any', product: '$100/mt ', status: "Overdue" },
         {id:'73749', date: '12.04.24', name: 'name', type:'Oaten Hay', location: 'Any', product: '$100/mt ', status: "Unpaid" },
         {id:'73749', date: '12.04.24',name: 'name',  type:'Oaten Hay', location: 'Any', product: '$100/mt ', status: "Unpaid" },
         {id:'73749', date: '12.04.24',name: 'name',  type:'Oaten Hay', location: 'Any', product: '$100/mt ', status: "Overdue" },
         {id:'73749', date: '12.04.24',name: 'name',  type:'Oaten Hay', location: 'Any', product: '$100/mt ', status: "Paid" }, 
        
       ];

  return (
    <div className="requirements_listpagemain">
    <div className="requirements_table">
      <div className="table-responsive ">
        <table class="table table-striped table-borderless">
          <thead>
            <tr>
              {
                tracking_maintitlles.map((v,i)=>{
                  return(
                    <th scope="col">{v.title}</th>
                  )
                })
              }
              {/* <th scope="col">#Shipment Id</th>
              <th scope="col">Order Date</th>
              <th scope="col">Quantity</th>
              <th scope="col">Origin</th>
              <th scope="col">Status</th> 
              <th scope="col">Action</th> */}
            </tr>
          </thead>
          <tbody>
            {firsttb_tabledata && firsttb_tabledata.map((v, i) => {
              return (
                <tr>
                 <td scope="row">INV-7374923654</td> 
                 <td>12.04.24</td>
                 <td>12.04.24</td>
                 <td>200mts</td>
                 <td>Globex Corporation</td>
                 <td>Agrimark</td>
                 <td>$1280</td>
                 <td>$500</td>
                 <td>$2</td>
                 <td>$1778</td>
                
                  <td>
                    <div className="show_status">
                      <p className={v.status == 'Paid' ? 'completed_tag': v.status == 'Unpaid' ? 'inprogess_tag' : 'open_tag'}>{v.status}</p>
                    </div>
                  </td>
                  <td className="actions_list">
                    <NavLink to={"/buyer/invoices-details"}>
                      <img src={images["view_btn.png"]} alt="" />
                    </NavLink>
                     
                    <button>
                      <img src={images["edit_btn.png"]} alt="" />
                    </button>
                    <button>
                      <img src={images["download_btn.png"]} alt="" />
                    </button>
                    <button>
                      <img src={images["delete_btn.png"]} alt="" />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  </div>
  )
}

export default InvoicesList;