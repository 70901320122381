import React from 'react'
import {  Banner } from '../../components/Front';
import { images } from '../../actions/customFn';
import { motion } from "framer-motion";

const UsersSupplier = () => {
  return (
    <motion.div
    initial={{ opacity: 0.2 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 1 }}
    transition={{ duration: 2 }}
  >
    <>
      <Banner title="Welcome, Suppliers!" bg={images["user-supplyimg.png"]} paragraph={"Are you a manufacturer, grower, processor, exporter, trader, or a supplier's agent ready to sell raw materials into the vibrant iddle Eastern market? Look no further than SupplyArabia – your ultimate marketplace destination."} />
       
<div className='aboutsupplier_mainsection'>
    <div className="container">


      <div className='suplierabout_cards1'>
              <div className='row'>
              <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pt-sm-4'>
                      <h5>Empower Your Business, Expand Your Reach</h5>
                      <p>Joining SupplyArabia as a supplier opens doors to unparalleled opportunities. Signing up for an account is not just easy, it's completely free. Simply create a profile and submit an application to become a seller. Whether you prefer to market your company and products openly, remain anonymous, or adjust your privacy settings, you're in full control of your presence on the platform.</p>
                  </div>
                  <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                      <img src={images["supply_about1.png"]} alt="" />
                  </div>
                 
              </div>
            </div>

            <div className='suplierabout_cards1'>
              <div className='row'>
                
              <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                      <img src={images["supply_about2.png"]} alt="" />
                  </div>
              <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pt-sm-4'>
                      <h5>Seamless Connections, Instant Communication</h5>
                      <p>Joining SupplyArabia as a supplier opens doors to unparalleled opportunities. Signing up for an account is not just easy, it's completely free. Simply create a profile and submit an application to become a seller. Whether you prefer to market your company and products openly, remain anonymous, or adjust your privacy settings, you're in full control of your presence on the platform.</p>
                  </div>
                  
                 
              </div>
            </div>

            <div className='suplierabout_cards1'>
              <div className='row'>
              <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pt-sm-4'>
                      <h5>Drive Demand, Maximize Profit</h5>
                      <p>Joining SupplyArabia as a supplier opens doors to unparalleled opportunities. Signing up for an account is not just easy, it's completely free. Simply create a profile and submit an application to become a seller. Whether you prefer to market your company and products openly, remain anonymous, or adjust your privacy settings, you're in full control of your presence on the platform.</p>
                  </div>
                  <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                      <img src={images["supply_about3.png"]} alt="" />
                  </div>
                 
              </div>
            </div>

            
              <div className='suplierabout_cards1'>
              <div className='row'>
              <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pt-sm-4'>
                <div className='supplier_endcards'> 
              <img src={images["supply_about4.png"]} alt="" />
                      <h5>Secure Transactions, Peace of Mind</h5>
                      <p>Utilize SupplyArabia's suite of services to streamline your business dealings on the platform. From Credit Insurance to safeguard your investments to other assistance tailored to your needs, we're here to help mitigate potential issues and ensure your trading experience is smooth and secure.</p>
                  </div>

              </div>     
              <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pt-sm-4'>
                <div className='supplier_endcards'> 
              <img src={images["supply_about5.png"]} alt="" />
                      <h5>Unlock Your Business's Full Potential</h5>
                      <p>Utilize SupplyArabia's suite of services to streamline your business dealings on the platform. From Credit Insurance to safeguard your investments to other assistance tailored to your needs, we're here to help mitigate potential issues and ensure your trading experience is smooth and secure.</p>
                  </div>

              </div>     
                 
              </div>
            </div>

            </div>
            </div>

     

        {/* <AboutCard/> */}
        {/* <Join/> */}
    </>
    </motion.div>
  )
}

export default UsersSupplier;