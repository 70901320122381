import React from "react";
import { images } from "../../actions/customFn";
import {
  AboutCard,
  Banner,
  BenifitesBusiness,
  Join,
} from "../../components/Front";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";

const UserBusinessServices = () => {
  return (
    <motion.div
    initial={{ opacity: 0.2 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 1 }}
    transition={{ duration: 2 }}
  >
    <div className="userbusiness_mainpage">
      <Banner
        title="Welcome, Business Services"
        bg={images["user-bussinessbg.png"]}
        paragraph={
          "Are you an importer, distributor, trader, or end-user based in the dynamic Middle East or an Arabic-speaking country in search of top-quality raw materials from across the globe? Look no further –SupplyArabia is your ultimate marketplace destination."
        }
      />

      <div className="aboutbusiness_mainsection">
        <div className="container">
          <div className="businessabout_cards1">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <img src={images["buyer_about1.png"]} alt="" />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pt-sm-4">
                <h5>Welcome to SupplyArabia Business Services!</h5>
                <p>
                  Are you a freight forwarding, clearing agent, maritime lawyer,
                  or any other business service provider crucial to commodities
                  trading? If so, we invite you to list your profile on our
                  directory and make your services readily available to the
                  SupplyArabia community.
                </p>
                <p>
                  Joining our platform provides your company with exposure to a
                  large and diverse community of users actively engaged in trade
                  and commerce. By showcasing your expertise and offerings on
                  SupplyArabia, you not only expand your reach but also become
                  an integral part of a thriving ecosystem dedicated to
                  facilitating seamless transactions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BenifitesBusiness />

      <div className="business_joinsection">
        <div className="container">
          <div className="join_row">
            <h6>
              Join Now and leverage the power of SupplyArabia to grow your
              business and become an indispensable resource for the global
              trading community. We look forward to welcoming you to our
              platform and helping you maximize your business potential.
            </h6>
            <NavLink to="#" className="join_btn">
              Join Now
            </NavLink>
          </div>
        </div>
      </div>
      
    </div>
     </motion.div>
  );
};

export default UserBusinessServices;
