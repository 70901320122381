import React from 'react';
import { images } from "../../../actions/customFn";

const ContractData = () => {

    const bidlist = [
        { title: "Contract ID", point: "#12542" },
        { title: "Grade", point: "Premium" },
        { title: "Delivery Port", point: "Jebel Ali" },
        { title: "Origin", point: "USA" },
        { title: "Delivery Port", point: "Jebel Ali" },
      ];

      const order_history = [{}, {}, {}, {}];

      const compareContract = [
        { title: "Company name", point: "Globex Corporation" },
        { title: "Address", point: "Lorem Ipsum is simply dummy text of the printing." },
      ]

  return (
    <div className="contract_detaildashpage">  
    <div className="orderdetail_mainsection">
    
    <div className="orderdetail_toppart">
      <div className="left_orderdetail">
        <div>  
        <h6>Contract For Order :</h6>
        <img className="p_img" src={images["latest_blog1.png"]} alt="" />

        </div>
        <div className="datalist_flex">
          <h6 className='contract_orderid'>Order #12542</h6>
          {/* <div className="horizont_divide"></div> */}
          {/* <div className="date_flex">
            <img src={images["gray_date.png"]} alt="" />
            <h6>26/07/2024</h6>
          </div> */}
          {/* <div className="horizont_divide"></div> */}
          {/* <div className="processing_tag">Processing</div> */}
        </div>
      </div>

      <div className="right_orderbtns">
        <button className="transparent_btnmain">Send Confirmation</button>
        <button className="button_darkmain">Request Support</button>
      </div>
    </div>

    <div className="bidlist_detailmainsection quatation_detaillistmain">
      <div className="row">
        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
          <div className="bidlist_uniquelists">
            {bidlist.map((v, i) => {
              return (
                <div className="build_list1">
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                      {" "}
                      <p>{v.title}</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                      <h6>{v.point}</h6>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
          <div className="bidlist_uniquelists">
            {bidlist.map((v, i) => {
              return (
                <div className="build_list1">
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                      {" "}
                      <p>{v.title}</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                      <h6>{v.point}</h6>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
          <div className="bidlist_uniquelists">
            <div className="build_list1">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  {" "}
                  <p>Payment Terms</p>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <h6>CAD</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>

  <div className="orderdetail_mainsection">
    
    <div className="orderdetail_toppart">
      <div className="left_orderdetail">
    
        <div className="datalist_flex">
          <h6>Contract id  #12542</h6>
          <div className="horizont_divide"></div>
          <div className="date_flex">
            <h6><span>Contract Date:</span></h6>
            <img src={images["gray_date.png"]} alt="" />
            <h6>26/07/2024</h6>
          </div>
          <div className="horizont_divide"></div>
          <div className="date_flex">
            <h6><span>Contract Time:</span></h6>
            <img src={images["black_clock.png"]} alt="" />
            <h6>03:15 PM</h6>
          </div>
          <div className="horizont_divide"></div>
          <div className="pending_tag">Pending</div>
        </div>
      </div>

    
    </div>

    <div className="bidlist_detailmainsection quatation_detaillistmain">
      <div className="row">
        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
          <div className="bidlist_uniquelists">
            {bidlist.map((v, i) => {
              return (
                <div className="build_list1">
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                      {" "}
                      <p>{v.title}</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                      <h6>{v.point}</h6>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

 
      </div>

    </div>

  </div>

  <div className="orderdetail_mainsection">
  <div className="bidlist_detailmainsection quatation_detaillistmain">
   <h6 className='contact_title'>Contract  Between</h6>
      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="bidlist_uniquelists comparecontract_leftlist">
            {compareContract.map((v, i) => {
              return (
                <div className="build_list1">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
                      {" "}
                      <p>{v.title}</p>
                    </div>
                    <div className="col-xl-7 col-lg-7 col-md-6 col-sm-6 col-6">
                      <h6>{v.point}</h6>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="bidlist_uniquelists">
            {compareContract.map((v, i) => {
              return (
                <div className="build_list1">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
                      {" "}
                      <p>{v.title}</p>
                    </div>
                    <div className="col-xl-7 col-lg-7 col-md-6 col-sm-6 col-6">
                      <h6>{v.point}</h6>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
 
      </div>

    </div>
    

  </div>

  </div>
  )
}

export default ContractData