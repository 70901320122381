import React from "react";
import {
  DashboardHeader,
  DashboardSidebar,
  RequirementsLists,
} from "../../components/Front";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";

const RequestFinanceSupport = () => {
  return (
    <motion.div
    initial={{ opacity: 0.2 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 1 }}
    transition={{ duration: 2 }}
  >
    <>
      <div className="dashboard-layout">
        <div className="layout_left_side">
          <DashboardSidebar />
        </div>

        <div className="layout-right-sider">
          <div className="">
            <DashboardHeader headertopic="Request Finance Support" />

            <div className="dashborader_datacontentmain">
              <div className="addnewtracking_section">
                <div className="addrequirement_mainsection">
                  <div className="require_flexinput">
                    <div className="input_allmain">
                      <label> Deal details</label>
                      <input type="text" placeholder="Enter details" />
                    </div>
                    <div className="input_allmain">
                      <label>Product/Service</label>
                      <input
                        type="text"
                        placeholder="Enter product/service"
                      />
                    </div>
                  </div>

                  <div className="require_flexinput">
                    <div className="input_allmain">
                      <label>Quantity</label>
                      <input type="text" placeholder="Enter Quantity" />
                    </div>
                    <div className="input_allmain">
                      <label>Total value</label>
                      <input type="text" placeholder="Enter value" />
                    </div>
                  </div>

                  <div className="require_flexinput">
                    <div className="input_allmain">
                      <label>Delivery Port</label>
                      <input type="text" placeholder="Enter details" />
                    </div>
                    <div className="input_allmain">
                      <label>Incoterms</label>
                      <input type="text" placeholder="Enter incoterms" />
                    </div>
                  </div>

                  <div className="require_flexinput">
                    <div className="input_allmain">
                      <label>Payment Terms from the supplier.</label>
                      <input type="text" placeholder="Enter details" />
                    </div>
                    <div className="input_allmain">
                      <label>Expected Delivery Date</label>
                      <input type="text" placeholder="Enter incoterms" />
                    </div>
                  </div>

                  <div className="input_allmain">
                    <label>Additional information</label>
                    <textarea type="text" rows={5} placeholder="Enter text" />
                  </div>
                </div>

                <div className="add_trackbutton">
                  <button className="button_darkmain">Add</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  </motion.div>
  )
}

export default RequestFinanceSupport