import React, { useState } from 'react';
import { images } from "../../actions/customFn";
import { motion } from "framer-motion";

import {
    Banner,
    Welcome,
    AboutCard,
    Join,
    RotatingLoader,
  } from "../../components/Front";

function AboutUs() {
 

  return (
    <motion.div
    initial={{ opacity: 0.2 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 1 }}
    transition={{ duration: 2 }}
  >
    <>
        <Banner title="About us" bg={images["about-us-banner.png"]}/>
        <Welcome/>
        <AboutCard/>
        <Join/>
    </>
   
    </motion.div>
  )
}

export default AboutUs; 