// import { Dashboard } from "@mui/icons-material";
import {
 
  RequirementsDashboard,
 
  AddRequirementForm,
 
  QuatationListDashboard,
  ProfileDashboard,
  QuatationDetailDashboard,
  BuyerMyOrders,
  MyOrderDetails,
  TrackingDashboard,
  AddNewTracking,
  TrackingDetailPage,
  InspectionDashboard,
  BuyerDashboard,
  RequirementDetails,
  FirmQuoteDashboard,
  BuyerMyBids,
  BuyerBidDetail,
  InspectionDetail,
  AddNewInspection,
  FinanceSupportPage,
  RequestFinanceSupport,
  InvoicesDashboard,
  InvoiceDetails,
  TermConditionDashoard,
  FinanceSupportDetail,
} from "../pages/Front"; 

const DashboardRouteArr = [
  {
    name: "BuyerDashboard",
    path: "/buyer/dashboard",
    key: "/buyer-dashboard",
    route: "/buyer-dashboard",
    element: <BuyerDashboard />,
  },
  {
    name: "RequirementsDashboard",
    path: "/buyer/requirements",
    key: "/requirements",
    route: "/requirements",
    element: <RequirementsDashboard />,
  },
  {
    name: "AddRequirementForm",
    path: "/buyer/add-requirement",
    key: "/add-requirement",
    route: "/add-requirement",
    element: <AddRequirementForm />,
  },
  {
    name: "RequirementDetails",
    path: "/buyer/requirement-detail",
    key: "/requirement-detail",
    route: "/requirement-detail",
    element: <RequirementDetails />,
  },
  {
    name: "FirmQuoteDashboard",
    path: "/buyer/firm-quote",
    key: "/firm-quote",
    route: "/firm-quote",
    element: <FirmQuoteDashboard />,
  },
 
 
  {
    name: "QuatationListDashboard",
    path: "/buyer/quatation-list",
    key: "/quatation-list",
    route: "/quatation-list",
    element: <QuatationListDashboard />,
  },
  {
    name: "QuatationDetailDashboard",
    path: "/buyer/quatation-detail",
    key: "/quatation-detail",
    route: "/quatation-detail",
    element: <QuatationDetailDashboard />,
  },
  {
    name: "BuyerMyBids",
    path: "/buyer/my-bids",
    key: "/my-bids",
    route: "/my-bids",
    element: <BuyerMyBids />,
  },
  {
    name: "BuyerBidDetail",
    path: "/buyer/bid-details",
    key: "/bid-details",
    route: "/bid-details",
    element: <BuyerBidDetail />,
  },

  {
    name: "ProfileDashboard",
    path: "/buyer/my-profile",
    key: "/my-profile",
    route: "/my-profile",
    element: <ProfileDashboard />,
  },
  {
    name: "BuyerMyOrders",
    path: "/buyer/my-orders",
    key: "/my-orders",
    route: "/my-orders",
    element: <BuyerMyOrders />,
  },
  {
    name: "MyOrderDetails",
    path: "/buyer/myorder-details",
    key: "/myorder-details",
    route: "/myorder-details",
    element: <MyOrderDetails />,
  },
  {
    name: "TrackingDashboard",
    path: "/buyer/tracking",
    key: "/tracking",
    route: "/tracking",
    element: <TrackingDashboard />,
  },
  {
    name: "AddNewTracking",
    path: "/buyer/addnew-tracking",
    key: "/addnew-tracking",
    route: "/addnew-tracking",
    element: <AddNewTracking />,
  },
  {
    name: "TrackingDetailPage",
    path: "/buyer/tracking-detail",
    key: "/tracking-detail",
    route: "/tracking-detail",
    element: <TrackingDetailPage />,
  },
  {
    name: "InspectionDashboard",
    path: "/buyer/inspection",
    key: "/inspection",
    route: "/inspection",
    element: <InspectionDashboard />,
  },
  {
    name: "InspectionDetail",
    path: "/buyer/inspection-detail",
    key: "/inspection-detail",
    route: "/inspection-detail",
    element: <InspectionDetail />,
  },
  {
    name: "AddNewInspection",
    path: "/buyer/addnew-inspection",
    key: "/addnew-inspection",
    route: "/addnew-inspection",
    element: <AddNewInspection />,
  },
  {
    name: "FinanceSupportPage",
    path: "/buyer/finance-support",
    key: "/finance-support",
    route: "/finance-support",
    element: <FinanceSupportPage />,
  },
  {
    name: "RequestFinanceSupport",
    path: "/buyer/request-support",
    key: "/request-support",
    route: "/request-support",
    element: <RequestFinanceSupport />,
  },
  {
    name: "FinanceSupportDetail",
    path: "/buyer/financesupport-detail",
    key: "/financesupport-detail",
    route: "/financesupport-detail",
    element: <FinanceSupportDetail />,
  },
  {
    name: "InvoicesDashboard",
    path: "/buyer/invoices",
    key: "/invoices",
    route: "/invoices",
    element: <InvoicesDashboard />,
  },
  {
    name: "InvoiceDetails",
    path: "/buyer/invoices-details",
    key: "/invoices-details",
    route: "/invoices-details",
    element: <InvoiceDetails />,
  },
  {
    name: "TermConditionDashoard",
    path: "/buyer/term-condition",
    key: "/term-condition",
    route: "/term-condition",
    element: <TermConditionDashoard />,
  },

  //   {
  //     path: "",
  //     private: true,
  //     element: <></>,
  //     children: [
  //       {
  //         path: "",
  //         element: "Private Route",
  //       },
  //     ],
  //   },
];

export default DashboardRouteArr;
