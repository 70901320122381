import React from 'react'
import { images } from '../../../actions/customFn';
import Modal from "react-bootstrap/Modal";

const BidFormModal = (props) => {

    const { bidmodal, closeBidmodal } = props;
 

  return (
    <div>
    <Modal
      className="login_modalmain"
      show={bidmodal}
      onHide={closeBidmodal}
      centered
    >
      <Modal.Body>
        <div className="closemodal_btn">
          <button onClick={closeBidmodal}>
            <img src={images["cross_btn.png"]} alt="" />
          </button>
        </div>
        <div className="bidform_modalsection">
 
 <h2>BID</h2>

 <div className="form_addbidinputsflex">

 {/* <div className="input_allmain">
                <label>Product</label>
                <input type="text" placeholder="Enter Product" />
              </div>

              <div className="input_allmain">
                <label>Delivery Port</label>
                <input type="text" placeholder="Enter your Delivery Port" />
              </div> */}

              {/* <div className="input_allmain">
                <label>Incoterms</label>
                <input type="text" placeholder="Enter your Incoterms" />
              </div>

              <div className="input_allmain">
                <label>Specification</label>
                <input type="text" placeholder="Enter Specification" />
              </div> */}
              {/* <div className="input_allmain">
                <label>Grade</label>
                <input type="text" placeholder="Enter Grade" />
              </div> */}
              <div className="input_allmain">
                <label>Packing</label>
                <input type="text" placeholder="Enter Packing" />
              </div>
              <div className="input_allmain">
                <label>Desired ETA</label>
                <input type="text" placeholder="Enter Desired ETA" />
              </div>
              <div className="input_allmain">
                <label>Price</label>
                <input type="text" placeholder="Enter Price" />
              </div>
              <div className="input_allmain">
                <label>Quantity</label>
                <input type="text" placeholder="Enter Quantity" />
              </div>

 </div>

 <div className="input_allmain">
                <label>Additional Information</label>
                <textarea type="text" rows={5} placeholder="Enter your message" />
              </div>

              <div className="bidnow_btn">
                <button className='button_darkmain'> 
                     <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
          >
            <g clipPath="url(#clip0_312_7367)">
              <path
                d="M0 16.9999H10.5V18.4999H0V16.9999ZM9 15.4877C9 15.2888 8.92098 15.0981 8.78033 14.9574C8.63968 14.8168 8.44891 14.7377 8.25 14.7377H2.25C2.05109 14.7377 1.86032 14.8168 1.71967 14.9574C1.57902 15.0981 1.5 15.2888 1.5 15.4877V16.2499H9V15.4877ZM10.2049 9.35574C9.85542 9.72818 9.56238 10.1498 9.33506 10.6071L15.5083 16.7804C15.649 16.921 15.8397 17 16.0386 17C16.2374 17 16.4282 16.921 16.5688 16.7804L17.6295 15.7197C17.6991 15.65 17.7544 15.5674 17.7921 15.4764C17.8298 15.3854 17.8492 15.2878 17.8492 15.1893C17.8492 15.0908 17.8298 14.9933 17.7921 14.9023C17.7544 14.8113 17.6991 14.7286 17.6295 14.659L11.4562 8.48743C10.9981 8.71275 10.5763 9.00543 10.2049 9.35574ZM6.49256 5.64343C5.9254 6.16977 5.26886 6.59068 4.55381 6.88636L8.43019 10.7627C8.72852 10.0489 9.14986 9.39304 9.675 8.82493C10.2422 8.29861 10.8987 7.87764 11.6137 7.5818L7.73719 3.70618C7.43875 4.41972 7.01749 5.0754 6.49256 5.64343Z"
                fill="white"
              />
              <path
                d="M9.14275 0.871168L8.08209 1.93183C7.78919 2.22472 7.78919 2.6996 8.08209 2.99249L12.3247 7.23513C12.6176 7.52802 13.0925 7.52802 13.3854 7.23513L14.446 6.17447C14.7389 5.88158 14.7389 5.4067 14.446 5.11381L10.2034 0.871168C9.91051 0.578275 9.43564 0.578275 9.14275 0.871168Z"
                fill="white"
              />
              <path
                d="M2.77849 7.23384L1.71783 8.2945C1.42494 8.58739 1.42494 9.06227 1.71783 9.35516L5.96047 13.5978C6.25336 13.8907 6.72824 13.8907 7.02113 13.5978L8.08179 12.5371C8.37468 12.2442 8.37468 11.7694 8.08179 11.4765L3.83915 7.23384C3.54626 6.94095 3.07138 6.94095 2.77849 7.23384Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_312_7367">
                <rect
                  width="18"
                  height="18"
                  fill="white"
                  transform="translate(0 0.5)"
                />
              </clipPath>
            </defs>
          </svg> Bid Now</button>
              </div>

        </div>
      </Modal.Body>
    </Modal>
  </div>
  )
}

export default BidFormModal