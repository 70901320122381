import React, { useEffect } from "react";
import FrontApp from "./pages/Front/FrontApp";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <FrontApp />
    </div>
  );
}

export default App;
