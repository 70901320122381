import React, { useState } from "react";
import BidFormModal from "../Modals/BidFormModal";

const PriceDetail = () => {

  const [bidmodal,setBidmodal] = useState(false);

  const opanBidmodal = ()=>{
    setBidmodal(true);
  }
  const closeBidmodal=()=>{
    setBidmodal(false);
  }

  return (
    <> 
    <div className="pricedetail_mainsection">
    <h2>
        $ 435 <span>/mt</span>{" "}
      </h2>

      <h5>Premium Alfalfa</h5>
      
      <div className="datalist_flex">
        <p>Unique #</p>
        <h6>73749</h6>
      </div>
      <div className="datalist_flex">
        <p>Unique #</p>
        <h6>73749</h6>
      </div>
      <div className="datalist_flex">
        <p>Unique #</p>
        <h6>73749</h6>
      </div>
      <div className="datalist_flex">
        <p>Unique #</p>
        <h6>73749</h6>
      </div>
      <div className="datalist_flex">
        <p>Unique #</p>
        <h6>73749</h6>
      </div>
      <div className="datalist_flex">
        <p>Unique #</p>
        <h6>73749</h6>
      </div>
      <div className="datalist_flex">
        <p>Unique #</p>
        <h6>73749</h6>
      </div>
      <div className="datalist_flex">
        <p>Specification</p>
        <h6>Lorem Ipsum</h6>
      </div>

      <div className="chatbid_btnsflex">
        <button className="transparent_btnmain">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
          >
            <g clipPath="url(#clip0_211_604)">
              <path
                d="M16.25 15.125V18.5C16.168 18.4531 16.0508 18.3828 15.8984 18.2891C15.7461 18.1953 15.4297 17.9375 14.9492 17.5156C14.4688 17.0938 13.9883 16.6016 13.5078 16.0391C12.9336 16.1797 12.3477 16.25 11.75 16.25C10.8945 16.25 10.1064 16.1621 9.38574 15.9863C8.66504 15.8105 8.07617 15.5938 7.61914 15.3359C7.16211 15.0781 6.77246 14.791 6.4502 14.4746C6.12793 14.1582 5.89941 13.8652 5.76465 13.5957C5.62988 13.3262 5.5625 13.0859 5.5625 12.875H7.25C8.76172 12.875 10.1064 12.6113 11.2842 12.084C12.4619 11.5566 13.3965 10.7773 14.0879 9.74609C14.7793 8.71484 15.125 7.50781 15.125 6.125C17.375 7.26172 18.5 8.85547 18.5 10.9062C18.5 12.582 17.75 13.9883 16.25 15.125ZM7.25 11.75C6.65234 11.75 6.06641 11.6797 5.49219 11.5391C5.01172 12.1016 4.54297 12.5879 4.08594 12.998C3.62891 13.4082 3.28906 13.6777 3.06641 13.8066L2.75 14V10.625C1.25 9.48828 0.5 8.08203 0.5 6.40625C0.5 5.33984 0.801758 4.35254 1.40527 3.44434C2.00879 2.53613 2.8291 1.81836 3.86621 1.29102C4.90332 0.763672 6.03418 0.5 7.25879 0.5C8.4834 0.5 9.61426 0.763672 10.6514 1.29102C11.6885 1.81836 12.5059 2.53613 13.1035 3.44434C13.7012 4.35254 14 5.33984 14 6.40625C14 7.47266 13.6953 8.41602 13.0859 9.23633C12.4766 10.0566 11.6621 10.6807 10.6426 11.1084C9.62305 11.5361 8.49219 11.75 7.25 11.75ZM7.25 1.625C6.23047 1.625 5.29004 1.82422 4.42871 2.22266C3.56738 2.62109 2.88477 3.16602 2.38086 3.85742C1.87695 4.54883 1.625 5.30176 1.625 6.11621C1.625 6.93066 1.87695 7.68359 2.38086 8.375C2.88477 9.06641 3.56738 9.61426 4.42871 10.0186C5.29004 10.4229 6.23047 10.625 7.25 10.625C8.26953 10.625 9.20996 10.4229 10.0713 10.0186C10.9326 9.61426 11.6152 9.06641 12.1191 8.375C12.623 7.68359 12.875 6.93066 12.875 6.11621C12.875 5.30176 12.623 4.54883 12.1191 3.85742C11.6152 3.16602 10.9326 2.62109 10.0713 2.22266C9.20996 1.82422 8.26953 1.625 7.25 1.625Z"
                fill="#191919"
              />
            </g>
            <defs>
              <clipPath id="clip0_211_604">
                <rect
                  width="18"
                  height="18"
                  fill="white"
                  transform="translate(0.5 0.5)"
                />
              </clipPath>
            </defs>
          </svg>{" "}
          Chat
        </button>

        <button className="button_darkmain" onClick={opanBidmodal} >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
          >
            <g clipPath="url(#clip0_312_7367)">
              <path
                d="M0 16.9999H10.5V18.4999H0V16.9999ZM9 15.4877C9 15.2888 8.92098 15.0981 8.78033 14.9574C8.63968 14.8168 8.44891 14.7377 8.25 14.7377H2.25C2.05109 14.7377 1.86032 14.8168 1.71967 14.9574C1.57902 15.0981 1.5 15.2888 1.5 15.4877V16.2499H9V15.4877ZM10.2049 9.35574C9.85542 9.72818 9.56238 10.1498 9.33506 10.6071L15.5083 16.7804C15.649 16.921 15.8397 17 16.0386 17C16.2374 17 16.4282 16.921 16.5688 16.7804L17.6295 15.7197C17.6991 15.65 17.7544 15.5674 17.7921 15.4764C17.8298 15.3854 17.8492 15.2878 17.8492 15.1893C17.8492 15.0908 17.8298 14.9933 17.7921 14.9023C17.7544 14.8113 17.6991 14.7286 17.6295 14.659L11.4562 8.48743C10.9981 8.71275 10.5763 9.00543 10.2049 9.35574ZM6.49256 5.64343C5.9254 6.16977 5.26886 6.59068 4.55381 6.88636L8.43019 10.7627C8.72852 10.0489 9.14986 9.39304 9.675 8.82493C10.2422 8.29861 10.8987 7.87764 11.6137 7.5818L7.73719 3.70618C7.43875 4.41972 7.01749 5.0754 6.49256 5.64343Z"
                fill="white"
              />
              <path
                d="M9.14275 0.871168L8.08209 1.93183C7.78919 2.22472 7.78919 2.6996 8.08209 2.99249L12.3247 7.23513C12.6176 7.52802 13.0925 7.52802 13.3854 7.23513L14.446 6.17447C14.7389 5.88158 14.7389 5.4067 14.446 5.11381L10.2034 0.871168C9.91051 0.578275 9.43564 0.578275 9.14275 0.871168Z"
                fill="white"
              />
              <path
                d="M2.77849 7.23384L1.71783 8.2945C1.42494 8.58739 1.42494 9.06227 1.71783 9.35516L5.96047 13.5978C6.25336 13.8907 6.72824 13.8907 7.02113 13.5978L8.08179 12.5371C8.37468 12.2442 8.37468 11.7694 8.08179 11.4765L3.83915 7.23384C3.54626 6.94095 3.07138 6.94095 2.77849 7.23384Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_312_7367">
                <rect
                  width="18"
                  height="18"
                  fill="white"
                  transform="translate(0 0.5)"
                />
              </clipPath>
            </defs>
          </svg>{" "}
          Bid{" "}
        </button>
      </div>
    </div>

<BidFormModal closeBidmodal={closeBidmodal} bidmodal={bidmodal} />
    </> 
  );
};

export default PriceDetail;
