import React from "react";
import { images } from "../../../actions/customFn";
import { NavLink } from "react-router-dom";

const LatestBlogs = () => {
  const latestblogdata = [
    { blogimg: images["latest_blog1.png"] },
    { blogimg: images["latest_blog1.png"] },
    { blogimg: images["latest_blog1.png"] },
    { blogimg: images["latest_blog1.png"] },
    { blogimg: images["latest_blog1.png"] },
    { blogimg: images["latest_blog1.png"] },
  ];

  return (
    <div className="latestblogs_mainsection">
      <div className="container">
        <div className="row">
          {latestblogdata.map((v, i) => {
            return (
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="latestblog_card1">
                  <div className="latest_imgblog1">  
                  <div
                    className="blog_mainimage"
                    style={{ backgroundImage: `url(${v.blogimg})` }}
                  ></div>
 </div>
 
                  <div className="blogs_detailcont">
                    <h5>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </h5>

                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>

                    <div className="admin_dateflex">
                      <div className="profile_name">
                        <img src={images["person1.png"]} alt="" />
                        <p>Admin</p>
                      </div>
                      <div className="date_flex">
                        <img src={images["calendar1.png"]} alt="" />
                        <p>02 - 02 - 2024</p>
                      </div>
                    </div>

                    <NavLink to={"/blog-detail"}>
                      <div className="readmore_button">Read More</div>
                    </NavLink>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LatestBlogs;
