import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { images } from "../../../actions/customFn";

const RequirementsLists = () => {
  const firsttab_tabledata = [
    { status: "Completed" },
    {status: 'Inprogress'},
    { status: "Completed" },
    { status: "Completed" },
    { status: "Open" },
    { status: "Inprogress" },
    { status: "Inprogress" },
    { status: "Open" },
    { status: "Completed" },
    { status: "Completed" },
    { status: "Inprogress" },
   
  ];

  const navigate = useNavigate();

  const redirectpage = ()=>{
    navigate('/buyer/biddedlist');
  }

  return (
    <div className="requirements_listpagemain">
      <div className="requirements_table">
        <div className="table-responsive ">
          <table class="table table-striped table-borderless">
            <thead>
              <tr>
                <th scope="col">ID number</th>
                <th scope="col">Date Listed</th>
                <th scope="col">Product</th>
                {/* <th scope="col">Delivery Port</th> */}
                {/* <th scope="col">Quantity</th> */}
                <th scope="col">Origin</th>
                <th scope="col">Grade</th>
                <th scope="col">Specification</th>
                <th scope="col">Quantity</th>
                <th scope="col">Delivery Port</th>
                <th scope="col">Incoterms</th>
                <th scope="col">No. of shipments</th>
                <th scope="col">Delivery period</th>
                <th scope="col"> Additional information</th>
                <th scope="col"> Status</th>
                <th scope="col"> Action</th>
              </tr>
            </thead>
            <tbody>
              {firsttab_tabledata.map((v, i) => {
                return (
                  
                  <tr className="linking_row"  >
                    <td scope="row">73749</td>
                    <td>12.04.24</td>
                    <td>Oaten Hay</td>
                    <td>Any</td>
                    <td>Premium</td>
                    <td>Premium</td>
                    <td>200 mts</td>
                    <td>Jebel Ali</td>
                    <td>CIF</td>
                    <td>1</td>
                    <td>12.04.24</td>
                    <td>Lorem Ipsum is simply...</td>
                    <td> 
                      <div className="show_status">
                        <p className={v.status == 'Completed' ? 'completed_tag': v.status == 'Inprogress' ? 'inprogess_tag' : 'open_tag'}>{v.status}</p>
                      </div>{" "}
                    </td>
                    <td className="actions_list">
                      <NavLink to={"/buyer/requirement-detail"}>
                        {" "}
                        <img src={images["view_btn.png"]} alt="" />{" "}
                      </NavLink>
                      <NavLink to={""}>
                        {" "}
                        <img src={images["edit_btn.png"]} alt="" />{" "}
                      </NavLink>
                      <button>
                        <img src={images["delete_btn.png"]} alt="" />
                      </button>
                    </td>
                  </tr>
                  
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RequirementsLists;
