import React from "react";
import {
    HomeBanner,
    Blogs,
    Community,
    HowWorks,
    TabTable,
    HomeServices
  } from "../../components/Front";
  import { motion } from "framer-motion";

function Home(){
    return(
        <motion.div
        initial={{ opacity: 0.2 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1 }}
        transition={{ duration: 2 }}
      >
        <>
        <div id="home_page" style={{display : "none"}}>
            
        </div>
            {/* <Header/> */}
            <HomeBanner/>
            <TabTable/>
            <Community/>
            <HowWorks/>
            <HomeServices />
            <Blogs heading={"Blog"} />
            {/* <Footer/> */}
        </>
        </motion.div>
    );
}

export default Home;