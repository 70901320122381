import React from 'react';
import {
    AvailabilityHeader,
  AvailabilitySidebar,
  ContractData,
  RequirementDescription,
  
} from "../../components/Front";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { images } from "../../actions/customFn";

const ContractDetails = () => {
  return (
    <motion.div
    initial={{ opacity: 0.2 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 1 }}
    transition={{ duration: 2 }}
  >
    <>
      <div className="dashboard-layout">
        <div className="layout_left_side">
          <AvailabilitySidebar />
        </div>

        <div className="layout-right-sider">
          <div className="">
            <AvailabilityHeader headertopic="Contract Details" />

            <div className="dashborader_datacontentmain">

               <ContractData />
                
               <RequirementDescription />

              </div>

          </div>
        </div>
      </div>
    </>
  </motion.div>
  )
}

export default ContractDetails