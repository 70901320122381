import React from 'react'
import { images } from '../../../actions/customFn';
import { NavLink } from 'react-router-dom';

const InvoiceQuantityRates = () => {

    const rates_data = [{},{}];

    const payment_data = [
        {title: 'Bank account no', point: '000-000-0xx-xxx'},
        {title: 'PayPal ID', point: '7374923654'},
        {title: 'IFSC code', point: 'ABC0010'}, 
    ]

  return (
    <div className="invoice_quantityratesdiv">
        <div className="description_part">
            <h6>Service  Description</h6>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
        </div>

<h4 className="topics">Quantity and Rates</h4>

<div className="requirements_listpagemain">
<div className="requirements_table">
      <div className="table-responsive ">
        <table class="table table-striped table-borderless">
          <thead>
            <tr>
            <th scope="col">Product ID</th>
            <th scope="col">QTY</th>
            <th scope="col" className='text_endt'>Unit Price</th>
            <th scope="col" className='text_endt'>Amount</th>
              
            </tr>
          </thead>
          <tbody>
            {rates_data && rates_data.map((v, i) => {
              return (
                <tr>
                 <td scope="row">PTD-1000245613115</td> 
                 <td>200mts</td> 
                 <td className='text_endt'>$1200.00</td> 
                 <td className='text_endt'>$1200.00</td> 
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
    </div>

<div className="bottom_tableprice">                                                                                                           
    <div className="totals_opicflex">
        <h6>Sub total</h6>
        <h6>$2400.00</h6>
    </div>
    </div>
    <div className="bottomprice_tablecontent">
        <div className="price_contflex">  
    <h6>Tax</h6>
    <h6 className='viewdata' >$500.00</h6>
    </div>
        <div className="price_contflex">  
    <h6>Discount</h6>
    <h6 className='viewdata'>-</h6>
    </div>
        <div className="price_contflex">  
    <h6>Total amount due</h6>
    <h6 className='viewdata'>$2900.00</h6>
    </div>
        <div className="price_contflex">  
   <button className='button_darkmain'>Pay Now</button>
    </div>
    </div>

    <h4 className="topics">Payment Instructions</h4>

    <div className="bidlist_detailmainsection  payment_instdatapart">
        <div className="row">
              {payment_data.map((v, i) => {
                return (
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="bidlist_uniquelists">
                  <div className="build_list1">
                    <div className="row">
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
                       
                        <p>{v.title}</p>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
                        <h6>{v.point}</h6>
                      </div>
                    </div>
                  </div>
            </div>
          </div>
                );
              })}

 

           
        </div>

      </div>

    <h4 className="topics">Terms & Conditions</h4>
    <div className="requirement_description">
        {/* <h5>Additional information</h5> */}
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>

<ul>
    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
</ul>

    </div>

    </div>
  )
}

export default InvoiceQuantityRates;