import React from "react";
import { 
  DashboardHeader, 
  DashboardSidebar,
  OrderTracking, 
} from "../../components/Front";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { images } from "../../actions/customFn";

const InspectionDetail = () => {

    const trade_detaillist = [
        { title: "Inspector name", point: "Johan deo" },
        { title: "Email", point: "Glob@gmail.com" },
        { title: "Contact no.", point: "+1 231 256 3233" },
        { title: "Company name", point: "Globex Corporation" }, 
    ]

  return (
    <motion.div
    initial={{ opacity: 0.2 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 1 }}
    transition={{ duration: 2 }}
  >
    <>
      <div className="dashboard-layout">
        <div className="layout_left_side">
          <DashboardSidebar />
        </div>

        <div className="layout-right-sider">
          <div className="">
            <DashboardHeader headertopic="Inspection Detail" />

            <div className="dashborader_datacontentmain">
              
            <div className="orderdetail_mainsection tracking_detailpage inspectdetail_top">
      <div className="orderdetail_toppart">
        <div className="left_orderdetail">
          <img className="p_img" src={images["latest_blog1.png"]} alt="" />

          <div className="datalist_flex">
            <h6>Inspection id  #12542</h6>
            <div className="horizont_divide"></div>
            <div className="date_flex">
                <h6>Inspection Date:</h6>
              <img src={images["gray_date.png"]} alt="" />
              <h6><span>26/07/2024</span></h6>
            </div>
            <div className="horizont_divide"></div>
            <div className="date_flex">
                <h6>Inspection Time:</h6>
              <img src={images["black_clock.png"]} alt="" />
              <h6> <span>03:15 PM</span></h6>
            </div>
            <div className="horizont_divide"></div>
            <div className="schedule_tag">Scheduled</div>
          </div>
        </div>

       
      </div>

      <div className="bidlist_detailmainsection quatation_detaillistmain">
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
            <div className="bidlist_uniquelists">
              {trade_detaillist.map((v, i) => {
                return (
                  <div className="build_list1">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        {" "}
                        <p>{v.title}</p>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <h6>{v.point}</h6>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

        

           
        </div>

  
  
        <div className="inspect_reasonpart">
          <h5 className="reason_topic">Reason for inspection</h5>
<p>Lorem ipsum dolor sit amet consectetur. Arcu sed vitae morbi massa. Odio ac ut ornare a facilisi. Urna quam turpis cras eu dignissim laoreet aliquam eu donec.</p>
 
      </div>

      <div className="lets_discussspart">
<p>In you need help with inspection  you can discuss with inspectors and other relevant parties</p>
<button className="button_darkmain">Let’s Discuss</button>
      </div>

      </div>

 
    </div>
               
 

            </div>
          </div>
        </div>
      </div>
    </>
    </motion.div>
  )
}

export default InspectionDetail