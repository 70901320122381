import React from "react";
import { images } from "../../actions/customFn";
import { Banner } from "../../components/Front";
import { motion } from "framer-motion";

const UserBuyers = () => {
  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <div className="userbuyer_mainpage">
        <Banner
          title="Welcome, Buyers!"
          bg={images["user-buyerbg.png"]}
          paragraph={
            "Are you an importer, distributor, trader, or end-user based in the dynamic Middle East or an Arabic-speaking country in search of top-quality raw materials from across the globe? Look no further –SupplyArabia is your ultimate marketplace destination."
          }
        />

        <div className="aboutsupplier_mainsection">
          <div className="container">
            <div className="suplierabout_cards1">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <img src={images["buyer_about1.png"]} alt="" />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pt-sm-4">
                  <h5>Empower Your Procurement Process, Expand Your Options</h5>
                  <p>
                    Joining SupplyArabia as a supplier opens doors to
                    unparalleled opportunities. Signing up for an account is not
                    just easy, it's completely free. Simply create a profile and
                    submit an application to become a seller. Whether you prefer
                    to market your company and products openly, remain
                    anonymous, or adjust your privacy settings, you're in full
                    control of your presence on the platform.
                  </p>
                </div>
              </div>
            </div>

            <div className="suplierabout_cards1">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pt-sm-4">
                  <h5>Streamlined Connections, Direct Communication</h5>
                  <p>
                    Joining SupplyArabia as a supplier opens doors to
                    unparalleled opportunities. Signing up for an account is not
                    just easy, it's completely free. Simply create a profile and
                    submit an application to become a seller. Whether you prefer
                    to market your company and products openly, remain
                    anonymous, or adjust your privacy settings, you're in full
                    control of your presence on the platform.
                  </p>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <img src={images["buyer_about2.png"]} alt="" />
                </div>
              </div>
            </div>

            <div className="suplierabout_cards1">
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 pt-sm-4">
                  <div className="supplier_endcards">
                    <img src={images["buyer_about3.png"]} alt="" />
                    <h5>Drive Supply, Maximize Value</h5>
                    <p>
                      Utilize SupplyArabia's suite of services to streamline
                      your business dealings on the platform. From Credit
                      Insurance to safeguard your investments to other
                      assistance tailored to your needs, we're here to help
                      mitigate potential issues and ensure your trading
                      experience is smooth and secure.
                    </p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 pt-sm-4">
                  <div className="supplier_endcards">
                    <img src={images["buyer_about4.png"]} alt="" />
                    <h5>Secure Transactions, Peace of Mind</h5>
                    <p>
                      Utilize SupplyArabia's suite of services to streamline
                      your business dealings on the platform. From Credit
                      Insurance to safeguard your investments to other
                      assistance tailored to your needs, we're here to help
                      mitigate potential issues and ensure your trading
                      experience is smooth and secure.
                    </p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 pt-sm-4">
                  <div className="supplier_endcards">
                    <img src={images["buyer_about5.png"]} alt="" />
                    <h5>Unlock Your Purchasing Potential</h5>
                    <p>
                      Utilize SupplyArabia's suite of services to streamline
                      your business dealings on the platform. From Credit
                      Insurance to safeguard your investments to other
                      assistance tailored to your needs, we're here to help
                      mitigate potential issues and ensure your trading
                      experience is smooth and secure.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default UserBuyers;
