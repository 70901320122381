import React from "react";
import { NavLink } from "react-router-dom";
import {
  PriceDetail,
  RequirementDescription,
  RequirementsSlider,
} from "../../components/Front";
import { images } from "../../actions/customFn";
import { motion } from "framer-motion";

const RequirementsDetail = () => {
  return (
    <motion.div
    initial={{ opacity: 0.2 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 1 }}
    transition={{ duration: 2 }}
  >
    <div className="requirementsdetail_pagemain">
      <div className="pagenavlink_main">
        <div className="container">
          <ul>
            <li>
              <NavLink to={""}>Home</NavLink> /
            </li>
            <li>
              <NavLink to={""}> Listing</NavLink> /
            </li>
            <li>
              <NavLink to={""}> Detail</NavLink>
            </li>
          </ul>
        </div>
      </div>

      <div className="requirement_content">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
              <RequirementsSlider />
              <RequirementDescription />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
              <PriceDetail />

              <div className="producttime_bidsmain">
                <div className="timeprice_flex">
                  <div>
                    <h6>
                      {" "}
                      <img src={images["small_time.png"]} alt="" /> Add Product
                      Time
                    </h6>
                    <h4>2 : 12 : 05</h4>
                  </div>
                  <div className="bids_part">
                    <p>Closing Date</p>
                    <h3>12.10.24</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </motion.div>
  );
};

export default RequirementsDetail;
