import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

const SignUpModal = (props) => {
  const { setSignupPart } = props;

  const navigation = useNavigate();

  const signup_redirect = ()=>{
    navigation('/seller/dashboard');
  }

  return (
    <>
      <div className="login_rightcontentpart signup_maincontent">
        <h2>Signup</h2>

        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </p>

        <div className="scroll_signupinputs">
          <div className="input_allmain">
            <label>Name</label>
            <input type="text" placeholder="Enter your name" />
          </div>
          <div className="input_allmain">
            <label>Email</label>
            <input type="text" placeholder="Enter your email address" />
          </div>
          <div className="input_allmain">
            <label>Telephone Number</label>
            <input type="text" placeholder="Enter your telephone number" />
          </div>
          <div className="input_allmain">
            <label>Company Name</label>
            <input type="text" placeholder="Enter your Company Name" />
          </div>
          <div className="input_allmain">
            <label>Address</label>
            <input type="text" placeholder="Enter your Address" />
          </div>
          <div className="input_allmain">
            <label>Role</label>
            <input type="text" placeholder="Enter your Role" />
          </div>
          <div className="input_allmain">
            <label>Activities</label>
            <input type="text" placeholder="Enter your Activities" />
          </div>
          <div className="input_allmain">
            <label>Products</label>
            <input type="text" placeholder="Enter your Products" />
          </div>
          <div className="input_allmain">
            <label>Volumes</label>
            <input type="text" placeholder="Enter your Volumes" />
          </div>

          <div className="input_allmain">
            <label>Password</label>
            <input type="text" placeholder="Enter your password" />
          </div>
          <div className="input_allmain">
            <label>Confirm Password</label>
            <input type="text" placeholder="Enter Confirm Password" />
          </div>

          {/* <div className="forgotpassword">
                <button>Forgot Password?</button>
              </div> */}

          <div className="login_button">
            <button className="button_darkmain" onClick={()=>signup_redirect()} >Signup</button>
          </div>

          <div className="or_section">
            <p>
              <span>Or</span>
            </p>

            <p className="signup_link">
              Already have an account ?
              <button onClick={setSignupPart} className="">
                Login
              </button>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpModal;
