import React from "react";
import {
  DashboardHeader,
  DashboardSidebar,
  QuatationDetailList,
  RequirementDescription,
} from "../../components/Front";
import { motion } from "framer-motion";

const QuatationDetailDashboard = () => {
  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <>
        <div className="dashboard-layout">
          <div className="layout_left_side">
            <DashboardSidebar />
          </div>

          <div className="layout-right-sider">
            <div className="">
              <DashboardHeader headertopic="Quotation Details" />

              <div className="dashborader_datacontentmain">
                <QuatationDetailList />
                <div className="requirement_description quotationdetail_inform">
        <h5>More Detail</h5>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>

<ul>
    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li> 
</ul>

<h5 className="adiition_info">Additional Information</h5>
<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>


    </div>
                
    {/* <div className="requirement_description">
        <h5>Additional information</h5>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
        </div> */}
                
              </div>
            </div>
          </div>
        </div>
      </>
    </motion.div>
  );
};

export default QuatationDetailDashboard;
