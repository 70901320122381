import React from "react";
import { images } from "../../../actions/customFn";

const OrderDetail = () => {
  const bidlist = [
    { title: "Company", point: "Agrimark" },
    { title: "Grade", point: "Premium" },
    { title: "Delivery Port", point: "Jebel Ali" },
    { title: "Origin", point: "USA" },
    { title: "Delivery Port", point: "Jebel Ali" },
  ];

  const order_history = [{}, {}, {}, {}];
  const supplier_info1 = [
{ title: "Name", info: "Clifford M. Pereira" },
{ title: "Email", info: "Glob@gmail.com" },
{ title: "Telephone Number", info: "+1 231 256 3233" },
{ title: "Company Name", info: "Globex Corporation" }, 
  ]

  const supplier_info22 = [
    { title: "Role", info: "Lorem Ipsum" },
    { title: "Activities", info: "Lorem Ipsum" },
    { title: "Services/ products offered", info: "Oaten Hay" },
    { title: "Volumes", info: "200mts" },
  ]
  const supplier_info3 = [
    { title: "Website link", info: "www.abc.com" },
    { title: "Business categories", info: "Finance" },
    { title: "Brief description", info: "Lorem Ipsum is simply dummy." },
    { title: "Address", info: "Lorem Ipsum is simply dummy text of the " },
  ]

  return (
    <div className="orderdetail_mainsection">
      <div className="orderdetail_toppart">
        <div className="left_orderdetail">
          <img className="p_img" src={images["latest_blog1.png"]} alt="" />

          <div className="datalist_flex">
            <h6>Order #12542</h6>
            <div className="horizont_divide"></div>
            <div className="date_flex">
              <img src={images["gray_date.png"]} alt="" />
              <h6>26/07/2024</h6>
            </div>
            <div className="horizont_divide"></div>
            <div className="processing_tag">Processing</div>
          </div>
        </div>

        <div className="right_orderbtns">
          {/* <button className="transparent_btnmain">Cancel Order</button> */}
          <button className="button_darkmain">Contact SupplyArabia</button>
        </div>
      </div>

      <div className="bidlist_detailmainsection quatation_detaillistmain">
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
            <div className="bidlist_uniquelists">
              {bidlist.map((v, i) => {
                return (
                  <div className="build_list1">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        {" "}
                        <p>{v.title}</p>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <h6>{v.point}</h6>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
            <div className="bidlist_uniquelists">
              {bidlist.map((v, i) => {
                return (
                  <div className="build_list1">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        {" "}
                        <p>{v.title}</p>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <h6>{v.point}</h6>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
            <div className="bidlist_uniquelists">
              <div className="build_list1">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                    {" "}
                    <p>Payment Terms</p>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                    <h6>CAD</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="row">
        <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-12">
          <div className="orderhistory_section">
            <h4>Order History</h4>
            <div className="order_historylist">
              {order_history.map((v, i) => {
                return (
                  <div className="history_list1">
                    <div className="row">
                      <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-6">
                        {" "}
                        <p>20/07/2024</p>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <h6>Placed order</h6>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12 col-12">
          <div className="orderhistory_section">
           <div className="heading_flex"> <h4>Seller information</h4> <img src={images['msg_green.png']} alt="" /> </div>
            <div className="order_historylist seller_information">
              <div className="row">
                <div className="col-xl-1 col-lg-2 col-md-12 col-sm-2 col-12">
                  <img src={images["profile_1img.png"]} alt="" />
                </div>
                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                  {supplier_info1.map((v, i) => {
                    return (
                      <div className="history_list1">
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <p>{v.title}</p>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <h6>{v.info}</h6>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                  {supplier_info22.map((v, i) => {
                    return (
                      <div className="history_list1">
                        <div className="row">
                          <div className="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-6">
                            <p>{v.title}</p>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <h6>{v.info}</h6>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12">
                  {supplier_info3.map((v, i) => {
                    return (
                      <div className="history_list1">
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <p>{v.title}</p>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <h6>{v.info}</h6>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
