import React from 'react';
import { Banner, Blogs, LatestBlogs } from '../../components/Front';
import { images } from '../../actions/customFn'; 
import { motion } from "framer-motion";

function BlogPage() {
  return (
    <motion.div
    initial={{ opacity: 0.2 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 1 }}
    transition={{ duration: 2 }}
  >
    <div className='blogs_mainpage'>
             <Banner title="Blog" bg={images["blog_bannerbg.png"]}   /> 
           {/* <h3>Blog</h3> */}
             
             <Blogs heading={"Top Post"} />
             <LatestBlogs />

        </div>
        </motion.div>
  )
}

export default BlogPage;