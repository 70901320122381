import React from "react";
import {
    AvailabilityHeader,
  AvailabilitySidebar,
 
  
} from "../../components/Front";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { images } from "../../actions/customFn";

const ApplyCreditInsurance = () => {
  return (
    <motion.div
    initial={{ opacity: 0.2 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 1 }}
    transition={{ duration: 2 }}
  >
    <>
      <div className="dashboard-layout">
        <div className="layout_left_side">
          <AvailabilitySidebar />
        </div>

        <div className="layout-right-sider">
          <div className="">
            <AvailabilityHeader headertopic="Apply For Credit Insurance" />

            <div className="dashborader_datacontentmain">
          
            <div className="addnewtracking_section">
                  <div className="addrequirement_mainsection">

                    <div className="require_flexinput">
                      <div className="input_allmain">
                        <label> Buyer name</label>
                        <input type="text" placeholder="Enter Buyer name" />
                      </div>
                      <div className="input_allmain">
                        <label>Product name</label>
                        <input
                          type="text"
                          placeholder="Enter Product name"
                        />
                      </div>
                    </div>

                    <div className="require_flexinput">
                      <div className="input_allmain">
                        <label> Origin</label>
                        <input type="text" placeholder="Enter Origin" />
                      </div>
                      <div className="input_allmain">
                        <label>Price</label>
                        <input
                          type="text"
                          placeholder="Enter Price"
                        />
                      </div>
                    </div>


                    <div className="require_flexinput">
                      <div className="input_allmain">
                        <label> Payment terms</label>
                        <input type="text" placeholder="Enter Payment terms" />
                      </div>
                      <div className="input_allmain">
                        <label>Delivery Port</label>
                        <input
                          type="text"
                          placeholder="Enter Delivery Port"
                        />
                      </div>
                    </div>
                    <div className="require_flexinput">
                      <div className="input_allmain">
                        <label> Incoterms</label>
                        <input type="text" placeholder="Enter Incoterms" />
                      </div>
                      <div className="input_allmain">
                        <label>No. of shipments</label>
                        <input
                          type="text"
                          placeholder="Enter shipments"
                        />
                      </div>
                    </div>
                    <div className="require_flexinput">
                      <div className="input_allmain">
                        <label> Delivery period</label>
                        <input type="text" placeholder="Enter Delivery period" />
                      </div>
                      <div className="input_allmain">
                        <label>Total Value</label>
                        <input
                          type="text"
                          placeholder="Enter Total Value"
                        />
                      </div>
                    </div>

                   

                   
                  </div>

                  <div className="add_trackbutton">
                    <button className="button_darkmain">Add</button>
                  </div>
                </div>

            </div>

          </div>
        </div>
      </div>
    </>
  </motion.div>
  )
}

export default ApplyCreditInsurance;