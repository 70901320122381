import React from 'react'
import { images } from '../../../actions/customFn';
import Modal from "react-bootstrap/Modal";

const AddQuotationModal = (props) => {

    const { addquatemodal, closeQuatationmodal } = props;
  return (
    <div>
    <Modal
      className="login_modalmain"
      show={addquatemodal}
      onHide={closeQuatationmodal}
      centered
    >
      <Modal.Body>
        <div className="closemodal_btn">
          <button onClick={closeQuatationmodal}>
            <img src={images["cross_btn.png"]} alt="" />
          </button>
        </div>
        <div className="bidform_modalsection">
 
 <h2>Add Quotation</h2>

 <div className="form_inputsflex">

 <div className="input_allmain">
                <label>Company</label>
                <input type="text" placeholder="Enter Company" />
              </div>

              <div className="input_allmain">
                <label>Product</label>
                <input type="text" placeholder="Enter your Product" />
              </div>
              <div className="input_allmain">
                <label>Quantity</label>
                <input type="text" placeholder="Enter your Quantity" />
              </div>
              <div className="input_allmain">
                <label>Grade</label>
                <input type="text" placeholder="Enter your Grade" />
              </div>

              <div className="input_allmain">
                <label>Origin</label>
                <input type="text" placeholder="Enter your Origin" />
              </div>

              <div className="input_allmain">
                <label>Price</label>
                <input type="text" placeholder="Enter Price" />
              </div>
              <div className="input_allmain">
                <label>Incoterms</label>
                <input type="text" placeholder="Enter Incoterms" />
              </div>
              <div className="input_allmain">
                <label>Delivery Port</label>
                <input type="text" placeholder="Enter Delivery Port" />
              </div>
              <div className="input_allmain">
                <label>No. of shipments </label>
                <input type="text" placeholder="Enter No. of shipments " />
              </div>
              <div className="input_allmain">
                <label>Delivery Period</label>
                <input type="text" placeholder="Enter Delivery Period" />
              </div>
              <div className="input_allmain">
                <label>Payment Terms</label>
                <input type="text" placeholder="Enter Payment Terms" />
              </div>

 </div>

 {/* <div className="input_allmain">
                <label>Additional Information</label>
                <textarea type="text" rows={5} placeholder="Enter your message" />
              </div> */}

              <div className="bidnow_btn">
                <button className='button_darkmain'>  PDF Generator</button>
              </div>

        </div>
      </Modal.Body>
    </Modal>
  </div>
  )
}

export default AddQuotationModal;