import React from 'react'
import { images } from '../../../actions/customFn';

function AboutCard() {
  return (
    <>
        <div className='about_card_sec'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                        <div className='card_box'>
                            <img src={images["about-card-1.png"]} alt="" />
                            <h5 className='box_title'>Streamlined Trading, Tailored Solutions</h5>
                            <p className='box_content'>SupplyArabia simplifies the buying and selling process for commodities, providing users with indispensable tools to facilitate transactions, optimize pricing, and mitigate associated risks. From seamless Account Verification and comprehensive Credit Insurance to reliable Shipment Tracking, top-notch Inspection Services, and flexible Payment Options, we offer tailored solutions to enhance your trading experience.</p>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                        <div className='card_box'>
                            <img src={images["about-card-2.png"]} alt="" />
                            <h5 className='box_title'>Marketplace Dynamics</h5>
                            <p className='box_content'>Post your requirements and experience the excitement of a live marketplace with firm offers, firm bids, and automatic trade functionality. Our cutting-edge technology simplifies the buying and selling process, enabling secure transactions of raw materials with ease. Manage bids and offers effortlessly from any device, ensuring you stay in control every step of the way.</p>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                        <div className='card_box'>
                            <img src={images["about-card-3.png"]} alt="" />
                            <h5 className='box_title'>Maximum Value</h5>
                            <p className='box_content'>Here's the best part: Signing up for a SupplyArabia account is completely free. Simply create your SupplyArabia account today and unlock endless opportunities in the world of raw material supply. Let's reshape the future of commerce together.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default AboutCard;