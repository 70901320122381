// import { Dashboard } from "@mui/icons-material";
import {
  AboutUs,
  BlogPage,
  Market,
  Home ,
  Services,
  UsersSupplier,
  BlogDetails,
  UserBuyers,
  UserBusinessServices,
  RequirementsDetail,
  AvailabilityDetail,
  ContactUs,
 
  TermAndCondition,
} from "../pages/Front"; 
 const RouteArr = [
  {
    name: "Home",
    path: "/",
    key: "home",
    route: "/",
    element: <Home />,
  },
  {
    name: "Blog",
    path: "/blog",
    key: "/blog",
    route: "/blog",
    element: <BlogPage />,
  },
  {
      name: "Market",
      path: "/market",
      key: "/market",
      route: "/market",
      element: <Market />,
    },
    {
      name: "AboutUs",
      path: "/about-us",
      key: "/about-us",
      route: "/about-us",
      element: <AboutUs />,
    },
    {
      name: "Services",
      path: "/services",
      key: "/services",
      route: "/services",
      element: <Services />,
    },
    {
      name: "UsersSupplier",
      path: "/user-supplier",
      key: "/user-supplier",
      route: "/user-supplier",
      element: <UsersSupplier />,
    },
    {
      name: "BlogDetails",
      path: "/blog-detail",
      key: "/blog-detail",
      route: "/blog-detail",
      element: <BlogDetails />,
    },
    {
      name: "UserBuyers",
      path: "/user-buyers",
      key: "/user-buyers",
      route: "/user-buyers",
      element: <UserBuyers />,
    },
    {
      name: "UserBusinessServices",
      path: "/user-business",
      key: "/user-business",
      route: "/user-business",
      element: <UserBusinessServices />,
    },
    {
      name: "RequirementsDetail",
      path: "/availability-detail",
      key: "/availability-detail",
      route: "/availability-detail",
      element: <RequirementsDetail />,
    },
    {
      name: "AvailabilityDetail",
      path: "/requirement-detail",
      key: "/requirement-detail",
      route: "/requirement-detail",
      element: <AvailabilityDetail />,
    },
    {
      name: "ContactUs",
      path: "/contact-us",
      key: "/contact-us",
      route: "/contact-us",
      element: <ContactUs />,
    },
    {
      name: "TermAndCondition",
      path: "/term-condition",
      key: "/term-condition",
      route: "/term-condition",
      element: <TermAndCondition />,
    },
 


  {
    path: "",
    private: true,
    element: <></>,
    children: [
      {
        path: "",
        element: "Private Route",
      },
    ],
  },
];

export default RouteArr;
