import React from 'react'
import { images } from '../../../actions/customFn';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { NavLink } from 'react-router-dom';

const DashboardAvailability = () => {

    const firsttab_tabledata = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];

    const messageinbox = [{}, {}, {}, {}];

    const secondtab_tabledata = [{}, {}, {}, {}, {}, {}];

  return (
    <div className="dashboard_requirements_mainsection">
      <div className="requirements_sectionmain ">
        {/* <h4>Last Availability</h4> */}

<div className="requirements_table">
        <div className="tab_table_sec">
       
          <Tabs
            defaultActiveKey="open_rq"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="open_rq" title="Open Requirements">
              <div className="table-responsive ">
                <table class="table table-striped table-borderless">
                  <thead>
                    <tr>
                    <th scope="col">ID number</th>
                      <th scope="col">Delivery Port</th>
                      <th scope="col">Date Listed</th>
                      <th scope="col">Product</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Grade</th>
                      <th scope="col">Origin</th>
                      <th scope="col">Total quotes</th>
                      <th scope="col">Incoterns</th>
                      <th scope="col">More </th> 
                      <th scope="col">Action </th> 
                    </tr>
                  </thead>
                  <tbody>
                    {firsttab_tabledata.map((v, i) => {
                      return (
                        <tr>
                           <td scope="row">73749</td>
                          <td>Jebel Ali</td>
                          <td>12.04.24</td>
                          <td>Oaten Hay</td>
                          <td>200 mts</td>
                          <td>Premium</td>
                          <td>Any</td>
                          <td>20 Bid</td>
                          <td>CIF</td>
                          <td>Lorem Ipsum is simply...</td>
                          <td>
                          <button  >View</button>
                            <button >Bid</button>
                            <button  >Chat</button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Tab>
            <Tab eventKey="current_av" title="Current Availability">
              <div className="table-responsive ">
                <table class="table table-striped table-borderless ">
                  <thead>
                    <tr>
                      <th scope="col">Lot Number</th> 
                      <th scope="col">Date Listed</th>
                      <th scope="col">Product</th>
                      <th scope="col">Origin</th>
                      <th scope="col">Grade</th>
                      <th scope="col">Specification</th>  
                      <th scope="col">Quantity</th>  
                      <th scope="col">Shipping Period</th>  
                      <th scope="col">Actions</th>  
                      {/* <th scope="col">Specification</th>  
                      <th scope="col">Additional information</th> */}
                      {/* <th scope="col"></th> */}
                    </tr>
                  </thead>
                  <tbody className="">
                    {secondtab_tabledata.map((v, i) => {
                      return (
                        <tr>
                           {/* <td scope="row">
                      <img src={images["availabel_img1.png"]} alt="" />
                    </td> */}
                          <td >
                          73749
                          </td>
                          <td>12.04.22</td>
                          <td>Oaten Hay</td>
                          <td>Any</td>
                          <td>Premium</td>
                          <td>Specific Detail</td>
                          <td>200mts</td>
                          <td>Mar-Dec</td>
                          {/* <td>2864 Scenicview Drive...</td>
                          <td>Lorem Ipsum is simply...</td> */}
                          {/* <td>100 metric tons</td>
                          <td>Lorem Ipsum is simply...</td> */}
                          <td  className="seller_dashboardactions" >
                          <NavLink to={"/seller/availability-details"}>
                      <img src={images["view_btn.png"]} alt="" />
                    </NavLink>
                     
                    <button>
                      <img src={images["edit_btn.png"]} alt="" />
                    </button>
                    {/* <button>
                      <img src={images["download_btn.png"]} alt="" />
                    </button> */}
                    <button>
                      <img src={images["delete_btn.png"]} alt="" />
                    </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Tab>
          </Tabs>
       
      </div>
           
        </div>
      </div>

      <div className="inbox_sectionmain">
        <h4>Inbox</h4>

        {messageinbox.map((v, i) => {
          return (
            <div className="message_flex">
              <img src={images["person2.png"]} alt="" className="profileimg" />

              <div>
                <div className="person_nametimeflex">
                  <h5>Gary R. Castillo</h5>
                  <h6>2 : 00 PM</h6>
                </div>
                <p>Lorem Ipsum is simply dummy text of the printing...</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  )
}

export default DashboardAvailability;