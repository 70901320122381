import React from "react";
import {
  DashboardHeader,
  DashboardSidebar,
  RequirementsLists,
} from "../../components/Front";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";

const AddNewInspection = () => {
  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <>
        <div className="dashboard-layout">
          <div className="layout_left_side">
            <DashboardSidebar />
          </div>

          <div className="layout-right-sider">
            <div className="">
              <DashboardHeader headertopic="Arrange new inspections for shipment" />

              <div className="dashborader_datacontentmain">
                
                <div className="addnewtracking_section">
                  <div className="addrequirement_mainsection">
                    <div className="require_flexinput">
                      <div className="input_allmain">
                        <label> Inspection Date</label>
                        <input type="text" placeholder="Enter Date" />
                      </div>
                      <div className="input_allmain">
                        <label>Inspection Type</label>
                        <input
                          type="text"
                          placeholder="Enter  inspection type"
                        />
                      </div>
                    </div>

                    <div className="require_flexinput">
                      <div className="input_allmain">
                        <label>Location</label>
                        <input type="text" placeholder="Enter Location" />
                      </div>
                      <div className="input_allmain">
                        <label>Product</label>
                        <input type="text" placeholder="Enter product name" />
                      </div>
                    </div>

                    <div className="input_allmain">
                      <label>Additional information</label>
                      <textarea type="text" rows={5} placeholder="Enter text" />
                    </div>
                  </div>

                  <div className="add_trackbutton">
                    <button className="button_darkmain">Add</button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </>
    </motion.div>
  );
};

export default AddNewInspection;
