import React from "react";
import { images } from "../../../actions/customFn";

const FirmBidInformation = () => {
  const firmbidlist = [{}, {}];

  return (
    <>
      <div className="firmbid_informationmain">
        <div className="topprofile">
          <img src={images["person1.png"]} alt="" />
          <h4>Trent B. Brooks</h4>
        </div>

        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="product_typelists">
              <div className="row">
                <div className="col-6">
                  <p>Product</p>
                </div>
                <div className="col-6">
                  <h6>Oaten Hay</h6>
                </div>
              </div>
            </div>
            <div className="product_typelists">
              <div className="row">
                <div className="col-6">
                  <p>Product</p>
                </div>
                <div className="col-6">
                  <h6>Oaten Hay</h6>
                </div>
              </div>
            </div>
            <div className="product_typelists">
              <div className="row">
                <div className="col-6">
                  <p>Product</p>
                </div>
                <div className="col-6">
                  <h6>Oaten Hay</h6>
                </div>
              </div>
            </div>
            <div className="product_typelists">
              <div className="row">
                <div className="col-6">
                  <p>Specification</p>
                </div>
                <div className="col-6">
                  <h6>Lorem Ipsum</h6>
                </div>
              </div>
            </div>
            <div className="product_typelists">
              <div className="row">
                <div className="col-6">
                  <p>Product</p>
                </div>
                <div className="col-6">
                  <h6>Oaten Hay</h6>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="product_typelists">
              <div className="row">
                <div className="col-6">
                  <p>Packing</p>
                </div>
                <div className="col-6">
                  <h6>Lorem Ipsum</h6>
                </div>
              </div>
            </div>

            <div className="product_typelists">
              <div className="row">
                <div className="col-6">
                  <p>Desired ETA</p>
                </div>
                <div className="col-6">
                  <h6>Lorem Ipsum</h6>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="product_typelists">
              <div className="row">
                <div className="col-6">
                  <p>Incoterms</p>
                </div>
                <div className="col-6">
                  <h6>CIF</h6>
                </div>
              </div>
            </div>

            <div className="product_typelists">
              <div className="row">
                <div className="col-6">
                  <p>Delivery period</p>
                </div>
                <div className="col-6">
                  <h6>March-December</h6>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
    <div className="bidprice_section">
        <div>  
<p>Price</p>
<h3>$ 435 <span>/mt</span></h3>
<p>Quantity</p>
<h6>100<span>/mt</span></h6>
</div>

    </div>
</div> */}
        </div>

        <div className="additional_information">
          <p>Additional Information</p>
          <h6>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </h6>
        </div>
      </div>

      <div className="firmbid_buttonssection">
        <button>Accept</button>
        <button>Negotiate</button>
        <button>Reject</button>
      </div>
    </>
  );
};

export default FirmBidInformation;
