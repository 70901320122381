import React from 'react';
// import Blog1 from '../../assets/images/home/blog-1.png';
// import Blog2 from '../../assets/images/home/blog-2.png';
// import Blog3 from '../../assets/images/home/blog-3.png';
import { images } from "../../../actions/customFn";
import { NavLink } from 'react-router-dom';



function Blogs(props) {
  return (
    <>
        <div className='blog_sec'>
            <div className='container'>
                  <h3>{props.heading}</h3>              
                  <div className='blog_list'>
                        <div className='blog_card'>
                            <img src={images["blog-1.png"]} alt="" />
                            <div className='blog_details'>
                                <NavLink className='blog_title' to='/blog-detail'>Hay Trading to the Middle East</NavLink>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <p>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_59_168)">
                                    <path d="M12.375 7.3125C12.375 7.16332 12.4343 7.02024 12.5398 6.91475C12.6452 6.80926 12.7883 6.75 12.9375 6.75H14.0625C14.2117 6.75 14.3548 6.80926 14.4602 6.91475C14.5657 7.02024 14.625 7.16332 14.625 7.3125V8.4375C14.625 8.58668 14.5657 8.72976 14.4602 8.83525C14.3548 8.94074 14.2117 9 14.0625 9H12.9375C12.7883 9 12.6452 8.94074 12.5398 8.83525C12.4343 8.72976 12.375 8.58668 12.375 8.4375V7.3125ZM9 7.3125C9 7.16332 9.05926 7.02024 9.16475 6.91475C9.27024 6.80926 9.41332 6.75 9.5625 6.75H10.6875C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125V8.4375C11.25 8.58668 11.1907 8.72976 11.0852 8.83525C10.9798 8.94074 10.8367 9 10.6875 9H9.5625C9.41332 9 9.27024 8.94074 9.16475 8.83525C9.05926 8.72976 9 8.58668 9 8.4375V7.3125ZM3.375 10.6875C3.375 10.5383 3.43426 10.3952 3.53975 10.2898C3.64524 10.1843 3.78832 10.125 3.9375 10.125H5.0625C5.21168 10.125 5.35476 10.1843 5.46025 10.2898C5.56574 10.3952 5.625 10.5383 5.625 10.6875V11.8125C5.625 11.9617 5.56574 12.1048 5.46025 12.2102C5.35476 12.3157 5.21168 12.375 5.0625 12.375H3.9375C3.78832 12.375 3.64524 12.3157 3.53975 12.2102C3.43426 12.1048 3.375 11.9617 3.375 11.8125V10.6875ZM6.75 10.6875C6.75 10.5383 6.80926 10.3952 6.91475 10.2898C7.02024 10.1843 7.16332 10.125 7.3125 10.125H8.4375C8.58668 10.125 8.72976 10.1843 8.83525 10.2898C8.94074 10.3952 9 10.5383 9 10.6875V11.8125C9 11.9617 8.94074 12.1048 8.83525 12.2102C8.72976 12.3157 8.58668 12.375 8.4375 12.375H7.3125C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V10.6875Z" fill="white"/>
                                    <path d="M3.9375 0C4.08668 0 4.22976 0.0592632 4.33525 0.164752C4.44074 0.270242 4.5 0.413316 4.5 0.5625V1.125H13.5V0.5625C13.5 0.413316 13.5593 0.270242 13.6648 0.164752C13.7702 0.0592632 13.9133 0 14.0625 0C14.2117 0 14.3548 0.0592632 14.4602 0.164752C14.5657 0.270242 14.625 0.413316 14.625 0.5625V1.125H15.75C16.3467 1.125 16.919 1.36205 17.341 1.78401C17.7629 2.20597 18 2.77826 18 3.375V15.75C18 16.3467 17.7629 16.919 17.341 17.341C16.919 17.7629 16.3467 18 15.75 18H2.25C1.65326 18 1.08097 17.7629 0.65901 17.341C0.237053 16.919 0 16.3467 0 15.75V3.375C0 2.77826 0.237053 2.20597 0.65901 1.78401C1.08097 1.36205 1.65326 1.125 2.25 1.125H3.375V0.5625C3.375 0.413316 3.43426 0.270242 3.53975 0.164752C3.64524 0.0592632 3.78832 0 3.9375 0ZM1.125 4.5V15.75C1.125 16.0484 1.24353 16.3345 1.4545 16.5455C1.66548 16.7565 1.95163 16.875 2.25 16.875H15.75C16.0484 16.875 16.3345 16.7565 16.5455 16.5455C16.7565 16.3345 16.875 16.0484 16.875 15.75V4.5H1.125Z" fill="white"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_59_168">
                                    <rect width="18" height="18" fill="white"/>
                                    </clipPath>
                                    </defs>
                                    </svg>
                                    02 - 02 - 2024</p>
                            </div>
                        </div>
                        <div className='blog_card'>
                            <img src={images["blog-2.png"]} alt="" />
                            <div className='blog_details'>
                            <NavLink className='blog_title' to='/blog-detail'>Hay Trading to the Middle East</NavLink>
                                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
                                <p>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_59_168)">
                                    <path d="M12.375 7.3125C12.375 7.16332 12.4343 7.02024 12.5398 6.91475C12.6452 6.80926 12.7883 6.75 12.9375 6.75H14.0625C14.2117 6.75 14.3548 6.80926 14.4602 6.91475C14.5657 7.02024 14.625 7.16332 14.625 7.3125V8.4375C14.625 8.58668 14.5657 8.72976 14.4602 8.83525C14.3548 8.94074 14.2117 9 14.0625 9H12.9375C12.7883 9 12.6452 8.94074 12.5398 8.83525C12.4343 8.72976 12.375 8.58668 12.375 8.4375V7.3125ZM9 7.3125C9 7.16332 9.05926 7.02024 9.16475 6.91475C9.27024 6.80926 9.41332 6.75 9.5625 6.75H10.6875C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125V8.4375C11.25 8.58668 11.1907 8.72976 11.0852 8.83525C10.9798 8.94074 10.8367 9 10.6875 9H9.5625C9.41332 9 9.27024 8.94074 9.16475 8.83525C9.05926 8.72976 9 8.58668 9 8.4375V7.3125ZM3.375 10.6875C3.375 10.5383 3.43426 10.3952 3.53975 10.2898C3.64524 10.1843 3.78832 10.125 3.9375 10.125H5.0625C5.21168 10.125 5.35476 10.1843 5.46025 10.2898C5.56574 10.3952 5.625 10.5383 5.625 10.6875V11.8125C5.625 11.9617 5.56574 12.1048 5.46025 12.2102C5.35476 12.3157 5.21168 12.375 5.0625 12.375H3.9375C3.78832 12.375 3.64524 12.3157 3.53975 12.2102C3.43426 12.1048 3.375 11.9617 3.375 11.8125V10.6875ZM6.75 10.6875C6.75 10.5383 6.80926 10.3952 6.91475 10.2898C7.02024 10.1843 7.16332 10.125 7.3125 10.125H8.4375C8.58668 10.125 8.72976 10.1843 8.83525 10.2898C8.94074 10.3952 9 10.5383 9 10.6875V11.8125C9 11.9617 8.94074 12.1048 8.83525 12.2102C8.72976 12.3157 8.58668 12.375 8.4375 12.375H7.3125C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V10.6875Z" fill="white"/>
                                    <path d="M3.9375 0C4.08668 0 4.22976 0.0592632 4.33525 0.164752C4.44074 0.270242 4.5 0.413316 4.5 0.5625V1.125H13.5V0.5625C13.5 0.413316 13.5593 0.270242 13.6648 0.164752C13.7702 0.0592632 13.9133 0 14.0625 0C14.2117 0 14.3548 0.0592632 14.4602 0.164752C14.5657 0.270242 14.625 0.413316 14.625 0.5625V1.125H15.75C16.3467 1.125 16.919 1.36205 17.341 1.78401C17.7629 2.20597 18 2.77826 18 3.375V15.75C18 16.3467 17.7629 16.919 17.341 17.341C16.919 17.7629 16.3467 18 15.75 18H2.25C1.65326 18 1.08097 17.7629 0.65901 17.341C0.237053 16.919 0 16.3467 0 15.75V3.375C0 2.77826 0.237053 2.20597 0.65901 1.78401C1.08097 1.36205 1.65326 1.125 2.25 1.125H3.375V0.5625C3.375 0.413316 3.43426 0.270242 3.53975 0.164752C3.64524 0.0592632 3.78832 0 3.9375 0ZM1.125 4.5V15.75C1.125 16.0484 1.24353 16.3345 1.4545 16.5455C1.66548 16.7565 1.95163 16.875 2.25 16.875H15.75C16.0484 16.875 16.3345 16.7565 16.5455 16.5455C16.7565 16.3345 16.875 16.0484 16.875 15.75V4.5H1.125Z" fill="white"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_59_168">
                                    <rect width="18" height="18" fill="white"/>
                                    </clipPath>
                                    </defs>
                                    </svg>
                                    02 - 02 - 2024</p>
                            </div>
                        </div>
                        <div className='blog_card'>
                            <img src={images["blog-3.png"]} alt="" />
                            <div className='blog_details'>
                            <NavLink className='blog_title' to='/blog-detail'>Hay Trading to the Middle East</NavLink>
                                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
                                <p>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_59_168)">
                                    <path d="M12.375 7.3125C12.375 7.16332 12.4343 7.02024 12.5398 6.91475C12.6452 6.80926 12.7883 6.75 12.9375 6.75H14.0625C14.2117 6.75 14.3548 6.80926 14.4602 6.91475C14.5657 7.02024 14.625 7.16332 14.625 7.3125V8.4375C14.625 8.58668 14.5657 8.72976 14.4602 8.83525C14.3548 8.94074 14.2117 9 14.0625 9H12.9375C12.7883 9 12.6452 8.94074 12.5398 8.83525C12.4343 8.72976 12.375 8.58668 12.375 8.4375V7.3125ZM9 7.3125C9 7.16332 9.05926 7.02024 9.16475 6.91475C9.27024 6.80926 9.41332 6.75 9.5625 6.75H10.6875C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125V8.4375C11.25 8.58668 11.1907 8.72976 11.0852 8.83525C10.9798 8.94074 10.8367 9 10.6875 9H9.5625C9.41332 9 9.27024 8.94074 9.16475 8.83525C9.05926 8.72976 9 8.58668 9 8.4375V7.3125ZM3.375 10.6875C3.375 10.5383 3.43426 10.3952 3.53975 10.2898C3.64524 10.1843 3.78832 10.125 3.9375 10.125H5.0625C5.21168 10.125 5.35476 10.1843 5.46025 10.2898C5.56574 10.3952 5.625 10.5383 5.625 10.6875V11.8125C5.625 11.9617 5.56574 12.1048 5.46025 12.2102C5.35476 12.3157 5.21168 12.375 5.0625 12.375H3.9375C3.78832 12.375 3.64524 12.3157 3.53975 12.2102C3.43426 12.1048 3.375 11.9617 3.375 11.8125V10.6875ZM6.75 10.6875C6.75 10.5383 6.80926 10.3952 6.91475 10.2898C7.02024 10.1843 7.16332 10.125 7.3125 10.125H8.4375C8.58668 10.125 8.72976 10.1843 8.83525 10.2898C8.94074 10.3952 9 10.5383 9 10.6875V11.8125C9 11.9617 8.94074 12.1048 8.83525 12.2102C8.72976 12.3157 8.58668 12.375 8.4375 12.375H7.3125C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V10.6875Z" fill="white"/>
                                    <path d="M3.9375 0C4.08668 0 4.22976 0.0592632 4.33525 0.164752C4.44074 0.270242 4.5 0.413316 4.5 0.5625V1.125H13.5V0.5625C13.5 0.413316 13.5593 0.270242 13.6648 0.164752C13.7702 0.0592632 13.9133 0 14.0625 0C14.2117 0 14.3548 0.0592632 14.4602 0.164752C14.5657 0.270242 14.625 0.413316 14.625 0.5625V1.125H15.75C16.3467 1.125 16.919 1.36205 17.341 1.78401C17.7629 2.20597 18 2.77826 18 3.375V15.75C18 16.3467 17.7629 16.919 17.341 17.341C16.919 17.7629 16.3467 18 15.75 18H2.25C1.65326 18 1.08097 17.7629 0.65901 17.341C0.237053 16.919 0 16.3467 0 15.75V3.375C0 2.77826 0.237053 2.20597 0.65901 1.78401C1.08097 1.36205 1.65326 1.125 2.25 1.125H3.375V0.5625C3.375 0.413316 3.43426 0.270242 3.53975 0.164752C3.64524 0.0592632 3.78832 0 3.9375 0ZM1.125 4.5V15.75C1.125 16.0484 1.24353 16.3345 1.4545 16.5455C1.66548 16.7565 1.95163 16.875 2.25 16.875H15.75C16.0484 16.875 16.3345 16.7565 16.5455 16.5455C16.7565 16.3345 16.875 16.0484 16.875 15.75V4.5H1.125Z" fill="white"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_59_168">
                                    <rect width="18" height="18" fill="white"/>
                                    </clipPath>
                                    </defs>
                                    </svg>
                                    02 - 02 - 2024</p>
                            </div>
                        </div>
                  </div>
            </div>            
        </div>
    </>
  )
}

export default Blogs;