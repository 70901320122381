import React from "react";
import {
    AvailabilityHeader,
  AvailabilitySidebar,
 
  
} from "../../components/Front";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { images } from "../../actions/customFn";

const ArrangeInspection = () => {
  return (
    <motion.div
    initial={{ opacity: 0.2 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 1 }}
    transition={{ duration: 2 }}
  >
    <>
      <div className="dashboard-layout">
        <div className="layout_left_side">
          <AvailabilitySidebar />
        </div>

        <div className="layout-right-sider">
          <div className="">
            <AvailabilityHeader headertopic="Arrange new inspections for shipment" />

            <div className="dashborader_datacontentmain">
          
            <div className="addnewtracking_section">
                  <div className="addrequirement_mainsection">
                    <div className="require_flexinput">
                      <div className="input_allmain">
                        <label> Shipment Id</label>
                        <input type="text" placeholder="Enter Shipment Id" />
                      </div>
                      <div className="input_allmain">
                        <label>Date</label>
                        <input
                          type="text"
                          placeholder="Enter date for inspection"
                        />
                      </div>
                    </div>

                    <div className="require_flexinput">
                      <div className="input_allmain">
                        <label>Time</label>
                        <input type="text" placeholder="Enter time for your shipment inspection" />
                      </div>
                     
                    </div>

                   
                  </div>

                  <div className="add_trackbutton">
                    <button className="button_darkmain">Add</button>
                  </div>
                </div>

            </div>

          </div>
        </div>
      </div>
    </>
  </motion.div>
  )
}

export default ArrangeInspection;