import React from "react";
import { 
  DashboardHeader, 
  DashboardSidebar,
  OrderDetail,
  OrderTracking, 
} from "../../components/Front";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";

const MyOrderDetails = () => {
  return (
    <motion.div
    initial={{ opacity: 0.2 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 1 }}
    transition={{ duration: 2 }}
  >
    <>
      <div className="dashboard-layout">
        <div className="layout_left_side">
          <DashboardSidebar />
        </div>

        <div className="layout-right-sider">
          <div className="">
            <DashboardHeader headertopic="Order Details" />

            <div className="dashborader_datacontentmain">
             

             
<OrderDetail />
<OrderTracking />

            </div>
          </div>
        </div>
      </div>
    </>
    </motion.div>
  )
}

export default MyOrderDetails;