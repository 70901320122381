import React from "react";
import { images } from "../../../actions/customFn";

const LatestPost = () => {
  const latestpost = [
    { postimg: images["post_img1.png"] },
    { postimg: images["post_img1.png"] },
    { postimg: images["post_img1.png"] },
    { postimg: images["post_img1.png"] },
    { postimg: images["post_img1.png"] },
  ];

  return (
    <div className="latestpost_mainsection">
      <h2>Latest Post</h2>

      {latestpost.map((v, i) => {
        return (
          <div className="latestpost_card1" key={i}>
            <div className="leftimage">
              <img src={v.postimg} alt="" />
            </div>
            <div className="right_contentdata">
              <h6>Lorem Ipsum is simply dummy text of the printing and</h6>

              <div className="admin_dateflex">
                <div className="profile_name">
                  <img src={images["person1.png"]} alt="" />
                  <p>Admin</p>
                </div>
                <div className="date_flex">
                  <img src={images["calendar1.png"]} alt="" />
                  <p>02 - 02 - 2024</p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default LatestPost;
