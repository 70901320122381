import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { NavLink } from "react-router-dom";
import { images } from "../../../actions/customFn";
import LoginModal from "../Modals/LoginModal";

function TabTable() {
  const firsttab_tabledata = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
  const secondtab_tabledata = [{}, {}, {}, {}, {}, {}];

          // Login modal
const [loginmodal, setLoginModal] = useState(false);

const closeLoginModal = ()=>{
    setLoginModal(false);
}

const openLoginModal = ()=>{
    setLoginModal(true); 
}

  return (
    <>
      <div className="tab_table_sec">
        <div className="container">
          <Tabs
            defaultActiveKey="open_rq"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="open_rq" title="Open Requirements">
              <div className="table-responsive ">
                <table class="table table-striped table-borderless">
                  <thead>
                    <tr>
                    <th scope="col">ID number</th>
                    <th scope="col">Delivery Port</th>
                      <th scope="col">Date Listed</th>
                      <th scope="col">Product</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Grade</th>
                      <th scope="col">Origin</th>
                      <th scope="col">Total quotes</th>
                      <th scope="col">Incoterns</th>
                      <th scope="col">More </th> 
                      {/* <th scope="col">Additional information</th> */}
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {firsttab_tabledata.map((v, i) => {
                      return (
                        <tr>
                          <td scope="row">73749</td>
                          <td>Jebel Ali</td>
                          <td>12.04.24</td>
                          <td>Oaten Hay</td>
                          <td>200 mts</td>
                          <td>Premium</td>
                          <td>Any</td>
                          <td>20 Bid</td>
                          <td>CIF</td>
                          <td>Lorem Ipsum is simply...</td>
                          <td>
                          <button onClick={()=>openLoginModal()} >View</button>
                            <button onClick={()=>openLoginModal()} >Quote</button>
                            <button onClick={()=>openLoginModal()} >Chat</button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Tab>
            <Tab eventKey="current_av" title="Current Availability">
              <div className="table-responsive ">
                <table class="table table-striped table-borderless">
                  <thead>
                    <tr>
                    <th scope="col">Pictures</th> 
                      <th scope="col">Product</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Quality</th>
                      <th scope="col">Origin</th>
                      <th scope="col">Location</th>  
                      <th scope="col">Additional information</th> 
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody className="current_available_table">
                    {secondtab_tabledata.map((v, i) => {
                      return (
                        <tr>
                           <td scope="row">
                      <img src={images["availabel_img1.png"]} alt="" />
                    </td>
                          <td >
                          Premium Oaten Hay
                          </td>
                          <td>100 metric tons</td>
                          <td>Premium</td>
                          <td>200 mts</td>
                          <td>2864 Scenicview Drive...</td>
                          <td>Lorem Ipsum is simply...</td>
                          <td>
                          <button onClick={()=>openLoginModal()} >View</button>
                            <button onClick={()=>openLoginModal()} >Bid</button>
                            <button onClick={()=>openLoginModal()} >Chat</button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>

      <LoginModal loginmodal={loginmodal} closeLoginModal={closeLoginModal} />
    </>
  );
}

export default TabTable;
