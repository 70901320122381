import React from "react";
import {
    AvailabilityHeader,
  AvailabilitySidebar,
  AvailableOrderData,
  OrderDetail,
  OrderTracking,
  
} from "../../components/Front";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { images } from "../../actions/customFn";

const SupplierOrderDetail = () => {
  return (
    <motion.div
    initial={{ opacity: 0.2 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 1 }}
    transition={{ duration: 2 }}
  >
    <>
      <div className="dashboard-layout">
        <div className="layout_left_side">
          <AvailabilitySidebar />
        </div>

        <div className="layout-right-sider">
          <div className="">
            <AvailabilityHeader headertopic="Order Details" />

            <div className="dashborader_datacontentmain">
          
            <AvailableOrderData />
            <OrderTracking />
            </div>

          </div>
        </div>
      </div>
    </>
  </motion.div>
  )
}

export default SupplierOrderDetail