import React from "react"; 
// import { BallTriangle } from "react-loader-spinner";
import { ThreeDots } from "react-loader-spinner";

const RotatingLoader = () => {
  return (
    <div className=" circulartriangle_loader">
      {/* <Loading type="circles" width={100} height={300} fill="#f44242" /> */}

{/* <BallTriangle
  height={100}
  width={100}
  radius={5}
  color="#4fa94d"
  ariaLabel="ball-triangle-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  className=""
  /> */}
<ThreeDots
  visible={true}
  height="80"
  width="80"
  color="#F0904B"
  radius="9"
  ariaLabel="three-dots-loading"
  wrapperStyle={{}}
  wrapperClass=""
  />
    </div>
  );
};

export default RotatingLoader;
