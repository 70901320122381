import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RouteArr from "../hooks/GetRoutes";
import FrontApp from "../pages/Front/FrontApp";
import DashboardFrontApp from "../pages/Front/DashboardFrontApp";
import DashboardRouteArr from "../hooks/DashboardRoutes";
import AvailabilityFrontApp from "../pages/Front/AvailabilityFrontApp";
import AvailabilityDashboardRouteArr from "../hooks/AvailabilityRoutes";

const router = createBrowserRouter([
  {
    path: "/",
    element: <FrontApp />,
    children: RouteArr,
  },
  {
    path: "/buyer",
    element: <DashboardFrontApp />,
    children: DashboardRouteArr,
  },
  {
    path: "/seller",
    element: <AvailabilityFrontApp />,
    children: AvailabilityDashboardRouteArr,
  },

]);
const Routes = () => {
  return <RouterProvider router={router} />;
};

export default Routes;
