import React from "react";
import {
  DashboardHeader,
  DashboardSidebar,
  RequirementsLists,
} from "../../components/Front";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";

const AddNewTracking = () => {
  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <>
        <div className="dashboard-layout">
          <div className="layout_left_side">
            <DashboardSidebar />
          </div>

          <div className="layout-right-sider">
            <div className="">
              <DashboardHeader headertopic="Add new Shipment for tracking" />

              <div className="dashborader_datacontentmain">

                <div className="addnewtracking_section">
                  <div className="input_allmain">
                    <label>Shipment Details</label>
                    <input
                      type="text"
                      placeholder="Enter your shipment details like booking numbers, container numbers etc. to add new shipment tracking"
                    />
                  </div>

                  <div className="add_trackbutton">
                    <button className="button_darkmain">Add</button>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </>
    </motion.div>
  );
};

export default AddNewTracking;
