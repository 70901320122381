import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { motion } from "framer-motion";
import RotatingLoader from "../Loaders/RotatingLoader";

const Master = () => {
const params = useParams();
  const [isloader, setIsloader] = useState(true);

  useEffect(()=>{
    setIsloader(true);

    setTimeout(() => {
      setIsloader(false);
    }, 1500);
    
  }, [params])



  return (
    <>
      <div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 2 }}
      >
        {isloader == true ? (
    <RotatingLoader />
  ) : (
        <>
       
        <Header />
        <Outlet />
        <Footer />
        </>
  )}
      </div>
    </>
  );
};

export default Master;
