import React from "react";
import { images } from "../../../actions/customFn";

const ProfileDetailInfo = () => {
  
  const profile_detail = [
    { title: "Name", detail: "Clifford M. Pereira" },
    { title: "Email", detail: "Glob@gmail.com" },
    { title: "Telephone Number", detail: "+1 231 256 3233" },
    { title: "Company Name", detail: "Globex Corporation" },
    {
      title: "Address",
      detail: "Lorem Ipsum is simply dummy text of the printing.",
    },
    {
      title: "Role",
      detail: "Lorem Ipsum is simply dummy text of the printing.",
    },
    {
      title: "Activities",
      detail: "Lorem Ipsum is simply dummy text of the printing.",
    },
    { title: "Services/products offered", detail: "Oaten Hay" },
    { title: "Volumes", detail: "200mts" },
    { title: "Business categories", detail: "Finance" },
    { title: "Website link", detail: "www.abc.com" },
    {
      title: "Brief description",
      detail:
        "Lorem Ipsum is simply dummy text of the printing.Lorem Ipsum is simply dummy text of the printing.Lorem Ipsum is simply dummy text of the printing.",
    },
  ];

  return (
    <div className="profileedit_mainsection">
      <div className="profile_user_image ">
        <div className="user_image">
          <img src={images["profile_1img.png"]} alt="" />
        </div>
      </div>
      <p className="upload_imaglabel">My profile image/Company Logo</p>

      <div className="profile_detailflexbox">
        {profile_detail.map((v, i) => {
          return (
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-5">
                <h6>{v.title}</h6>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-7">
                <h6>
                  <span>{v.detail}</span>
                </h6>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProfileDetailInfo;
